

html, body
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
  -webkit-tap-highlight-color: transparent


html
  scroll-behavior: smooth
body
  font-family: var(--font_body)
  color: var(--color_body)
  letter-spacing: 0
  text-rendering: optimizeSpeed
  min-height: 100vh
  line-height: 1.5
  min-width: 280px
  // display: flex
  // flex-direction: column

// .main
//   flex: 1

a
  text-decoration: none
  &:not([class])
    text-decoration-skip-ink: auto

figure
  margin: 0

picture
  display: contents

[hidden]
  display: none !important

