@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
/** Our system will use a 4px base vertical rhythm */
/** Our system's base font size */
/**
 * * Calculate the type offset for a given font
 * *
 * * @param {number} $lh - the font's base line height
 * * @param {number} $fontSize - the font's size
 * * @param {number} $descenderHeightScale - the font's descender height as a ratio
 * * @return {number} the offset to be added to a transformY to keep the text in place
 * */
/**
 * * The basekick base function
 * *
 * * @param {number} $typeSizeModifier - a multiplier to determine the font size
 * * @param {number} $typeRowSpan - how many rows of our vertical rhythm should the type span
 * * @param {number} $descenderHeightScale - the height of the descender expressed as a ratio of the font
 * * @param {number} $capHeight - the font's cap height expressed as a ratio of the font
 * */
/**
 * * The trim mixin to apply base-kick styles
 * *
 * * @param {number} $typeSizeModifier - a multiplier for our system's $baseFontSize
 * * @param {number} $typeRowSpan - the height of the descender expressed as a ratio of the font
 * */
/**
 * * The trim mixin to apply base-kick styles
 * *
 * * @param {16px / 14px} =>  mark_up_font_size / default_font_size => size modifier
 * * @param {11} => mark_up_text_height_trim => 22px line_height on site
 * *
 * * @use "sass:math"
 * *
 * * @include trim(16, 11)
 * **/
/*----------------------------*/
:root {
  --ff1-3: "exo2-300", var(--font_fallback);
  --ff1-4: "exo2-400", var(--font_fallback);
  --ff1-5: "exo2-500", var(--font_fallback);
  --ff1-6: "exo2-600", var(--font_fallback);
  --ff1-7: "exo2-700", var(--font_fallback);
  --ff2-4: "audiowide-400", var(--font_fallback);
  --ff3-3: "inter-300", var(--font_fallback);
  --ff3-4: "inter-400", var(--font_fallback);
  --ff3-5: "inter-500", var(--font_fallback);
  --ff3-6: "inter-600", var(--font_fallback);
  --ff3-7: "inter-700", var(--font_fallback);
  --font_fallback: BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --font_code: Menlo,Consolas,Monaco,Liberation Mono,Lucida Console,monospace;
  --font_body: var(--ff1-4);
  --color_body: var(--dd2-2);
  --trs1: cubic-bezier(0.25, 0.1, 0.25, 1);
  --trs2: cubic-bezier(0.39, 0.58, 0.57, 1);
  --trs121: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  --trs12: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  --trs21: cubic-bezier(0.6, -0.28, 0.74, 0.05);
  --size-3: 1rem;
  --size-4: 1.25rem;
  --size-5: 1.5rem;
  --size-6: 1.75rem;
  --size-7: 2rem;
  --size-8: 3rem;
  --size-9: 4rem;
  --size-10: 5rem;
  --size-fluid-1: clamp(0.5rem, 1vw, 1rem);
  --size-fluid-2: clamp(1rem, 2vw, 1.25rem);
  --size-fluid-22: clamp(1.15rem, 2vw, 1.4rem);
  --size-fluid-3: clamp(1.5rem, 3vw, 2rem);
  --size-fluid-4: clamp(1.5rem, 4vw, 2.5rem);
  --size-fluid-5: clamp(1.5rem, 5vw, 3rem);
  --size-fluid-6: clamp(2rem, 5vw, 5rem);
  --size-fluid-7: clamp(3rem, 7vw, 5rem);
  --size-fluid-8: clamp(4rem, 9vw, 6rem);
  --tip_wrap: 1170px;
  --tip_inset: 15px;
  --fip_wrap: 1170;
  --fip_inset: 15;
  --fip_min_offset: calc(var(--fip_wrap) + var(--fip_inset));
  --minmax_15_30: clamp(15px, 15px + (30 - 15) * (100vw - 740.3px) / (var(--fip_min_offset) - 740.3), 30px);
  --tip_offset: max(var(--minmax_15_30), calc((min(100%, 100vw) - calc(var(--tip_wrap, 0px) - var(--tip_inset, 0px) * 2)) / 2));
  --verticalmenu_width: 260px;
  --HS_offset: var(--bdrs-8);
  --bdrs-4: 4px;
  --bdrs-8: 8px;
  --bdrs-12: 12px;
  --minmax_8_15: clamp(8px, 8px + (15 - 8) * (100vw - 820.3px) / (1645 - 820.3), 15px);
  --minmax_15_25: clamp(15px, 15px + (25 - 15) * (100vw - 820.3px) / (1645 - 820.3), 25px);
  --minmax_20_35: clamp(20px, 20px + (35 - 20) * (100vw - 820.3px) / (1645 - 820.3), 35px);
  --aa1: #ff002e;
  --gg1: green;
  --dd1: #fff;
  --dd1-1: #e5e5e5;
  --dd1-2: #f2f2f2;
  --dd1-3: #d2d2d2;
  --dd1-4: #ccc;
  --dd2: #000;
  --rd2: 0 0 0;
  --dd2-1: #222;
  --dd2-2: #4f4d57;
  --dd2-3: #333;
  --dd2-4: #555;
  --dd3: #666;
  --dd3-1: #888;
  --dd3-2: #a2a2a2;
  --dd3-3: #EAEDF7;
  --dd3-4: #ddd;
  --dd3-5: #f2f2f0;
  --cc1: #afbc25;
  --rr1: 175 188 37;
  --hh1: #8a941e;
  --hh1-2: #000;
  --cc2: #ff6408;
  --ch2: #d44f00;
  --cc3: #231f20;
}

@font-face {
  font-display: swap;
  font-family: "exo2-300";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/01_exo2/Exo2-Light.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "exo2-400";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/01_exo2/Exo2-Regular.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "exo2-500";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/01_exo2/Exo2-Medium.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "exo2-600";
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/01_exo2/Exo2-SemiBold.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "exo2-700";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/01_exo2/Exo2-Bold.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "audiowide-400";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/02_audiowide/Audiowide-Regular.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "inter-300";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/03_Inter/Inter-Light-BETA.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "inter-400";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/03_Inter/Inter-Regular.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "inter-500";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/03_Inter/Inter-Medium.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "inter-600";
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/03_Inter/Inter-SemiBold.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "inter-700";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/03_Inter/Inter-Bold.ttf") format("truetype");
}
html, body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font_body);
  color: var(--color_body);
  letter-spacing: 0;
  text-rendering: optimizeSpeed;
  min-height: 100vh;
  line-height: 1.5;
  min-width: 280px;
}

a {
  text-decoration: none;
}
a:not([class]) {
  text-decoration-skip-ink: auto;
}

figure {
  margin: 0;
}

picture {
  display: contents;
}

[hidden] {
  display: none !important;
}

/* Leading Trim */
/* One of the challenges when working with some typefaces is having */
/* a different leading value which might result in inconsistent spacing */
/* before and after a font. */
.wall_categories__item_title > span {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: var(--trim, 16px);
  align-items: center;
}
.wall_categories__item_title > span > *:nth-child(1), .wall_categories__item_title > span::before {
  display: block;
  grid-column: 1;
  grid-row: 1;
}

/* Leading Trim end */
.breadcrumbs_contain {
  --scrollbar_overflow: var(--scrollbar_overflow_x) var(--scrollbar_overflow_y);
  -ms-overflow-style: var(--scrollbar_overflow_style, none);
  overflow: var(--scrollbar_overflow_xy, var(--scrollbar_overflow, auto));
  scrollbar-width: var(--scrollbar_ff_width, none);
}
.breadcrumbs_contain::-webkit-scrollbar {
  width: var(--scrollbar_width, 0);
  height: var(--scrollbar_height, 0);
}

/*----------------------------*/
.btn-list-grid {
  padding-top: 4px !important;
}

.btn:not(.ui_btn) {
  font-size: 15px !important;
  font-family: var(--ff1-5) !important;
  line-height: 1 !important;
  text-transform: uppercase !important;
  display: flex !important;
  align-items: center !important;
  align-content: center !important;
  justify-content: center !important;
  padding: 4px 12px !important;
  border: none !important;
  gap: 8px;
}

.owl-carousel {
  background-color: var(--dd1);
  margin: calc(var(--minmax_8_15) / -2) !important;
  width: auto !important;
  height: auto !important;
}

.owl-carousel .owl-wrapper {
  display: flex !important;
}

.owl-carousel .owl-wrapper .owl-item {
  display: flex !important;
  flex-direction: column;
  height: auto !important;
}
.owl-carousel .owl-wrapper .owl-item > * {
  flex-grow: 1;
}
.owl-carousel .owl-wrapper .owl-item .double-slideitem {
  margin: calc(var(--minmax_8_15) / 2) !important;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: var(--minmax_8_15);
  align-content: stretch;
  background-color: #fff !important;
}
@media screen and (min-width: 280px) and (max-width: 804.7px) {
  .owl-carousel .owl-wrapper .owl-item .double-slideitem > .product_item_contain {
    grid-template-rows: 1fr 0.5fr 0.5fr 36px !important;
  }
}
@media screen and (min-width: 805px) {
  .owl-carousel .owl-wrapper .owl-item .double-slideitem > .product_item_contain {
    grid-template-rows: 1fr 0.8fr 36px !important;
  }
}
.owl-carousel .owl-wrapper .owl-item .double-slideitem > .product_item_contain .product_item_image {
  grid-row: span 3;
}
@media screen and (min-width: 280px) and (max-width: 804.7px) {
  .owl-carousel .owl-wrapper .owl-item .double-slideitem > .product_item_contain .product_item_heading {
    grid-row: 1/span 2;
  }
}
@media screen and (min-width: 280px) and (max-width: 804.7px) {
  .owl-carousel .owl-wrapper .owl-item .double-slideitem > .product_item_contain .product_item_price {
    grid-row: 3;
  }
}
.owl-carousel .owl-wrapper .owl-item .double-slideitem > .product_item_contain .product_btn_addtocart {
  height: 36px;
}
@media screen and (min-width: 280px) and (max-width: 804.7px) {
  .owl-carousel .owl-wrapper .owl-item .double-slideitem > .product_item_contain .product_btn_addtocart {
    grid-column: 1/-1;
    grid-row: 4;
  }
}
@media screen and (min-width: 805px) {
  .owl-carousel .owl-wrapper .owl-item .double-slideitem > .product_item_contain .product_btn_addtocart {
    grid-row: 3;
    grid-column: 2/-1;
  }
}
.owl-carousel .owl-wrapper .owl-item .double-slideitem > .product_item_contain:only-child {
  grid-row: span 2;
  grid-template-columns: minmax(auto, 1fr) minmax(auto, 0.6fr);
  grid-template-rows: 1fr 0.3fr 36px !important;
  min-height: 0;
  min-width: 0;
  max-width: 100%;
}
.owl-carousel .owl-wrapper .owl-item .double-slideitem > .product_item_contain:only-child * {
  grid-column: 1/-1;
}
.owl-carousel .owl-wrapper .owl-item .double-slideitem > .product_item_contain:only-child *:not(.product_item_price):not(.product_btn_addtocart) {
  grid-row: unset !important;
}
.owl-carousel .owl-wrapper .owl-item .double-slideitem > .product_item_contain:only-child .product_item_figure {
  display: contents !important;
}
.owl-carousel .owl-wrapper .owl-item .double-slideitem > .product_item_contain:only-child .product_item_figure img:not(._hover) {
  width: auto !important;
  height: auto !important;
}
.owl-carousel .owl-wrapper .owl-item .double-slideitem > .product_item_contain:only-child .product_item_name {
  font-size: 15px !important;
  line-height: 16px !important;
  transform: translateY(0.1433333333em) !important;
  padding-top: 1px !important;
}
.owl-carousel .owl-wrapper .owl-item .double-slideitem > .product_item_contain:only-child .product_item_name::before {
  content: "";
  margin-top: -5px !important;
  display: block !important;
  height: 0 !important;
}
.owl-carousel .owl-wrapper .owl-item .double-slideitem > .product_item_contain:only-child .product_item_heading {
  padding: 3px 13px 10px !important;
}
.owl-carousel .owl-wrapper .owl-item .double-slideitem > .product_item_contain:only-child .product_item_price,
.owl-carousel .owl-wrapper .owl-item .double-slideitem > .product_item_contain:only-child .product_btn_addtocart {
  grid-row: 3;
}
.owl-carousel .owl-wrapper .owl-item .double-slideitem > .product_item_contain:only-child .product_item_price {
  gap: 13px !important;
  padding: 6px 13px !important;
  flex-direction: row-reverse;
  align-items: baseline !important;
  align-content: baseline;
  grid-column: 1;
}
.owl-carousel .owl-wrapper .owl-item .double-slideitem > .product_item_contain:only-child .product_btn_addtocart {
  grid-column: 2;
}

#products-related {
  padding-left: 15px;
  padding-right: 15px;
}

#footer h5,
#footer .h5 {
  font-size: 19px;
  line-height: 1.1;
}
#footer .column li {
  font-size: 15px;
  line-height: 1.2;
}

#search.header-search .btn-lg {
  gap: 5px;
}

@media screen and (min-width: 280px) and (max-width: 661.7px) {
  .smcart_item_quantity_contain {
    border-radius: var(--bdrs-4) !important;
  }
}

.tooltip .tooltip-inner {
  font-size: 12px;
  border-radius: var(--bdrs-12);
  min-height: 24px !important;
  width: fit-content !important;
  padding-bottom: 5px;
}

.tooltip.top .tooltip-arrow {
  bottom: 1px;
  transform: scale(1.5);
}

#czoffercmsblock .container,
#czsubbannercmsblock .container {
  all: unset !important;
}

#czsubbannercmsblock .subbanners {
  margin: 0;
  display: grid;
  min-width: 0;
  grid-gap: calc(var(--minmax_20_35) / 2 + 5px) calc(var(--minmax_20_35) / 2);
}
@media screen and (min-width: 280px) and (max-width: 567.7px) {
  #czsubbannercmsblock .subbanners {
    grid-template-columns: 100%;
  }
}
@media screen and (min-width: 568px) {
  #czsubbannercmsblock .subbanners {
    grid-template-columns: 1fr 1fr;
  }
}
#czsubbannercmsblock .subbanners > * {
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
}
#czsubbannercmsblock .subbanners > * > * {
  border-radius: var(--bdrs-8) !important;
}

.common-home #content {
  padding-bottom: 0;
}

#czoffercmsblock .offercms-inner {
  display: grid;
  grid-template-columns: 100%;
  border-radius: var(--bdrs-8);
  overflow: hidden;
}
#czoffercmsblock .offercms-inner > * {
  grid-row: 1;
  grid-column: 1;
  position: static;
}
#czoffercmsblock .offercms-inner .offercmsdetail {
  background-color: #b2bd3a;
  border-radius: var(--bdrs-8);
}
#czoffercmsblock .offercmsdetail {
  display: flex;
  flex-direction: column;
}
#czoffercmsblock .offercmsdetail > * {
  height: auto !important;
  flex-grow: 1;
  display: grid;
  grid-template-columns: 100%;
  border-radius: var(--bdrs-8);
  grid-gap: calc(var(--minmax_15_25) / 2) var(--minmax_15_25);
}
@media screen and (min-width: 370px) {
  #czoffercmsblock .offercmsdetail > * {
    grid-template-columns: 1fr 0.3fr;
    grid-auto-rows: max-content 1fr;
  }
}
#czoffercmsblock .offercmsdetail > * * {
  line-height: 1.5;
}
#czoffercmsblock .offercmsdetail .shopnow {
  position: static;
  place-self: center center;
}
@media screen and (min-width: 370px) {
  #czoffercmsblock .offercmsdetail .shopnow {
    grid-column: 2;
    grid-row: 1;
  }
}
@media screen and (min-width: 731px) {
  #czoffercmsblock .offercmsdetail .shopnow {
    grid-column: 2;
    grid-row: 1/span 2;
  }
}
@media screen and (min-width: 370px) and (max-width: 730.7px) {
  #czoffercmsblock .offercmsdetail .offer-subtitle {
    grid-column: 1/-1;
  }
}

.price-cartbox,
.form-group.qty {
  display: grid !important;
  grid-template-columns: 100%;
  grid-auto-rows: max-content;
  grid-gap: calc(var(--minmax_8_15) / 2) var(--minmax_8_15);
}
.price-cartbox > *,
.form-group.qty > * {
  margin: 0 !important;
  width: unset !important;
}

.form-group.qty {
  grid-template-columns: 80px minmax(auto, 200px) 36px max-content;
  align-items: center;
  margin-bottom: calc(var(--minmax_8_15) / 2);
}
.form-group.qty [name=quantity],
.form-group.qty #button-cart {
  align-self: stretch;
  border-radius: var(--bdrs-8);
  text-align: center;
}
.form-group.qty .control-label {
  grid-column: 1/-1;
}

#product .category_sortbar {
  grid-template-columns: 100%;
  margin-bottom: var(--minmax_15_30);
}

#product .product_item_price_new {
  font-size: var(--size-fluid-5) !important;
}

#product .product_item_price_old {
  --fz_old: 16px !important;
  --fz_currency: 16px !important;
}

.zoomContainer {
  margin: 0 !important;
  border-radius: var(--bdrs-8);
  overflow: hidden !important;
}

.product-info .product-image {
  display: grid;
  border-radius: var(--bdrs-8);
  grid-template-columns: 100%;
  grid-gap: calc(var(--minmax_8_15) / 2);
  border: calc(var(--minmax_8_15) / 2) solid var(--dd3-5);
  background-color: var(--dd3-5);
}
.product-info .product-image > *,
.product-info .product-image .thumbnail {
  margin: 0 !important;
  padding: 0 !important;
  border-radius: var(--bdrs-8) !important;
  overflow: hidden !important;
  max-width: 100%;
}
.product-info .product-image.thumbnails {
  grid-template-rows: 1fr auto;
}
.product-info .product-image .thumbnail img {
  width: 100%;
  border-radius: var(--bdrs-8);
}
.product-info .product-image .image {
  width: 100% !important;
}
.product-info .product-image .additional-carousel {
  width: 100% !important;
  display: grid;
  grid-gap: calc(var(--minmax_8_15) / 2);
  grid-template-columns: 30px minmax(0, 1fr) 30px;
}
.product-info .product-image .additional-carousel.not_slider {
  grid-template-columns: 100%;
  border-radius: var(--bdrs-4) !important;
}
.product-info .product-image .customNavigation {
  display: contents !important;
}
.product-info .product-image .customNavigation > * {
  position: unset !important;
  height: 100% !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--bdrs-4) !important;
}
.product-info .product-image .customNavigation .arrow-left {
  order: 1;
}
.product-info .product-image .customNavigation .arrow-right {
  order: 3;
}
.product-info .product-image .image-additional {
  order: 2;
  grid-row: 1;
  grid-column: 2;
  width: 100% !important;
  height: 100% !important;
  min-width: 0;
  margin: 0;
  padding: 0;
}
.product-info .product-image .not_slider .image-additional {
  grid-row: 1;
  grid-column: 1;
}
.product-info .product-image .slider-item {
  width: auto !important;
}
.product-info .product-image .product-block {
  height: 100% !important;
}
.product-info .product-image .slider-wrapper-outer {
  border-radius: var(--bdrs-4) !important;
}
.product-info .product-image .not_slider .image-additional {
  overflow: auto hidden;
}
.product-info .product-image .slider-wrapper,
.product-info .product-image .not_slider .image-additional {
  display: grid !important;
  --cols: 3;
  --col_gap: calc(var(--minmax_8_15) / 2);
  --calc_cols: calc(var(--cols, 1) - 1);
  --calc_width: calc(100% - var(--col_gap) * var(--calc_cols));
  grid-auto-columns: calc(var(--calc_width, 100%) / var(--cols, 1));
  grid-auto-rows: minmax(95px, 132px);
  grid-auto-flow: column;
  max-width: 100%;
  width: 100% !important;
  min-width: 0;
  grid-gap: calc(var(--minmax_8_15) / 2);
}
.product-info .product-image .slider-wrapper *,
.product-info .product-image .not_slider .image-additional * {
  margin: 0 !important;
}
.product-info .product-image .product-block {
  width: 100% !important;
  height: 100% !important;
  border-radius: var(--bdrs-4) !important;
  border: none !important;
}
.product-info .product-image .product-block .elevatezoom-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 100% !important;
}
.product-info .product-image .product-block img {
  width: 100% !important;
  height: 100% !important;
  border-radius: var(--bdrs-4) !important;
  object-fit: contain;
  background-color: var(--dd1) !important;
}

.bottomfooter_contain {
  display: grid;
  grid-template-columns: max-content 1fr !important;
  align-items: center;
}
.bottomfooter_contain .footerbottom,
.bottomfooter_contain .footerpowered.text {
  display: contents !important;
}
.bottomfooter_contain .footerbottom > *,
.bottomfooter_contain .footerpowered.text > * {
  margin: 0;
  position: 0;
}
.bottomfooter_contain .text > p:last-child {
  font-family: var(--ff1-6);
  letter-spacing: 0.03em;
  font-size: clamp(12px, 12px + 2 * (100vw - 470.3px) / 809.7, 14px);
}
.bottomfooter_contain .social-block > *, .bottomfooter_contain .social-block li {
  padding: 0 !important;
  margin: 0 !important;
  line-height: 1 !important;
}

/*----------------------------*/
:root {
  --text-1: 1rem;
  --text-2: 1.25rem;
}

p {
  font-size: clamp(1rem, 5vw, var(--text-2));
}

.ui_title, .header .header_cart_total {
  --title_fz: var(--size-fluid-5);
  --title_lh: 1;
  --title_color: var(--title_dd2);
  --title_line_size: 7px;
  color: var(--title_color, var(--cc3)) !important;
  font-size: var(--title_fz);
  font-family: var(--title_ff, var(--ff1-7));
  font-style: var(--font-style, inherit);
  line-height: var(--title_lh, 1.2) !important;
  text-align: var(--title_align, left);
  margin: var(--title_mar, 0) !important;
  padding: var(--title_pad, 0) !important;
  text-transform: var(--title_tt, uppercase) !important;
  width: fit-content;
  --bxs_y: -7px;
  --bxs_val: var(--bxs_x, 0) var(--bxs_y, 0) var(--bxs_b, 0) var(--bxs_l, 0);
  --bxs_var: var(--bxs_type, inset) var(--bxs_val) var(--bxs_fill, rgb(153 153 153 / 50%));
  box-shadow: var(--title_bxs, var(--bxs_var, none));
}
.ui_title > h1, .header .header_cart_total > h1,
.ui_title > h2,
.header .header_cart_total > h2,
.ui_title > h3,
.header .header_cart_total > h3,
.ui_title > h4,
.header .header_cart_total > h4,
.ui_title > h5,
.header .header_cart_total > h5,
.ui_title > h6,
.header .header_cart_total > h6 {
  all: unset;
  box-sizing: border-box;
  appearance: auto;
  margin: 0;
}
.ui_title > *, .header .header_cart_total > * {
  color: inherit;
  text-transform: inherit !important;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: var(--title_inner_mar, 0) !important;
  padding: var(--title_innder_pad, 0) !important;
}

/*----------------------------*/
.ui_btn {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  appearance: auto;
  display: inline-flex;
  place-items: center;
  place-content: center;
  width: var(--width, fit-content) !important;
  height: var(--height, fit-content) !important;
}
.ui_btn_switcher {
  position: relative;
  transition: 300ms;
  color: var(--dd2);
}
.ui_btn_switcher::before {
  content: "";
  border: 1.36px solid transparent;
  pointer-events: none;
  border-radius: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%) scale(1.3);
  transition: 300ms;
  opacity: 0;
}
.ui_btn_switcher:hover::before {
  transform: translate(-50%, -50%) scale(var(--scale, 1.1));
  opacity: 1;
  border-color: var(--hh1);
  border-radius: var(--bdrs-4);
}
.ui_btn_switcher .ui_btn_icn {
  transition: 300ms;
  transform: scale(var(--scale, 1));
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ui_btn_switcher.active .ui_btn_icn, .ui_btn_switcher:hover .ui_btn_icn {
  color: var(--hh1);
}
.ui_btn_switcher:hover .ui_btn_icn {
  transform: scale(var(--scale, 0.7));
}

.footerbottom .social-block ul li a::before {
  font-size: 22.5px;
}
.footerbottom .social-block ul li.instagram a::before {
  content: "\f16d";
  font-size: 23.25px;
}

samp.text-clip {
  --calc_cap: calc((var(--var_lh) - var(--var_cap)) * -.5);
  display: grid;
  grid-template: fit-content(var(--cap, var(--var_cap, 100%)))/fit-content(100%);
}
samp.text-clip > span {
  pointer-events: none;
  display: flex;
  color: inherit;
  font-size: var(--fz, var(--var_fz, inherit)) !important;
  line-height: var(--lh, var(--var_lh, 20px));
  height: max-content;
  white-space: break-spaces;
  margin-block: calc(var(--calc_cap) * -1);
}

.new-limit__link, .new-limit__label, .new-select__label {
  position: relative;
  font-size: 14px;
  font-family: var(--ff1-5);
  line-height: 1;
}
[class].new-limit__link, [class].new-limit__label, [class].new-select__label {
  color: #777;
}

.new-select {
  display: grid;
  grid-template-columns: max-content fit-content(180px);
  grid-auto-rows: max-content;
  gap: 5px 10px;
  align-items: baseline;
  position: relative;
}
.new-select__label {
  flex: 0 1;
  margin: 0;
}
.new-select select {
  position: absolute !important;
  inset: 0;
  height: unset !important;
  width: unset !important;
  border: none !important;
}
.new-select__select.customSelect {
  height: unset;
  border: none;
  display: flex !important;
  background-color: var(--dd1) !important;
  box-shadow: 0 0 5px -3px rgba(0, 0, 0, 0.5);
  border-radius: var(--bdrs-4);
  padding: 5px 0 5px 10px;
  width: fit-content;
  font-size: 14px;
  font-family: var(--ff1-5);
  line-height: 1.7;
}
.new-select__select.customSelect .customSelectInner {
  height: 100%;
  width: calc(100% + 27px) !important;
  padding-right: 22px;
  background-size: 17px 17px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20opacity%3D%220.6%22%3E%3Cg%20clip-path%3D%22url(%23clip0_375_12445)%22%3E%3Cg%20clip-path%3D%22url(%23clip1_375_12445)%22%3E%3Cpath%20d%3D%22M7%209.99988L12%2014.9999L17%209.99988%22%20stroke%3D%22%23363E49%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3Cdefs%3E%3CclipPath%20id%3D%22clip0_375_12445%22%3E%3Crect%20width%3D%2224%22%20height%3D%2224%22%20rx%3D%223%22%20fill%3D%22white%22%2F%3E%3C%2FclipPath%3E%3CclipPath%20id%3D%22clip1_375_12445%22%3E%3Crect%20width%3D%2224%22%20height%3D%2224%22%20rx%3D%223%22%20fill%3D%22white%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3C%2Fsvg%3E%0A") !important;
  background-position-x: calc(100% - 6px) !important;
  background-position-y: 50% !important;
  background-repeat: no-repeat !important;
}

.new-limit {
  display: flex;
  gap: 5px 10px;
  align-items: baseline;
}
.new-limit__row {
  display: flex;
  gap: var(--minmax_8_15);
}
.new-limit__link {
  flex-shrink: 0;
  display: flex;
  place-content: center;
  padding-bottom: 5px;
}
.new-limit__link:not(.new-limit__link_current):hover {
  border-bottom: 1.5px solid var(--hh1-2);
}
.new-limit__link_current {
  border-bottom: 1.5px dashed var(--hh1-2);
}
.new-limit__link_current[class] {
  color: var(--hh1);
}

/*----------------------------*/
.box_heading {
  margin: 0;
  text-transform: uppercase !important;
  color: var(--dd2) !important;
  letter-spacing: 0 !important;
  text-align: center !important;
  font-size: var(--size-fluid-3);
  font-family: var(--ff1-7) !important;
  border-bottom: 1px solid #d8c9c9 !important;
}
.box_head {
  text-align: center !important;
}
.box_content {
  margin: 0 !important;
  background-color: #f2f2f2 !important;
  border: none !important;
}
.box_section {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: var(--minmax_8_15);
  border: none !important;
}

.box_featured {
  padding: 0 !important;
  grid-gap: var(--minmax_15_25) var(--minmax_8_15);
  position: relative;
}
.box_featured .customNavigation {
  position: static !important;
  display: flex;
  justify-content: space-between;
  grid-gap: calc(var(--minmax_8_15) / 2);
  width: auto;
}
.box_featured .customNavigation > * {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
}
.box_featured .customNavigation > *::before {
  font-size: 22px !important;
  line-height: 1 !important;
  width: 100%;
  text-align: center;
}
.box_featured .box_wrapper {
  display: grid;
  grid-template-columns: 100%;
  min-width: 0;
  grid-gap: var(--minmax_8_15) var(--minmax_8_15);
  padding-top: var(--minmax_8_15);
  padding-bottom: var(--minmax_8_15);
}
.box_featured .box_container:not(.slider) {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr max-content;
  grid-auto-rows: max-content;
  padding: 0;
}

.header-cms {
  background: url(../image/codezeel/headphone_white.svg) no-repeat scroll 0 7px transparent;
  background-size: 31px;
  padding-left: 38px;
  position: relative;
}
.header-cms-block {
  display: inline-grid;
}

.headercms-title {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.8px;
  text-transform: capitalize;
  color: #fff;
  line-height: 22px;
}
@media screen and (min-width: 280px) and (max-width: 553.4px) {
  .headercms-title {
    line-height: 18px;
    font-size: 12px;
  }
}

.headercms-subtitle {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #afbc25;
  line-height: 22px;
}
.headercms-subtitle a {
  white-space: nowrap;
  color: #fff;
  font-size: 19px;
}
@media screen and (min-width: 280px) and (max-width: 553.4px) {
  .headercms-subtitle a {
    font-size: 19px;
    letter-spacing: 0.06em;
  }
}

/*----------------------------*/
/*----------------------------*/
.column_aside {
  display: grid !important;
  grid-template-columns: 100%;
  grid-gap: 20px;
  padding-bottom: 20px;
}
@media screen and (min-width: 280px) and (max-width: 991.6px) {
  .column_aside .box.bestseller {
    order: -1;
  }
}
.column_aside > * {
  margin: 0 !important;
}

#column-left {
  display: grid !important;
}

#column-left .box {
  width: min(720px, 100%);
  margin-left: auto;
  margin-right: auto;
}

#column-left .box .box-content {
  display: block !important;
}

.breadcrumbs_contain {
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-gap: 10px 26px;
  align-items: baseline;
  overflow: auto hidden;
  overflow-clip-margin: 15px;
}
.breadcrumbs_link {
  display: inline-flex;
  font-size: 14px;
  position: relative;
  transition: 250ms, text-decoration-thickness 0.3s;
}
.breadcrumbs_link:not(.is_current) {
  color: #777;
  font-family: var(--ff1-3);
  text-decoration: underline;
  text-decoration-thickness: 1.5px;
  text-decoration-color: transparent;
}
.breadcrumbs_link:not(.is_current):hover {
  text-decoration-color: var(--hh1);
  text-decoration-style: solid;
}
.breadcrumbs_link:not(.is_current)::after {
  content: "/";
  position: absolute;
  top: 0;
  left: calc(100% + 8.6666666667px);
  text-shadow: none;
}
.breadcrumbs_link.is_current {
  color: var(--dd2);
  font-family: var(--ff1-5);
}

.header {
  display: grid;
  grid-auto-rows: minmax(40px, max-content);
  grid-template-columns: 40px 0.5fr 1.2fr max-content 40px;
  /** Our system will use a 4px base vertical rhythm */
  /** Our system's base font size */
  /**
   * * Calculate the type offset for a given font
   * *
   * * @param {number} $lh - the font's base line height
   * * @param {number} $fontSize - the font's size
   * * @param {number} $descenderHeightScale - the font's descender height as a ratio
   * * @return {number} the offset to be added to a transformY to keep the text in place
   * */
  /**
   * * The basekick base function
   * *
   * * @param {number} $typeSizeModifier - a multiplier to determine the font size
   * * @param {number} $typeRowSpan - how many rows of our vertical rhythm should the type span
   * * @param {number} $descenderHeightScale - the height of the descender expressed as a ratio of the font
   * * @param {number} $capHeight - the font's cap height expressed as a ratio of the font
   * */
  /**
   * * The trim mixin to apply base-kick styles
   * *
   * * @param {number} $typeSizeModifier - a multiplier for our system's $baseFontSize
   * * @param {number} $typeRowSpan - the height of the descender expressed as a ratio of the font
   * */
  /**
   * * The trim mixin to apply base-kick styles
   * *
   * * @param {16px / 14px} =>  mark_up_font_size / default_font_size => size modifier
   * * @param {11} => mark_up_text_height_trim => 22px line_height on site
   * *
   * * @use "sass:math"
   * *
   * * @include trim(16, 11)
   * **/
  /** Our system will use a 4px base vertical rhythm */
  /** Our system's base font size */
  /**
   * * Calculate the type offset for a given font
   * *
   * * @param {number} $lh - the font's base line height
   * * @param {number} $fontSize - the font's size
   * * @param {number} $descenderHeightScale - the font's descender height as a ratio
   * * @return {number} the offset to be added to a transformY to keep the text in place
   * */
  /**
   * * The basekick base function
   * *
   * * @param {number} $typeSizeModifier - a multiplier to determine the font size
   * * @param {number} $typeRowSpan - how many rows of our vertical rhythm should the type span
   * * @param {number} $descenderHeightScale - the height of the descender expressed as a ratio of the font
   * * @param {number} $capHeight - the font's cap height expressed as a ratio of the font
   * */
  /**
   * * The trim mixin to apply base-kick styles
   * *
   * * @param {number} $typeSizeModifier - a multiplier for our system's $baseFontSize
   * * @param {number} $typeRowSpan - the height of the descender expressed as a ratio of the font
   * */
  /**
   * * The trim mixin to apply base-kick styles
   * *
   * * @param {16px / 14px} =>  mark_up_font_size / default_font_size => size modifier
   * * @param {11} => mark_up_text_height_trim => 22px line_height on site
   * *
   * * @use "sass:math"
   * *
   * * @include trim(16, 11)
   * **/
  /** Our system will use a 4px base vertical rhythm */
  /** Our system's base font size */
  /**
   * * Calculate the type offset for a given font
   * *
   * * @param {number} $lh - the font's base line height
   * * @param {number} $fontSize - the font's size
   * * @param {number} $descenderHeightScale - the font's descender height as a ratio
   * * @return {number} the offset to be added to a transformY to keep the text in place
   * */
  /**
   * * The basekick base function
   * *
   * * @param {number} $typeSizeModifier - a multiplier to determine the font size
   * * @param {number} $typeRowSpan - how many rows of our vertical rhythm should the type span
   * * @param {number} $descenderHeightScale - the height of the descender expressed as a ratio of the font
   * * @param {number} $capHeight - the font's cap height expressed as a ratio of the font
   * */
  /**
   * * The trim mixin to apply base-kick styles
   * *
   * * @param {number} $typeSizeModifier - a multiplier for our system's $baseFontSize
   * * @param {number} $typeRowSpan - the height of the descender expressed as a ratio of the font
   * */
  /**
   * * The trim mixin to apply base-kick styles
   * *
   * * @param {16px / 14px} =>  mark_up_font_size / default_font_size => size modifier
   * * @param {11} => mark_up_text_height_trim => 22px line_height on site
   * *
   * * @use "sass:math"
   * *
   * * @include trim(16, 11)
   * **/
}
@media screen and (min-width: 553px) {
  .header {
    grid-template-columns: 40px 0.5fr 1.2fr max-content max-content;
  }
}
@media screen and (min-width: 280px) and (max-width: 414.4px) {
  .header {
    grid-template-areas: "w w  w r r" "a q q q d";
  }
}
@media screen and (min-width: 414px) and (max-width: 553.4px) {
  .header {
    grid-template-areas: "w w  w r r" "a q e e d";
  }
}
@media screen and (min-width: 553px) and (max-width: 990.4px) {
  .header {
    grid-template-areas: "q q  w r r" "a e e e d";
  }
}
@media screen and (min-width: 280px) and (max-width: 990.4px) {
  .header {
    background-color: #363734;
    padding: var(--minmax_8_15) var(--tip_offset);
    grid-gap: 4px var(--minmax_8_15);
  }
}
@media screen and (min-width: 990.4px) {
  .header {
    background-color: transparent;
    grid-gap: 0;
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
  }
}
.header.fixed {
  background-color: var(--cc3);
  padding: var(--minmax_8_15);
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.header_contain {
  display: contents;
}
@media screen and (min-width: 990.4px) {
  .header_contain {
    display: grid;
    background-color: #363734;
    align-content: start;
    grid-auto-rows: max-content;
    align-items: center;
    grid-gap: var(--minmax_8_15);
    padding: calc(var(--minmax_8_15) * 2) var(--tip_offset);
    grid-template-columns: fit-content(var(--verticalmenu_width)) minmax(280px, 0.5fr) minmax(280px, 1fr) max-content;
    grid-template-areas: "q w e r";
  }
}
.header_contain > * {
  padding: 0 !important;
  margin: 0 !important;
}
.header_logo {
  grid-area: q;
}
@media screen and (min-width: 280px) and (max-width: 414.4px) {
  .header_logo {
    justify-content: center;
    width: auto;
  }
}
.header_logo img {
  object-fit: contain;
}
@media screen and (min-width: 280px) and (max-width: 990.4px) {
  .header_logo img {
    width: 75%;
  }
}
@media screen and (min-width: 990.4px) {
  .header_logo img {
    width: 100%;
  }
}
.header_search {
  grid-area: e;
}
.header_search button {
  width: auto !important;
  padding-inline: 8px !important;
  background-color: var(--cc1) !important;
}
@media screen and (min-width: 990px) {
  .header_search button {
    min-width: 100px !important;
  }
}
.header_search button > * {
  pointer-events: none;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.3));
}
.header_search button:hover {
  background-color: var(--hh1) !important;
}
.header_search button > * {
  flex-shrink: 0 !important;
}
@media screen and (min-width: 280px) and (max-width: 990.4px) {
  .header_search input, .header_search button {
    height: 100% !important;
  }
}
.header_content {
  grid-area: w;
}
@media screen and (min-width: 280px) and (max-width: 553.4px) {
  .header_content .header-cms {
    padding-left: 0 !important;
    background-image: none !important;
  }
  .header_content .headercms-title {
    display: none !important;
  }
}
.header_language {
  grid-area: r;
}
@media screen and (min-width: 280px) and (max-width: 553.4px) {
  .header_logo img {
    height: 38px;
    width: auto;
  }
}
@media screen and (min-width: 280px) and (max-width: 990.4px) {
  .header_cart, .header_vertical_menu .box-heading {
    grid-area: d;
  }
  .header #res-menu {
    grid-area: a;
  }
}
.header_logo {
  display: flex;
  align-items: center;
  width: 100%;
}
@media screen and (min-width: 990.6px) {
  .header_logo {
    padding-left: calc(var(--minmax_8_15) / 2) !important;
  }
}
.header_search {
  padding: 0;
}
.header_search button {
  min-width: 44px;
}
.header_content {
  color: var(--dd1);
}
@media screen and (min-width: 280px) and (max-width: 553.4px) {
  .header_content {
    place-self: center start;
  }
}
@media screen and (min-width: 553px) {
  .header_content {
    place-self: center center;
  }
}
.header_language {
  display: flex;
}
.header_language .language-select span {
  padding-bottom: 5px !important;
  padding-top: 5px !important;
  border-bottom: 1.5px dashed transparent !important;
}
.header_language .language-select.active span {
  border-bottom-color: var(--hh1) !important;
}
.header_language #form-language {
  gap: 4px var(--minmax_8_15);
}
.header_language #form-language img {
  margin-right: 0;
}
@media screen and (min-width: 280px) and (max-width: 414.4px) {
  .header #search {
    display: none !important;
  }
}
.header_bottom {
  display: contents;
  --hb_height: 30px;
}
@media screen and (min-width: 990.4px) {
  .header_bottom {
    --hb_height: 50px;
    --verticalmenu: calc(var(--verticalmenu_width) + var(--hb_height) / 2 - var(--HS_offset) / 2);
    display: grid;
  }
}
.header_bottom.common-home {
  grid-template-columns: 100%;
  grid-template-rows: var(--hb_height);
  background-color: transparent;
}
@media screen and (min-width: 990.4px) {
  .header_bottom.common-home::before {
    content: "";
    grid-column: 1;
    grid-row: 1/2;
    background-color: #31312f;
    width: 100%;
    box-shadow: 0 4px 3px -1px rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 1;
  }
}
.header_bottom:not(.common-home) {
  padding: 0 var(--tip_offset);
  background-color: #31312f;
  grid-template-columns: minmax(0, var(--verticalmenu)) 1fr max-content;
  grid-template-rows: var(--hb_height);
}
.header_bottom:not(.header_bottom.common-home) .header_bottom_overlay {
  display: contents;
}
@media screen and (min-width: 990.4px) {
  .header_bottom.common-home .header_bottom_overlay {
    grid-column: 1;
    grid-row: 1/span 2;
    padding: 0 var(--tip_offset);
    display: grid;
    grid-template-columns: minmax(0, var(--verticalmenu)) 1fr max-content;
    grid-template-rows: var(--hb_height) max-content;
  }
}
@media screen and (min-width: 280px) and (max-width: 990.4px) {
  .header_bottom.common-home .header_bottom_overlay {
    display: contents !important;
  }
}
.header_bottom.common-home .header_bottom_overlay #verticalmenublock {
  grid-row: span 2;
  z-index: 6;
}
.header_bottom.common-home .header_bottom_overlay #verticalmenublock .box-content {
  position: static;
}
@media screen and (min-width: 280px) and (max-width: 990.4px) {
  .header_bottom.common-home .header_bottom_overlay #verticalmenublock ~ .main-slider {
    display: none !important;
  }
}
.header_bottom.common-home .header_bottom_overlay .main-slider {
  grid-row: 2;
  grid-column: 2/-1;
  --MS_bdrs: 0 0 var(--bdrs-8) var(--bdrs-8);
  background-color: rgba(var(--rr1)/30%);
  background-clip: content-box;
  border-radius: var(--MS_bdrs) !important;
  position: relative;
}
@media screen and (min-width: 990.4px) {
  .header_bottom.common-home .header_bottom_overlay .main-slider {
    margin-left: calc(var(--HS_offset) / -1) !important;
    margin-bottom: var(--HS_offset) !important;
  }
}
@media screen and (min-width: 990.4px) {
  .header_bottom.common-home .header_bottom_overlay .main-slider .swiper,
  .header_bottom.common-home .header_bottom_overlay .main-slider .swiper-wrapper,
  .header_bottom.common-home .header_bottom_overlay .main-slider .swiper-slide {
    height: 100% !important;
  }
}
.header_bottom.common-home .header_bottom_overlay .swiper-slide img {
  object-position: right bottom;
}
.header_bottom.common-home .header_bottom_overlay #spinner {
  z-index: 4;
}
.header_bottom.common-home .header_bottom_overlay .swiper-viewport {
  z-index: 3;
}
.header_bottom_overlay > * {
  width: unset !important;
  margin: 0 !important;
}
.header .nav.navbar-nav,
.header .header-cart {
  height: 100%;
}
.header .nav.navbar-nav {
  display: flex;
  align-items: center;
}
.header .main-menu ul > li > a, .header .hiden_menu li > a {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.header .header_cart, .header .header_vertical_menu .box-heading, .header_vertical_menu .header .box-heading {
  cursor: pointer;
  position: relative;
  background-color: var(--cc1);
  border-radius: var(--bdrs-4);
  transition: 300ms;
}
@media screen and (min-width: 990.4px) {
  .header .header_cart, .header .header_vertical_menu .box-heading, .header_vertical_menu .header .box-heading {
    border-radius: var(--bdrs-8);
    margin-block: calc(var(--HS_offset) / -2) !important;
  }
}
.header .header_cart > *, .header .header_vertical_menu .box-heading > *, .header_vertical_menu .header .box-heading > * {
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.3));
  pointer-events: none;
}
.header .header_cart:hover, .header .header_vertical_menu .box-heading:hover, .header_vertical_menu .header .box-heading:hover {
  background-color: var(--hh1);
}
.header .header_cart#cart, .header .header_vertical_menu #cart.box-heading, .header_vertical_menu .header #cart.box-heading {
  display: grid;
  color: var(--dd1);
  padding-block: calc(var(--minmax_8_15) / 1.7) calc(var(--minmax_8_15) / 1.2);
  padding-inline: calc(var(--minmax_8_15) / 1.15);
  box-shadow: 2px 5px 22px rgba(0, 0, 0, 0.4);
  z-index: 6;
  grid-gap: 0 calc(var(--minmax_8_15) / 2);
  align-items: center;
  grid-template-columns: max-content max-content;
  grid-template-rows: max-content;
}
@media screen and (min-width: 280px) and (max-width: 553.4px) {
  .header .header_cart#cart, .header .header_vertical_menu #cart.box-heading, .header_vertical_menu .header #cart.box-heading {
    gap: 0;
  }
}
@media screen and (min-width: 280px) and (max-width: 990.4px) {
  .header .header_cart#cart .header_cart_icon, .header .header_vertical_menu #cart.box-heading .header_cart_icon, .header_vertical_menu .header #cart.box-heading .header_cart_icon {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .header .header_cart#cart .header_cart_icon svg, .header .header_vertical_menu #cart.box-heading .header_cart_icon svg, .header_vertical_menu .header #cart.box-heading .header_cart_icon svg {
    height: auto;
    width: 25px;
  }
}
@media screen and (min-width: 990.4px) {
  .header .header_cart#cart, .header .header_vertical_menu #cart.box-heading, .header_vertical_menu .header #cart.box-heading {
    margin-inline: calc(var(--HS_offset) * -1) !important;
    border: none !important;
  }
}
.header .header_cart#cart.zero .header_cart_total, .header .header_vertical_menu #cart.zero.box-heading .header_cart_total, .header_vertical_menu .header #cart.zero.box-heading .header_cart_total, .header .header_cart#cart.zero .header_cart_quantity, .header .header_vertical_menu #cart.zero.box-heading .header_cart_quantity, .header_vertical_menu .header #cart.zero.box-heading .header_cart_quantity {
  display: none;
}
.header .header_cart#cart.zero .header_cart_icon, .header .header_vertical_menu #cart.zero.box-heading .header_cart_icon, .header_vertical_menu .header #cart.zero.box-heading .header_cart_icon {
  grid-column: 2;
}
.header .header_cart#cart.zero .header_cart_title, .header .header_vertical_menu #cart.zero.box-heading .header_cart_title, .header_vertical_menu .header #cart.zero.box-heading .header_cart_title {
  grid-column: 1;
  grid-row: 1/span 2;
  place-self: center;
}
.header .header_cart > button, .header .header_vertical_menu .box-heading > button, .header_vertical_menu .header .box-heading > button {
  display: contents !important;
}
.header .header_cart_icon {
  align-self: end;
  grid-column: 1;
  grid-row: 1/span 2;
  transform: translate(0px, 4px) rotate(1deg) scaleX(-1);
}
.header .header_cart_title {
  grid-column: 2;
  grid-row: 1;
  place-self: end;
  letter-spacing: 0.04em;
  line-height: 1.1;
  font-family: var(--ff1-4);
  min-width: 68px;
}
@media screen and (min-width: 280px) and (max-width: 990.4px) {
  .header .header_cart_title {
    font-size: var(--size-5);
  }
}
@media screen and (min-width: 990.4px) {
  .header .header_cart_title {
    font-size: var(--size-5);
  }
}
@media screen and (min-width: 280px) and (max-width: 553.4px) {
  .header .header_cart_title {
    display: none;
  }
}
.header .header_cart_total {
  grid-column: 2;
  grid-row: 2;
  font-family: var(--ff1-7);
  font-size: var(--size-fluid-1);
}
@media screen and (min-width: 280px) and (max-width: 553.4px) {
  .header .header_cart_total {
    display: none;
  }
}
.header .header_cart_quantity {
  grid-column: 1;
  grid-row: 1;
}
.header .header_cart_quantity {
  --fz: 24px;
  --fz_currency: 14px;
  --fz_old: 14px;
  --line_height: 5px;
  --distance: 3px;
  --price_gap: 10px;
  --color_old: #555;
  --color_old-line: #d8c9c9;
  align-items: center;
  background-color: rgb(249, 17, 85);
  border-radius: 7px;
  color: var(--dd1);
  display: flex;
  font-family: var(--ff1-5);
  text-indent: 2px;
  font-size: 9px;
  font-weight: 500;
  grid-column: 1;
  grid-row: 1;
  justify-content: center;
  min-height: 14px;
  min-width: 14px;
  padding: 1px 2.3px 1px 1px;
  place-self: end start;
  pointer-events: none;
  position: absolute;
  letter-spacing: -0.003em;
  right: 80%;
  top: 50%;
  transform: translate(0.1vw, -122%) translatex(2.4px) skew(-3deg) rotate(1.5deg);
  transition: 250ms;
  line-height: 0.7;
}
@media screen and (min-width: 280px) and (max-width: 553.4px) {
  .header .header_cart_quantity {
    left: auto;
    right: 45%;
    justify-self: center;
  }
}
.header .header_cart:hover .header_cart_quantity, .header .header_vertical_menu .box-heading:hover .header_cart_quantity, .header_vertical_menu .header .box-heading:hover .header_cart_quantity {
  z-index: 2;
  border-radius: 3px;
  box-shadow: none;
  padding: 1px 2px 2px;
  text-indent: 0;
  min-height: 12px;
  transform: translate(0.13vw, -118%) scale(2.3) !important;
  line-height: 0.8;
}
.header .header_cart_total {
  font-size: 16px;
}
.header .header_cart_total.zero {
  display: none !important;
}
.header .header_cart_total.zero + .header .header_cart_quantity {
  display: none !important;
}
.header .header_cart_integer, .header .header_cart_currency {
  text-shadow: 1px 1px 0.7px rgba(0, 0, 0, 0.1) !important;
  line-height: 1 !important;
  font-family: var(--ff1-5) !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  z-index: 2;
  font-style: italic;
  color: var(--dd2);
}
.header .header_cart_currency {
  font-size: 10px !important;
  text-transform: uppercase;
}
.header .responsive-menu .main-navigation {
  top: calc(100% + 8px);
  width: 100vw;
  left: -8px;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.header_vertical_menu {
  margin-inline: calc(var(--HS_offset) / -2) !important;
  display: grid;
  width: auto !important;
  grid-template-columns: 100%;
  grid-template-rows: minmax(var(--hb_height), auto) 1fr;
  filter: drop-shadow(2px 5px 16px rgba(0, 0, 0, 0.4));
}
@media screen and (min-width: 280px) and (max-width: 990.4px) {
  .header_vertical_menu {
    display: none !important;
  }
}
.header_vertical_menu .box-heading {
  padding-inline: calc(var(--minmax_8_15) + var(--HS_offset) / 2 + 3px) !important;
  display: flex;
  align-items: center;
}
.header_vertical_menu .box-heading .dropdown-arrow {
  order: -1;
}
.header_vertical_menu .box-heading::before {
  transition: 300ms;
  order: 3;
  margin-left: auto;
}
.header_vertical_menu .box-content {
  top: calc(100% - 1px);
  box-shadow: none;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  visibility: visible !important;
  pointer-events: none;
  transform-origin: 50% 100% !important;
  transition: 250ms, transform 350ms var(--trs21) !important;
  transform: translateY(5%) scaleY(0.97) !important;
}
.header_vertical_menu .box-content .dropmenu {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: max-content;
  align-content: start;
  background-color: transparent;
  padding: 0 0 12px !important;
}
.header_vertical_menu .box-content .dropmenu .top_level:first-child:nth-last-child(9), .header_vertical_menu .box-content .dropmenu .top_level:first-child:nth-last-child(9) ~ .top_level {
  --top_lavel_fz: 15px;
  --top_lavel_lh: 1.3;
  --tl_pdblock_start: 6px;
  --tl_pdblock_end: 8px;
  --top_lavel_bgp-y: 8px;
}
.header_vertical_menu .box-content .dropmenu .top_level a {
  font-size: var(--top_lavel_fz, 15px) !important;
  padding-block: var(--tl_pdblock, var(--tl_pdblock_start, 9px) var(--tl_pdblock_end, 7px)) !important;
  padding-inline: var(--top_lavel_pdinline, var(--tl_pdinline_start, 29px) var(--tl_pdinline_end, 4px)) !important;
  font-family: var(--ff1-3) !important;
  font-weight: 300 !important;
  line-height: var(--top_lavel_lh, 1.4) !important;
  letter-spacing: 0.02em !important;
  border-radius: var(--bdrs-4) !important;
  background-position: 10px var(--top_lavel_bgp-y, 11px) !important;
}
.header_vertical_menu .box-content .dropmenu .top_level a:hover {
  background-color: var(--hh1);
}
.header_vertical_menu:not(.open) .box-heading {
  height: calc(100% + var(--HS_offset)) !important;
  transform-origin: 100% 50% !important;
}
.header_vertical_menu:not(.open) .box-heading:hover::before {
  transform: translateY(4px);
}
.header_vertical_menu:not(.open) .box-content > * {
  opacity: 0;
}
.header_vertical_menu.open .box-heading {
  transform-origin: 50% 50% !important;
  transition: 300ms, transform 500ms var(--trs121), height 275ms var(--trs121) 50ms, border-radius 200ms 100ms ease-out !important;
  border-radius: var(--bdrs-8) var(--bdrs-8) 0 0 !important;
  transform: translateY(calc(var(--HS_offset) / -1));
  height: calc(100% + var(--HS_offset) * 2 + 1px) !important;
  box-shadow: none;
}
.header_vertical_menu.open .box-heading::before {
  transition: 250ms 100ms;
  transform: scale(1, -1);
}
.header_vertical_menu.open .box-heading:hover + .box-content {
  background-color: var(--hh1) !important;
}
.header_vertical_menu.open .box-content {
  transition: 300ms, transform 250ms var(--trs12) 55ms, border-radius 200ms 100ms ease-out !important;
  box-shadow: none;
  border-radius: 0 0 var(--bdrs-8) var(--bdrs-8);
  pointer-events: auto;
  transform: translateY(0) scaleY(1) !important;
}
.header_menu {
  height: var(--hb_height);
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--minmax_8_15) / 1);
  padding-block: calc(var(--minmax_8_15) / 2);
  padding-inline: 0;
  margin-inline: calc(var(--HS_offset) / 2) !important;
}
@media screen and (min-width: 280px) and (max-width: 990.4px) {
  .header_menu {
    display: contents !important;
    background-color: #fff;
  }
  .header_menu #res-menu {
    background-color: var(--dd1);
    border-radius: var(--bdrs-4);
  }
  .header_menu > * {
    margin: 0 !important;
  }
}
.header_menu::before {
  display: none;
}
.header_menu .nav-inner,
.header_menu #menu .nav.navbar-nav,
.header_menu #menu .top_level {
  display: contents !important;
}
@media screen and (min-width: 990.4px) {
  .header_menu #menu.main-menu {
    display: contents !important;
  }
}
.header_menu #menu.main-menu a {
  font-family: var(--ff1-3);
  background-color: rgb(var(--rr1)/10%);
  padding: calc(var(--minmax_8_15) / 2) var(--minmax_8_15);
  border-radius: var(--bdrs-4);
  transition: 300ms;
  height: 100%;
  text-transform: unset;
}
.header_menu #menu.main-menu a:hover {
  background-color: var(--hh1);
  color: var(--cc3);
}
@media screen and (min-width: 280px) and (max-width: 990.4px) {
  .header .nav-responsive {
    display: contents !important;
    margin: 0 !important;
    min-width: 44px;
  }
  .header .nav-responsive .expandable {
    width: 100%;
    height: 100%;
    margin: 0 !important;
  }
  .header .nav-responsive .main-navigation {
    width: fit-content;
  }
}

.search {
  display: flex !important;
}
.search_label {
  display: contents;
}
.search_inp {
  flex-grow: 1;
  appearance: none !important;
  border-radius: var(--bdrs-4) 0 0 var(--bdrs-4) !important;
}

/*----------------------------*/
.mfp {
  /** Our system will use a 4px base vertical rhythm */
  /** Our system's base font size */
  /**
   * * Calculate the type offset for a given font
   * *
   * * @param {number} $lh - the font's base line height
   * * @param {number} $fontSize - the font's size
   * * @param {number} $descenderHeightScale - the font's descender height as a ratio
   * * @return {number} the offset to be added to a transformY to keep the text in place
   * */
  /**
   * * The basekick base function
   * *
   * * @param {number} $typeSizeModifier - a multiplier to determine the font size
   * * @param {number} $typeRowSpan - how many rows of our vertical rhythm should the type span
   * * @param {number} $descenderHeightScale - the height of the descender expressed as a ratio of the font
   * * @param {number} $capHeight - the font's cap height expressed as a ratio of the font
   * */
  /**
   * * The trim mixin to apply base-kick styles
   * *
   * * @param {number} $typeSizeModifier - a multiplier for our system's $baseFontSize
   * * @param {number} $typeRowSpan - the height of the descender expressed as a ratio of the font
   * */
  /**
   * * The trim mixin to apply base-kick styles
   * *
   * * @param {16px / 14px} =>  mark_up_font_size / default_font_size => size modifier
   * * @param {11} => mark_up_text_height_trim => 22px line_height on site
   * *
   * * @use "sass:math"
   * *
   * * @include trim(16, 11)
   * **/
  /* &-wrap */
  /* &-wrap.mfp-ready */
  /* &-wrap.mfp-removing */
}
.mfp-preloader {
  display: none;
}
.mfp-wrap {
  position: fixed !important;
  z-index: 2101 !important;
  top: 0 !important;
  left: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  overflow: hidden !important;
}
.mfp-container {
  text-align: right !important;
  padding: 0 !important;
}
.mfp-container::before {
  display: none !important;
}
.mfp-content {
  display: grid !important;
  grid-template-columns: 100vw;
  grid-template-rows: 100vh;
  pointer-events: none;
  backdrop-filter: blur(2px);
}
.mfp-bg {
  width: unset !important;
  height: unset !important;
  position: fixed !important;
  inset: 0 !important;
  z-index: 2100 !important;
}
.mfp-frame {
  --mfp_frame_offset_size: 3vmin;
  --mfp_frame_inset_ver: min(3vmin, 18px);
  --mfp_frame_inset_hor: min(5.8024vmin, 25px);
  --mfp_frame_radius_size: var(--bdrs-12);
  --mfp_header_min_height: 62px;
  --mfp_header_inset_size: min(2vmin, 10px);
  --mfp_footer_inset_top: min(2vmin, 13px);
  --mfp_footer_inset_bottom: min(2.5vmin, 13px);
  --mfp_outside_close_btn_size: var(--mfp_header_min_height);
  --mfp_inside_close_btn_size: 30px;
  --mfp_inside_close_btn_bdrs: var(--bdrs-4);
  --mfp_frame_offset_top_val: var(--mfp_frame_offset_top, var(--mfp_frame_offset_ver, var(--mfp_frame_offset_size)));
  --mfp_frame_offset_right_val: var(--mfp_frame_offset_right, var(--mfp_frame_offset_hor, var(--mfp_frame_offset_size)));
  --mfp_frame_offset_bottom_val: var(--mfp_frame_offset_bottom, var(--mfp_frame_offset_ver, var(--mfp_frame_offset_size)));
  --mfp_frame_offset_left_val: var(--mfp_frame_offset_left, var(--mfp_frame_offset_hor, var(--mfp_frame_offset_size)));
  --mfp_frame_inset_top_val: var(--mfp_frame_inset_top, var(--mfp_frame_inset_ver, var(--mfp_frame_inset_size)));
  --mfp_frame_inset_right_val: var(--mfp_frame_inset_right, var(--mfp_frame_inset_hor, var(--mfp_frame_inset_size)));
  --mfp_frame_inset_bottom_val: var(--mfp_frame_inset_bottom, var(--mfp_frame_inset_ver, var(--mfp_frame_inset_size)));
  --mfp_frame_inset_left_val: var(--mfp_frame_inset_left, var(--mfp_frame_inset_hor, var(--mfp_frame_inset_size)));
  --mfp_outside_close_btn_width_val: var(--mfp_outside_close_btn_width, var(--mfp_outside_close_btn_size));
  --mfp_outside_close_btn_height_val: var(--mfp_outside_close_btn_height, var(--mfp_outside_close_btn_size));
  --mfp_inside_close_btn_width_val: var(--mfp_inside_close_btn_width, var(--mfp_inside_close_btn_size));
  --mfp_inside_close_btn_height_val: var(--mfp_inside_close_btn_height, var(--mfp_inside_close_btn_size));
  --mfp_header_inset_top_val: var(--mfp_header_inset_top, var(--mfp_header_inset_size, var(--mfp_frame_inset_top_val)));
  --mfp_header_inset_bottom_val: var(--mfp_header_inset_bottom, var(--mfp_header_inset_size, var(--mfp_frame_inset_bottom_val)));
  --mfp_main_inset_top_val: var(--mfp_main_inset_top, var(--mfp_main_inset_size, var(--mfp_frame_inset_top_val)));
  --mfp_main_inset_bottom_val: var(--mfp_main_inset_bottom, var(--mfp_main_inset_size, var(--mfp_frame_inset_bottom_val)));
  --mfp_footer_inset_top_val: var(--mfp_footer_inset_top, var(--mfp_footer_inset_size, var(--mfp_frame_inset_top_val)));
  --mfp_footer_inset_bottom_val: var(--mfp_footer_inset_bottom, var(--mfp_footer_inset_size, var(--mfp_frame_inset_bottom_val)));
  pointer-events: auto;
  grid-template-columns: var(--mfp_frame_columns, 100%);
  grid-template-rows: var(--mfp_frame_rows, max-content 1fr max-content);
  grid-auto-rows: max-content;
  filter: drop-shadow(-2px 2px 6px rgba(0, 0, 0, 0.15));
  display: grid !important;
  background-color: var(--mfp_frame_bgc, transparent) !important;
  max-width: var(--mfp_frame_max_width, 100%) !important;
  max-height: var(--mfp_frame_max_height, -webkit-fill-available) !important;
  overflow: unset !important;
  position: relative !important;
  width: var(--mfp_frame_width_val, fit-content);
  height: var(--mfp_frame_height_val, max-content);
  min-height: var(--mfp_frame_min_height, 67vh);
  place-self: center;
  margin: var(--mfp_frame_offset_top_val) var(--mfp_frame_offset_right_val) var(--mfp_frame_offset_bottom_val) var(--mfp_frame_offset_left_val);
}
@supports (min-height: 100svh) {
  .mfp-frame {
    min-height: var(--mfp_frame_min_height, 67svh);
  }
}
.mfp-frame:not(.mfp_full_size):not(.mfp_full_width) {
  --mfp_frame_width_val: min(max(80vw, 80vmin), var(--mfp_frame_width, var(--mfp_frame_size, 545px)));
}
.mfp-frame:not(.close_btn_left):not(.mfp_right) {
  --mfp_frame_offset_right: calc(var(--mfp_frame_offset_top_val) / 2 + var(--mfp_outside_close_btn_width_val));
}
.mfp-frame.mfp_full_size, .mfp-frame.mfp_full_width {
  --mfp_frame_width_val: min(max(80vw, 80vmin), calc(var(--mfp_frame_width, var(--mfp_frame_size, 100%)) - var(--mfp_frame_offset_left_val) - var(--mfp_frame_offset_right_val)));
}
.mfp-frame.close_btn_left, .mfp-frame.mfp_right {
  --mfp_frame_offset_left: calc(var(--mfp_frame_offset_top_val) / 2 + var(--mfp_outside_close_btn_width_val));
}
.mfp-frame:not(.mfp_full_size):not(.mfp_full_height) {
  --mfp_frame_height_val: var(--mfp_frame_height, var(--mfp_frame_size, max-content));
}
.mfp-frame.mfp_full_size, .mfp-frame.mfp_full_height {
  --mfp_frame_height_val: calc(100% - var(--mfp_frame_height, var(--mfp_frame_size, 0px)) - var(--mfp_frame_offset_top_val) - var(--mfp_frame_offset_bottom_val));
}
.mfp-frame.mfp_full_width {
  justify-self: stretch;
}
.mfp-frame.mfp_full_height {
  align-self: stretch;
}
.mfp-frame.mfp_full_size {
  justify-self: stretch;
  align-self: stretch;
}
.mfp-frame.mfp_top {
  align-self: start;
}
.mfp-frame.mfp_bottom {
  align-self: end;
}
.mfp-frame.mfp_left {
  justify-self: start;
}
.mfp-frame.mfp_right {
  justify-self: end;
}
.mfp-frame, .mfp-frame > .mfp-close, .mfp-frame .mfp-contain, .mfp-frame .mfp-header, .mfp-frame .mfp-main, .mfp-frame .mfp-footer {
  --mfp_frame_top_left_radius_val: var(--mfp_frame_top_left_radius, var(--mfp_frame_radius_size));
  --mfp_frame_top_right_radius_val: var(--mfp_frame_top_right_radius, var(--mfp_frame_radius_size));
  --mfp_frame_bot_right_radius_val: var(--mfp_frame_bot_right_radius, var(--mfp_frame_radius_size));
  --mfp_frame_bot_left_radius_val: var(--mfp_frame_bot_left_radius, var(--mfp_frame_radius_size));
  border-radius: var(--mfp_frame_top_left_radius_val) var(--mfp_frame_top_right_radius_val) var(--mfp_frame_bot_right_radius_val) var(--mfp_frame_bot_left_radius_val) !important;
}
.mfp-frame .mfp-contain {
  display: contents;
}
.mfp-frame .mfp-contain > * {
  margin: 0;
}
@media screen and (min-width: 280px) and (max-width: 562.7px) {
  .mfp-frame .mfp-main, .mfp-frame .mfp-footer {
    margin-left: -47px;
  }
}
.mfp-frame .mfp-header, .mfp-frame .mfp-main, .mfp-frame .mfp-footer {
  padding-left: var(--mfp_frame_inset_left_val);
  padding-right: var(--mfp_frame_inset_right_val);
}
.mfp-frame .mfp-header::before, .mfp-frame .mfp-header::after, .mfp-frame .mfp-main::before, .mfp-frame .mfp-main::after, .mfp-frame .mfp-footer::before, .mfp-frame .mfp-footer::after {
  display: none;
}
.mfp-frame .mfp-header {
  grid-template-columns: 1fr max-content;
}
.mfp-frame .mfp-footer {
  --mfp_frame_top_right_radius: 0;
  --mfp_frame_top_left_radius: 0;
}
.mfp-frame .mfp-close {
  --mfp_close_btn_bgc: var(--dd2-3);
  --mfp_close_btn_color: var(--dd3-3);
  --mfp_close_btn_bdrs: var(--bdrs-4);
  all: unset;
  appearance: auto;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1;
  font-family: var(--ff1-4);
  font-size: 34px;
  transition: 250ms linear;
  background-color: var(--mfp_close_btn_bgc);
  color: var(--mfp_close_btn_color);
}
.mfp-frame .mfp-close > * {
  transition: 250ms linear;
}
.mfp-frame .mfp-close:hover {
  --mfp_close_btn_bgc: var(--dd1);
  --mfp_close_btn_color: var(--dd2-3);
  --mfp_close_btn_bdrs: 8px;
  box-shadow: 0 0 8px -2px rgba(0, 0, 0, 0.3);
}
.mfp-frame > .mfp-close {
  --mfp_close_btn_bgc: var(--dd3-3);
  --mfp_close_btn_color: var(--dd2-3);
  width: var(--mfp_outside_close_btn_width_val);
  height: var(--mfp_outside_close_btn_height_val);
  font-style: italic;
  position: absolute;
  top: 0;
  opacity: 1 !important;
}
.mfp-frame > .mfp-close > * {
  transform: skew(-10deg);
}
.mfp-frame > .mfp-close:hover {
  --mfp_close_btn_bgc: var(--dd3-4);
  box-shadow: inset -5px 0px 8px -3px rgba(0, 0, 0, 0.3);
}
.mfp-frame:not(.close_btn_left):not(.mfp_right) > .mfp-close {
  --mfp_frame_top_left_radius: 0px;
  --mfp_frame_bot_left_radius: 0px;
  right: unset;
  left: calc(100% - 0.5px);
}
.mfp-frame.mfp_right > .mfp-close, .mfp-frame.close_btn_left > .mfp-close {
  --mfp_frame_top_right_radius: 0;
  --mfp_frame_bot_right_radius: 0;
  right: calc(100% - 0.5px);
  left: unset;
}
.mfp-frame .mfp-contain .mfp-header ~ .mfp-main {
  --mfp_frame_top_right_radius: 0;
  --mfp_frame_top_left_radius: 0;
}
.mfp-frame .mfp-header, .mfp-frame .mfp-contain > *:nth-last-of-type(2) {
  --mfp_frame_bot_right_radius: 0;
  --mfp_frame_bot_left_radius: 0;
}
.mfp-frame:not(.close_btn_left):not(.mfp_right) > .mfp-close ~ .mfp-contain .mfp-header {
  --mfp_frame_top_right_radius: 0;
}
.mfp-frame.mfp_right > .mfp-close ~ .mfp-contain .mfp-header, .mfp-frame.close_btn_left > .mfp-close ~ .mfp-contain .mfp-header {
  --mfp_frame_top_left_radius: 0;
}
.mfp-frame > .mfp-close ~ .mfp-contain .mfp-close {
  display: none;
}
.mfp-frame .mfp-header .mfp-close {
  align-self: start;
  width: var(--mfp_inside_close_btn_width_val) !important;
  height: var(--mfp_inside_close_btn_height_val) !important;
  margin-top: var(--mfp_inside_close_btn_mar_top, var(--mfp_inside_close_btn_mar, 9px));
  margin-right: var(--mfp_inside_close_btn_mar_rig, var(--mfp_inside_close_btn_mar, 0));
  margin-bottom: var(--mfp_inside_close_btn_mar_bot, var(--mfp_inside_close_btn_mar, 0));
  margin-left: var(--mfp_inside_close_btn_mar_left, var(--mfp_inside_close_btn_mar, 0));
  border-radius: var(--mfp_inside_close_btn_bdrs);
}
.mfp-frame .mfp-header .mfp-close:hover > * {
  transform: scale(0.8);
}
.mfp-frame .mfp-main {
  box-sizing: content-box;
  overflow: var(--mfp_main_overflow, auto) !important;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.mfp-frame .mfp-header, .mfp-frame .mfp-footer {
  grid-auto-columns: auto;
  grid-gap: 5px clamp(10px, 10px + 10 * (100vw - 662.3px) / 892.7, 20px);
  background-color: var(--dd3-3) !important;
  border: none;
}
.mfp-frame .mfp-header:empty, .mfp-frame .mfp-footer:empty {
  display: none;
}
.mfp-frame .mfp-header:not(:empty), .mfp-frame .mfp-footer:not(:empty) {
  display: grid;
}
.mfp-frame > .mfp-close ~ .mfp-contain .mfp-header .mfp-title, .mfp-frame .mfp-header > *:only-child, .mfp-frame .mfp-header > *:nth-child(n+3), .mfp-frame .mfp-footer > * {
  grid-column: 1/-1;
}
.mfp-frame > .mfp-close ~ .mfp-contain .mfp-header .mfp-title, .mfp-frame .mfp-header > *:only-child, .mfp-frame .mfp-footer {
  text-align: center;
  justify-content: center;
}
.mfp-frame .mfp-header {
  padding-top: var(--mfp_header_inset_top_val);
  padding-bottom: var(--mfp_header_inset_bottom_val);
  min-height: var(--mfp_header_min_height, 0);
}
.mfp-frame .mfp-main {
  padding-top: var(--mfp_main_inset_top_val);
  padding-bottom: var(--mfp_main_inset_bottom_val);
  margin-block: -1px;
}
.mfp-frame .mfp-footer {
  padding-top: var(--mfp_footer_inset_top_val);
  padding-bottom: var(--mfp_footer_inset_bottom_val);
  min-height: var(--mfp_footer_min_height, max-content);
}
.mfp-frame .mfp-title {
  padding: 0;
  color: var(--dd2-3);
  font-family: var(--ff1-6);
  font-size: clamp(25px, 25px + 5 * (100vw - 763.3px) / 921.7, 30px) !important;
  line-height: 1.1 !important;
  text-transform: uppercase;
  display: grid;
  align-content: center;
  grid-template-rows: 40px;
  grid-auto-rows: max-content;
  align-items: baseline;
}
.mfp-frame .mfp-title::before {
  content: "";
  display: block;
  height: 30px;
}
.mfp-frame .mfp-title::before,
.mfp-frame .mfp-title > span {
  grid-column: 1;
  grid-row: 1;
}
.mfp-frame .mfp-title > span {
  align-self: center;
}
.mfp-frame .mfp-subtitle {
  font-family: var(--ff1-5);
  font-size: clamp(16px, 16px + 6 * (100vw - 763.3px) / 921.7, 22px) !important;
  line-height: 1.1;
  font-style: italic;
}
.mfp-frame .mfp-main {
  background-color: var(--mfp_main_bgc, var(--dd1, #fff));
}
.mfp-frame .mfp-actions {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 8px 10px;
}
.mfp-frame .mfp-actions > *:first-child:nth-last-child(3) {
  width: 100%;
}
.mfp-frame .mfp-btn {
  background-color: transparent;
  font-size: 11.5px;
  line-height: 1;
  font-family: var(--ff1-6);
  font-style: italic;
  opacity: 1;
  border-radius: var(--bdrs-4);
  padding: 5.5px 8px 4px;
  text-shadow: 0.5px 0.5px 1px var(--dd3-2);
  letter-spacing: 0.03em;
  text-transform: uppercase;
  transition: 350ms;
  border: 1px solid var(--cc1);
}
.mfp-frame .mfp-btn:not(.btn_close) {
  background-color: var(--cc1);
  color: var(--dd1);
}
.mfp-frame .mfp-btn:not(.btn_close):hover {
  background-color: var(--hh1-2);
  border-color: var(--hh1-2);
}
.mfp-frame .mfp-btn.btn_close {
  text-shadow: 0.5px 0.5px 1px var(--dd3-4);
  border: none;
  text-decoration: underline;
  transition: 250ms, text-decoration-thickness 0.3s;
  text-decoration-thickness: from-font;
  text-decoration-style: double;
}
.mfp-frame .mfp-btn.btn_close:hover {
  text-decoration-color: var(--hh1);
  text-decoration-thickness: 1.5px;
  text-decoration-style: solid;
}
.mfp-bg {
  opacity: 0;
  transition: 250ms;
}
.mfp-bg.mfp-ready {
  opacity: 0.5;
}
.mfp-bg.mfp-removing {
  opacity: 0;
  transition: 250ms 100ms;
}
.mfp-wrap .mfp-frame {
  opacity: 0;
  transition: all 150ms ease-out;
  transform: perspective(100px) translate3D(0, 0, 1px) scale(1.2, 1);
}
.mfp-wrap .mfp-frame.mfp_right, .mfp-wrap .mfp-frame.mfp_left {
  transform-origin: 0 50%;
  transform: perspective(50px) translate3D(5%, 0, -1px) scale3D(1.2, 1, 1);
}
.mfp-wrap.mfp-ready .mfp-frame {
  opacity: 1;
  transform: perspective(100px) translate3D(0, 0, 0) scale(1);
}
.mfp-wrap.mfp-ready .mfp-frame.mfp_right, .mfp-wrap.mfp-ready .mfp-frame.mfp_left {
  transform: perspective(50px) translate3D(0, 0, 0) scale3D(1, 1, 1);
}
.mfp-wrap.mfp-removing .mfp-frame {
  opacity: 0;
  transform: perspective(100px) translate3D(0, 0, -3px) scale(1.15, 1);
  transition: 150ms, opacity 250ms;
}
.mfp-wrap.mfp-removing .mfp-frame.mfp_right, .mfp-wrap.mfp-removing .mfp-frame.mfp_left {
  transition: 250ms, opacity 250ms 50ms;
  transform-origin: -5% 50%;
  transform: perspective(80px) translate3D(15%, 0, -1.5px) scale3D(1.15, 1, 1.2);
}

/*----------------------------*/
.smcart {
  --mfp_frame_width: 545px;
}
.smcart_alert, .smcart_empty {
  --mfp_frame_inset_reverse_ver: calc(var(--mfp_frame_inset_ver) * var(--mfp_reverse_argument, -1));
  --mfp_frame_inset_reverse_hor: calc(var(--mfp_frame_inset_hor) * var(--mfp_reverse_argument, -1));
}
.smcart_btn {
  all: unset;
  cursor: pointer;
  appearance: auto;
  box-sizing: border-box;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  transition: 250ms linear !important;
  text-align: center;
}
.smcart_btn_icn {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 250ms linear;
}
.smcart_btn_icn > * {
  width: 100%;
  height: 100%;
  display: block;
  max-width: 100%;
  object-fit: contain;
}
.smcart_btn_alert {
  align-self: start;
  transform: translate(8px, -11px);
}
.smcart_btn_remove {
  border-radius: var(--bdrs-4);
  background-color: var(--dd3-3);
  width: 30px;
  height: 30px;
}
.smcart_btn_remove:hover {
  color: var(--dd1);
  background-color: var(--hh1-2);
  border-radius: 8px;
  box-shadow: 0 0 8px -2px rgba(0, 0, 0, 0.3);
}
.smcart_btn_remove .smcart_btn_icn {
  width: 20px;
  height: 20px;
}
.smcart_btn_remove:hover .smcart_btn_icn {
  transform: scale(0.8);
}
.smcart_btn_quantity {
  font-size: 14px;
  padding: 3px;
  align-self: center;
  justify-self: center;
  border-radius: var(--bdrs-4) !important;
}
.smcart_btn_quantity_decrease {
  border-radius: var(--bdrs-4) 0 0 var(--bdrs-4) !important;
  order: -2;
}
.smcart_btn_quantity_decrease[data-quantity-value="1"] {
  pointer-events: none;
}
.smcart_btn_quantity_increase {
  border-radius: 0 var(--bdrs-4) var(--bdrs-4) 0 !important;
}
.smcart_btn_quantity_increase:hover {
  box-shadow: 0 0 0 1px var(--hh1-2) !important;
}
.smcart_btn_quantity_increase:hover + input {
  border-radius: var(--bdrs-8) 0 0 var(--bdrs-8) !important;
  box-shadow: 0 0 0 1px var(--hh1-2), 0 0 3px -1px var(--hh1-2) !important;
}
.smcart_btn_quantity_increase:hover, .smcart_btn_quantity_decrease:not([data-quantity-value="1"]):hover {
  color: var(--dd1);
  background-color: var(--hh1-2);
}
.smcart_btn_quantity_decrease:not([data-quantity-value="1"]):hover {
  box-shadow: 0 0 0 1px var(--hh1-2) !important;
}
.smcart_btn_quantity_decrease:not([data-quantity-value="1"]):hover ~ input {
  border-radius: 0 var(--bdrs-8) var(--bdrs-8) 0 !important;
  box-shadow: 0 0 0 1px var(--hh1-2), 0 0 3px -1px var(--hh1-2) !important;
}
.smcart_btn_quantity_decrease[data-quantity-value="1"]:hover {
  background-color: var(--dd3-4);
}
.smcart_btn_order {
  border-radius: var(--bdrs-4);
  background-color: var(--cc1);
  border: 1px solid var(--cc1);
  font-family: var(--ff1-5);
  line-height: 1;
  padding: 5.5px 16px 4px;
  text-transform: uppercase;
  transition: all 500ms ease 0s;
  min-height: 34px;
}
@media screen and (min-width: 280px) and (max-width: 661.7px) {
  .smcart_btn_order {
    font-size: 12px;
  }
}
@media screen and (min-width: 662px) {
  .smcart_btn_order {
    font-size: 16px;
  }
}
.smcart_btn_order:hover {
  background-color: var(--hh1-2);
  border-color: var(--hh1-2);
  color: var(--dd1);
}
.smcart_alert {
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--mfp_frame_inset_ver) var(--mfp_frame_inset_hor);
  transform: translateY(var(--mfp_frame_inset_reverse_ver));
  margin: 0 var(--mfp_frame_inset_reverse_hor) var(--mfp_frame_inset_reverse_ver) !important;
  border-radius: var(--mfp_frame_radius_size);
  background-color: rgba(169, 68, 66, 0.3);
  backdrop-filter: blur(2px);
  border: 1px solid #A94442;
  box-shadow: none;
}
.smcart_alert:empty {
  display: none;
}
.smcart_alert_contain {
  border-radius: var(--bdrs-4) !important;
  margin: 0 !important;
  width: 100%;
  display: grid !important;
  grid-template-columns: minmax(0px, 1fr) max-content;
  grid-auto-rows: max-content;
  grid-gap: 10px;
  align-content: start;
  align-items: center;
}
.smcart_empty {
  flex-grow: 1;
  margin: var(--mfp_frame_inset_reverse_ver) var(--mfp_frame_inset_reverse_hor) !important;
  padding: var(--mfp_frame_inset_ver) var(--mfp_frame_inset_hor) !important;
  width: auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
.smcart_empty_text {
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-style: italic;
  font-size: 20px;
  text-indent: 5px;
  padding-bottom: 7.25vmin;
  mix-blend-mode: multiply;
}
.smcart_empty::before {
  content: "";
  position: absolute;
  inset: 0;
  opacity: 0.15;
  z-index: -1;
  background-blend-mode: multiply;
  background-color: var(--dd1);
  background-image: url("../image/decor/basket_empty_icon.webp");
  background-position: 96% 94%;
  background-repeat: no-repeat;
  background-size: 16% auto;
  pointer-events: none;
}
.smcart_data {
  display: flex;
  flex-direction: column;
  gap: calc(var(--mfp_frame_offset_y) * 2);
}
.smcart_data_contain, .smcart_data_table, .smcart_data_tbody {
  display: contents;
}
.smcart_data_thead {
  display: none;
}
.smcart_data_tbody > .smcart_data_trow + .smcart_data_trow {
  position: relative;
}
.smcart_data_tbody > .smcart_data_trow + .smcart_data_trow::before {
  content: "";
  width: calc(100% + var(--mfp_frame_offset_x) * 2);
  height: 1px;
  background-color: rgba(234, 237, 247, 0.45);
  position: absolute;
  bottom: calc(100% + var(--mfp_frame_offset_y) - 0.5px);
  left: 50%;
  transform: translateX(-50%);
}
.smcart_item + .smcart_item {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid var(--dd3-3);
}
.smcart_item {
  display: grid !important;
  grid-template-rows: 21px minmax(21px, max-content) repeat(2, auto);
}
@media screen and (min-width: 280px) and (max-width: 661.7px) {
  .smcart_item {
    grid-gap: 0 4px;
    grid-template-columns: 60px 1fr 1fr;
    grid-auto-columns: max-content;
    grid-template-areas: "preview name name" "preview name name" "model model model" "remove quantity total";
  }
}
@media screen and (min-width: 662px) {
  .smcart_item {
    grid-template-columns: fit-content(116px) auto 1fr 30px;
    justify-content: end;
    grid-gap: 0 min(20px, max(7px, 1.389vw));
  }
}
@media screen and (min-width: 280px) and (max-width: 661.7px) {
  .smcart_item.model_show {
    grid-template-columns: 80px 1fr 1fr 30px;
    grid-template-areas: "preview model model model" "preview name name name" "preview total total total" " preview quantity quantity remove";
  }
}
@media screen and (min-width: 662px) {
  .smcart_item.model_show {
    grid-template-areas: "preview model model remove" "preview name name remove" "preview name name remove" "preview quantity total total";
  }
}
.smcart_item.options_show {
  grid-template-areas: "preview name name remove" "preview name name remove" "preview options options options" "preview quantity total total";
}
.smcart_item.model_show.options_show {
  grid-template-areas: "preview model model remove" "preview name name remove" "preview options options options" "preview quantity total total";
}
.smcart_item > * {
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
}
.smcart_item_area_remove {
  grid-area: remove;
}
.smcart_item_area_preview {
  grid-area: preview;
}
.smcart_item_area_model {
  grid-area: model;
}
.smcart_item_area_name {
  grid-area: name;
}
.smcart_item_area_options {
  padding-top: 10px;
  grid-area: options;
}
.smcart_item_area_options > div {
  font-family: var(--ff2-4);
  font-size: 12px;
  display: flex;
  gap: 3px;
  align-items: baseline;
}
.smcart_item_area_options > div > *:nth-child(1) {
  color: var(--dd3-1);
  text-transform: unset !important;
}
.smcart_item_area_options > div > *:nth-child(2) {
  color: var(--dd2-3);
}
.smcart_item_area_quantity {
  grid-area: quantity;
  margin-top: 10px;
}
@media screen and (min-width: 280px) and (max-width: 661.7px) {
  .smcart_item_area_quantity {
    border-radius: 4px;
    justify-self: end;
  }
}
@media screen and (min-width: 662px) {
  .smcart_item_area_quantity {
    align-self: end;
  }
}
.smcart_item_area_total {
  grid-area: total;
  align-self: end;
  justify-self: end;
  padding-bottom: 9px;
}
.smcart_item_error {
  color: var(--aa1);
}
.smcart_item_error.error_stock {
  font-size: 14px;
  font-family: var(--ff1-7);
}
.smcart_item_btn {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  max-width: 100%;
  border-radius: var(--bdrs-4);
  transition: 250ms linear;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dd3-3);
}
.smcart_item_btn:hover {
  color: var(--dd1);
  background-color: var(--hh1-2);
  border-radius: 8px;
  box-shadow: 0 0 8px -2px rgba(0, 0, 0, 0.3);
}
.smcart_item_preview {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms linear;
  border-radius: var(--bdrs-4);
}
.smcart_item_preview:hover {
  box-shadow: 0 0 0 1px var(--dd3-3);
}
.smcart_item_preview img {
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: var(--bdrs-4);
}
.smcart_item_model {
  font-size: 13px;
  font-family: var(--ff1-3);
  display: flex;
  color: #777;
  align-items: baseline;
  gap: 3px;
}
.smcart_item_name {
  display: flex;
  align-items: baseline;
  gap: 4px;
}
.smcart_item_name_text {
  color: var(--dd2-3);
  font-family: var(--ff1-4);
  line-height: 1.2;
  display: inline-block;
}
@media screen and (min-width: 280px) and (max-width: 661.7px) {
  .smcart_item_name_text {
    font-size: 12px;
  }
}
@media screen and (min-width: 662px) {
  .smcart_item_name_text {
    font-size: 16px;
  }
}
.smcart_item_quantity {
  width: fit-content;
}
.smcart_item_quantity_contain {
  --smcart_item_quantity_size: min(40px, max(30px, 5.05vw));
  position: relative;
  display: grid;
  grid-template-columns: var(--smcart_item_quantity_size) 1fr var(--smcart_item_quantity_size);
  grid-template-rows: var(--smcart_item_quantity_size);
  background-color: var(--dd3-3);
  border-radius: var(--bdrs-8);
  transition: 250ms linear !important;
}
.smcart_item_quantity_inp {
  all: unset;
  appearance: auto;
  box-sizing: border-box;
  font-family: var(--ff1-5);
  text-align: center;
  cursor: text;
  padding: 3px;
  transition: all 250ms 0s !important;
  order: -1;
  border-radius: var(--bdrs-8);
}
.smcart_item_quantity_inp:hover:not(:focus) {
  cursor: pointer;
  border-radius: var(--bdrs-8);
  background-color: var(--dd3-4);
  box-shadow: inset 0 0 0 1px var(--dd3);
}
.smcart_item_quantity_inp:focus {
  box-shadow: inset 0 0 0 1px var(--dd3-2), 0 0 1px -1px var(--dd3-2);
  border-radius: var(--bdrs-8);
}
.smcart_item_quantity_inp:focus:hover {
  box-shadow: 0 0 0 1px var(--hh1-2);
}
.smcart_item_quantity_btn {
  height: var(--smcart_item_quantity_size);
  width: var(--smcart_item_quantity_size);
}
.smcart_item_total {
  display: flex;
  align-items: baseline;
  gap: 4px;
}
.smcart_item_total_text {
  font-size: clamp(16px, 16px + 5 * (100vw - 662.3px) / 892.7, 21px);
  font-family: var(--ff1-7);
  font-style: italic;
  white-space: nowrap;
  color: var(--dd2);
}
.smcart_total {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: var(--mfp_frame_inset_top_val);
  margin-bottom: var(--mfp_frame_inset_right_val);
}
.smcart_total_contain {
  display: grid;
  grid-gap: 10px;
  align-items: baseline;
  justify-items: end;
  grid-template-columns: 1fr max-content;
}
.smcart_total_contain > *:nth-child(n+3) {
  grid-column: 1/-1;
}
.smcart_total_text {
  font-size: 15px;
  font-family: var(--ff1-3);
  line-height: 1.2;
  color: var(--dd2-1);
}
.smcart_total_order {
  font-size: var(--size-fluid-5);
  font-family: var(--ff1-7);
  font-style: italic;
  text-transform: uppercase;
  color: var(--dd2);
}

/*----------------------------*/
.bannercarousel {
  width: min(720px, 100%);
  margin-left: auto;
  margin-right: auto;
}
.bannercarousel .swiper-slide {
  background-color: var(--dd3-5);
  border-radius: var(--bdrs-4);
  padding: 15px;
}
.bannercarousel .swiper-slide a {
  display: flex;
  border-radius: var(--bdrs-4);
  aspect-ratio: 188/265;
  box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.3);
}
.bannercarousel .swiper-slide img {
  border-radius: var(--bdrs-4);
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0 !important;
}

.main-slider {
  display: grid;
  grid-template-columns: 100%;
  justify-items: end;
}
@media screen and (min-width: 990.4px) {
  .main-slider {
    border-radius: var(--MS_bdrs, var(--bdrs-8));
  }
}
.main-slider #spinner {
  pointer-events: none;
  background-color: rgb(var(--rr1)/30%);
  backdrop-filter: blur(1px);
  position: static;
}
.main-slider #spinner,
.main-slider .swiper-viewport {
  grid-column: 1;
  grid-row: 1;
  min-height: 0;
  height: auto;
}
.main-slider #spinner,
.main-slider .swiper-viewport,
.main-slider .swiper-container,
.main-slider .swiper-slide,
.main-slider .swiper-slide a, .main-slider div, .main-slider span, .main-slider img {
  border-radius: inherit;
}

@media screen and (min-width: 990px) {
  .template_container.homw .main-slider {
    display: none !important;
  }
}

.advantages {
  --advantages_columns: 4;
  --advantages_row_gap: clamp(10px, 10px + (20 - 10) * (100vw - 1260.3px) / (1645 - 1260.3), 20px);
  --advantages_col_gap: clamp(10px, 10px + (35 - 10) * (100vw - 1260.3px) / (1645 - 1260.3), 35px);
  --advantages_col_size: 1fr;
  --advantages_col_decor_size: 1.5px;
  --advantages_col_decor_height: 75%;
  --advantages_col_decor_fill: var(--dd3-3);
  --advantages_icn_size: min(50px, max(38px, 5vmin));
  --advantages_icn_scale: 77%;
  --advantages_icn_bgc: url("../image/codezeel/service-icon.png");
  --advantages_icn_bgc_pos_x: center;
  --advantages_icn_width_var: var(--advantages_icn_width, var(--advantages_icn_size));
  --advantages_icn_height_var: var(--advantages_icn_height, var(--advantages_icn_size));
  --advantages_heading_color: var(--dd2);
  --advantages_heading_ff: var(--ff1-5);
  --advantages_heading_fz: 16px;
  --advantages_heading_ls: -.025rem;
}
@media screen and (min-width: 280px) and (max-width: 350px) {
  .advantages {
    --advantages_template_cols: 100%;
  }
}
@media screen and (min-width: 350.3px) and (max-width: 563px) {
  .advantages {
    --advantages_template_cols: 1fr 1fr;
  }
}
@media screen and (min-width: 563.3px) and (max-width: 1107px) {
  .advantages {
    --advantages_columns: 2;
  }
}
.advantages .container {
  all: unset;
}
.advantages__overlay {
  padding-top: clamp(10px, 10px + 20 * (100vw - 1260.3px) / 384.7, 30px) !important;
  padding-bottom: 0 !important;
}
.advantages__template {
  --calc_advantages_columns: calc(var(--advantages_columns, 1) - 1);
  --calc_advantages_col_size: repeat(var(--calc_advantages_columns, 1), minmax(0, var(--advantages_col_size, 1fr)) var(--advantages_col_decor_size, 1fr)) var(--advantages_col_size, 1fr);
  --calc_advantages_row_size: minmax(var(--advantages_icn_height_var, auto), max-content);
  display: grid;
  grid-template-columns: var(--advantages_template_cols, var(--calc_advantages_col_size));
  grid-auto-rows: var(--advantages_template_rows, var(--calc_advantages_row_size));
  grid-gap: var(--advantages_row_gap, 0) var(--advantages_col_gap, 0);
  justify-items: center;
  justify-content: center;
}
.advantages__item {
  width: 100%;
  display: contents;
}
.advantages__item::after {
  justify-self: center;
  align-self: center;
  content: "";
  display: block;
  width: var(--advantages_col_decor_width, var(--advantages_col_decor_size, auto));
  height: var(--advantages_col_decor_height, var(--advantages_col_decor_size, auto));
  background-color: var(--advantages_col_decor_fill, currentColor);
}
@media screen and (min-width: 280.3px) and (max-width: 563px) {
  .advantages__item:nth-child(1n)::after {
    display: none;
  }
}
@media screen and (min-width: 563.3px) and (max-width: 1107px) {
  .advantages__item:nth-child(2n)::after {
    display: none;
  }
}
.advantages__item:nth-child(4n)::after {
  display: none;
}
.advantages__item_overlay {
  width: 100%;
}
.advantages__item_contain {
  width: 100%;
  height: 100%;
  background-color: var(--dd1);
  display: grid;
  grid-gap: 3px clamp(5px, 5px + 5 * (100vw - 731.3px) / 913.7, 10px);
  grid-template-columns: var(--advantages_icn_width_var, max-content) 1fr;
  grid-template-rows: repeat(2, max-content);
  border-radius: var(--bdrs-4);
  justify-items: var(--advantages_contain_justify);
  text-align: var(--advantages_contain_text_align, left);
  padding: 8px clamp(8px, 8px + 14 * (100vw - 731.3px) / 913.7, 22px) 8px clamp(6px, 6px + 4 * (100vw - 731.3px) / 913.7, 10px);
  align-items: center;
  box-shadow: 0 0 0 1px var(--dd3-3);
  transition: 500ms;
}
.advantages__item_contain:hover {
  border-radius: var(--bdrs-12);
  background-color: rgb(var(--rr1)/30%);
  box-shadow: 3px 3px 10px rgb(var(--rd2)/7%), 5px 5px -10px rgb(var(--rd2)/7%);
}
.advantages__item_contain > *:nth-child(n+4) {
  grid-column: 1/-1;
}
.advantages__item_contain.contain_shipping {
  --advantages_icn_bgc_pos_y: 12px;
}
.advantages__item_contain.contain_shipping:hover {
  --advantages_icn_bgc_pos_y: -64px;
}
.advantages__item_contain.contain_returns {
  --advantages_icn_bgc_pos_y: -140px;
}
.advantages__item_contain.contain_returns:hover {
  --advantages_icn_bgc_pos_y: -216px;
}
.advantages__item_contain.contain_guarantee {
  --advantages_icn_bgc_pos_y: -294px;
}
.advantages__item_contain.contain_guarantee:hover {
  --advantages_icn_bgc_pos_y: -370px;
}
.advantages__item_contain.contain_supports {
  --advantages_icn_bgc_pos_y: -443px;
}
.advantages__item_contain.contain_supports:hover {
  --advantages_icn_bgc_pos_y: -519px;
}
.advantages__item_icn {
  grid-column: 1;
}
@media screen and (min-width: 280.3px) and (max-width: 563px) {
  .advantages__item_icn {
    grid-row: 2;
  }
}
@media screen and (min-width: 563px) {
  .advantages__item_icn {
    grid-row: 1/-1;
  }
}
@media screen and (min-width: 280.3px) and (max-width: 563px) {
  .advantages__item:not(:hover) .advantages__item_heading {
    padding-left: clamp(3px, 3px + 7 * (100vw - 360.3px) / 202.7, 10px);
  }
}
.advantages__item_heading {
  grid-row: 1;
}
@media screen and (min-width: 280.3px) and (max-width: 563px) {
  .advantages__item_heading {
    grid-column: 1/-1;
    transition: 250ms;
  }
}
@media screen and (min-width: 563px) {
  .advantages__item_heading {
    grid-column: 2;
    align-self: end;
    padding-top: 2px;
  }
}
.advantages__item_text {
  grid-row: 2;
  grid-column: 2;
}
@media screen and (min-width: 280.3px) and (max-width: 563px) {
  .advantages__item_text {
    align-self: center;
  }
}
@media screen and (min-width: 563px) {
  .advantages__item_text {
    align-self: start;
  }
}
.advantages__item_icn {
  height: var(--advantages_icn_width_var, auto);
  width: var(--advantages_icn_height_var, auto);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 500ms;
  filter: drop-shadow(0.5px 0.5px 0 var(--dd2));
  border-radius: var(--bdrs-12);
}
.advantages__item_icn::before {
  content: "";
  width: 50px;
  height: 50px;
  transform: scale(var(--advantages_icn_scale));
  transition: 500ms;
  background-image: var(--advantages_icn_bgc, none);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-color: transparent;
  background-position: var(--advantages_icn_bgc_pos_x, 50%) var(--advantages_icn_bgc_pos_y, 50%);
}
.advantages__item_contain:hover .advantages__item_icn {
  transition: 500ms, background-color 500ms 0ms;
  border-radius: var(--bdrs-4);
  background-color: var(--dd1);
  filter: drop-shadow(1px 1px 2px rgba(var(--rd2)/25%));
}
.advantages__item_contain:hover .advantages__item_icn::before {
  transition: 500ms, background-color 500ms 0ms;
}
.advantages__item_heading {
  color: var(--advantages_heading_color, currentColor);
  font-size: 1.55rem;
  font-family: var(--advantages_heading_ff, inherit);
  line-height: var(--advantages_heading_lh, 1.2);
  letter-spacing: var(--advantages_heading_ls, initial);
}
.advantages__item_text {
  font-size: var(--size-4);
}

.wall_categories {
  --cw_tiitle_minh: 40px;
  overflow: unset !important;
}
.wall_categories__container {
  padding: 0;
}
.wall_categories__head {
  --title_mar: 0 0 var(--minmax_15_25);
}
.wall_categories__content {
  --cw_row_gap: var(--cw_col_gap);
  --cw_cols_width: calc(100% - var(--cw_row_gap, 0px) * (var(--categories_wall_cols, 1) - 1));
  display: grid;
  grid-template-columns: repeat(var(--categories_wall_cols, 1), calc(var(--cw_cols_width, 100%) / var(--categories_wall_cols, 1)));
  grid-gap: var(--cw_col_gap, 0) var(--cw_row_gap, 0);
  margin: 0 !important;
  width: 100%;
}
@media screen and (min-width: 280px) and (max-width: 567.7px) {
  .wall_categories__content {
    --categories_wall_cols: 1;
    --cw_fz: clamp(13px, 13px + (17 - 13) * (100vw - 320.3px) / (568 - 320.3), 17px);
    --cw_col_gap: 10px;
  }
}
@media screen and (min-width: 568px) and (max-width: 911.7px) {
  .wall_categories__content {
    --categories_wall_cols: 2;
    --cw_fz: clamp(13px, 13px + (17 - 13) * (100vw - 320.3px) / (912 - 320.3), 17px);
    --cw_col_gap: 17.5px;
  }
}
@media screen and (min-width: 912px) and (max-width: 1259.7px) {
  .wall_categories__content {
    --categories_wall_cols: 3;
    --cw_fz: 15px;
    --cw_col_gap: 21.5px;
  }
}
@media screen and (min-width: 1260px) {
  .wall_categories__content {
    --cw_fz: 19px;
    --categories_wall_cols: 3;
    --cw_col_gap: var(--minmax_8_15);
  }
}
.wall_categories__content_view {
  display: contents;
}
.wall_categories__item {
  width: fit-content !important;
  background-color: var(--dd1);
  box-shadow: 0 0 0 1px var(--dd3-3);
  padding: 10px;
  border-radius: var(--bdrs-8);
  transition: 500ms;
}
.wall_categories__item:hover {
  border-radius: var(--bdrs-12);
}
.wall_categories__item_preview {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 10px;
  border-radius: var(--bdrs-8);
}
.wall_categories__item_figure {
  width: auto;
  margin-inline: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--bdrs-8);
}
.wall_categories__item_img {
  border-radius: var(--bdrs-4);
  display: block;
  max-width: 100%;
  width: 100%;
  height: 100%;
  background-size: contain;
  border: none;
  transition: 300ms ease-in 200ms;
  mix-blend-mode: multiply;
}
.wall_categories__item:hover .wall_categories__item_img {
  transition: 350ms ease-in-out;
  transform: scale(0.93);
}
.wall_categories__item_title {
  min-height: var(--cw_tiitle_minh);
  background-color: var(--dd3-3);
  border: 1px solid var(--dd3-3);
  display: flex;
  align-items: center;
  border-radius: var(--bdrs-4);
  font-family: var(--ff1-5);
  font-size: var(--cw_fz);
  color: var(--dd2);
  line-height: 1;
  letter-spacing: 0.01em;
  gap: 2.5px;
  justify-content: space-between;
  padding: 5px 8px 5px 8px;
  transition: 300ms linear;
}
.wall_categories__item_title:hover {
  transition: 300ms;
  background-color: var(--hh1-2);
  border-color: var(--hh1-2) !important;
  color: var(--dd1);
}
.wall_categories__item_title svg, .wall_categories__item_title svg * {
  transition: 300ms linear;
}
.wall_categories__item_title > span {
  margin-left: auto;
  margin-right: auto;
  --trim: calc(var(--cw_tiitle_minh) - 8px - 5px);
}
.wall_categories__item_title > svg {
  width: calc(var(--cw_tiitle_minh) * 0.5);
  height: auto;
}
.wall_categories__item:hover .wall_categories__item_title {
  border-radius: var(--bdrs-8);
  border-color: var(--hh1);
}
.wall_categories__item:hover .wall_categories__item_title svg, .wall_categories__item:hover .wall_categories__item_title svg * {
  fill: var(--hh1) !important;
}

/*----------------------------*/
.contact_overlay {
  --contain_size: 1200px;
  --contain_inset: 16px;
  --contain_left_col: 500;
  --contain_gap: calc(var(--contain_inset) * 2);
  --map_hover_offset: calc(var(--contain_inset) * -2);
  --contain_inset_top_var: var(--contain_inset_top, var(--contain_innset_ver, var(--contain_inset)));
  --contain_inset_right_var: var(--contain_inset_right, var(--contain_innset_hor, var(--contain_inset)));
  --contain_inset_bottom_var: var(--contain_inset_bottom, var(--contain_innset_ver, var(--contain_inset)));
  --contain_inset_left_var: var(--contain_inset_left, var(--contain_innset_hor, var(--contain_inset)));
  --contain_inset_var: var(--contain_inset_top_var, 0) var(--contain_inset_right_var, 0) var(--contain_inset_bottom_var, 0) var(--contain_inset_left_var, 0);
  --contain_row_gap_var: var(--contain_row_gap, var(--contain_gap, 0));
  --contain_col_gap_var: var(--contain_cow_gap, var(--contain_gap, 0));
  --contain_cols: 2;
  --contain_cols_size: calc(100% - var(--contain_col_gap_var, 0px) * (var(--contain_cols, 1) - 1));
  --contain_left_size: calc(var(--contain_cols_size) * .48);
  --contain_right_size: calc(var(--contain_cols_size) * .52);
  --map_hover_offset_top_var: var(--map_hover_offset_top, var(--map_hover_offset));
  --map_hover_offset_right_var: var(--map_hover_offset_right, var(--map_hover_offset));
  --map_hover_offset_bottom_var: var(--map_hover_offset_bottom, var(--map_hover_offset));
  --map_hover_offset_left_var: var(--map_hover_offset_left, var(--map_hover_offset));
  display: grid;
  grid-template-columns: 100%;
  grid-gap: var(--contain_row_gap_var, 0) var(--contain_col_gap_var, 0);
  background-color: var(--dd3-3);
  border-radius: var(--bdrs-12);
  grid-auto-rows: max-content;
  padding: var(--contain_inset_var);
}
@media screen and (min-width: 990.4px) {
  .contact_overlay {
    grid-template-columns: var(--contain_left_size, 1fr) var(--contain_right_size, 1fr);
  }
}
.contact_map {
  order: 2;
  align-self: stretch;
  position: relative;
  padding: var(--contain_inset_ver);
  border-radius: var(--bdrs-12);
}
@media screen and (min-width: 280px) and (max-width: 990.4px) {
  .contact_map {
    aspect-ratio: 16/9;
    border-radius: var(--bdrs-4);
  }
}
.contact_map_block {
  height: unset !important;
  width: unset !important;
  position: absolute;
  inset: 0 0 0 0;
  left: 0;
  transform: translateX(0);
  background-color: var(--dd1);
  border-radius: var(--bdrs-12);
  will-change: inset, transform, border-radius;
}
@media screen and (min-width: 990px) {
  .contact_map_block {
    box-shadow: 0 0 8px 0.5px rgba(0, 0, 0, 0.2);
  }
}
.contact_map_block > * {
  width: 100% !important;
  height: 100% !important;
  border-radius: inherit;
}
.contact_map_block img {
  border-radius: var(--bdrs-12);
}
.contact_map_block.map {
  background-color: #fff;
  will-change: left, box-shadow;
  transition: 170ms ease;
}
@media (hover: hover) and (pointer: fine) {
  .contact_map:hover .contact_map_block {
    transition: all 350ms ease, left 300ms 325ms var(--trs12);
    inset: var(--map_hover_offset) calc(var(--map_hover_offset) * 2) var(--map_hover_offset) 0;
    transform: translateX(var(--map_hover_offset));
  }
}
@media (hover: hover) and (pointer: fine) {
  .contact_map:hover .contact_map_block.map {
    left: -100%;
  }
}
@media (hover: hover) and (pointer: fine) {
  .contact_map:hover ~ .contact_contain .contact_title_form {
    transition: 150ms 250ms, opacity 500ms;
    opacity: 0;
    pointer-events: none;
    filter: grayscale(50%);
    position: relative;
  }
  .contact_map:hover ~ .contact_contain .contact_store_logo {
    background-color: #eceef6;
    box-shadow: 0 0 0 1px #eceef6, 1px 2px 4px -1px rgba(0, 0, 0, 0.36);
    transform: translateY(-4px);
  }
}
@media (hover: hover) and (pointer: fine) {
  .contact_map:hover ~ .contact_contain .contact .contact_title_form, .contact_map:hover ~ .contact_contain .contact_form {
    will-change: opacity;
    transition: 150ms 250ms, opacity 250ms;
    opacity: 0;
    pointer-events: none;
    position: relative;
  }
  .contact_map:hover ~ .contact_contain .contact_header {
    pointer-events: none;
    transform-origin: 0 50%;
    transition: all 350ms ease 250ms, transform 400ms 325ms var(--trs12);
    --contain_inset_reverse-x: calc(var(--contain_inset) * -1.1);
    --contain_inset_reverse-y: calc(var(--contain_inset) * -.3);
    transform: translate(var(--contain_inset_reverse-x), var(--contain_inset_reverse-y));
  }
  .contact_map:hover ~ .contact_contain .contact_header a {
    pointer-events: auto;
  }
  .contact_map:hover ~ .contact_contain .contact_header * {
    filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
    color: var(--dd2);
    transition: 300ms 275ms var(--trs1);
  }
  .contact_map:hover ~ .contact_contain .contact_header::before {
    transition: all 450ms 300ms var(--trs12);
    border-radius: calc(var(--bdrs-12) / 2);
    background-color: rgba(235, 237, 246, 0.85);
    box-shadow: inset -65px -32px 0 61px rgba(179, 154, 233, 0.55), 0 0 0 2.7px #eceef6, 0 0 0 4px #d8d3ef;
  }
}
.contact_header {
  --title: 16px;
  display: grid;
  grid-gap: 6px 16px;
  margin-bottom: 30px;
  will-change: filter, transform, backdrop-filter, transform-origin, background-color, inset, left, opacity;
  grid-column: 1/-1;
  transition: all 250ms ease 300ms, transform 325ms 275ms var(--trs12);
  transform: translateZ(0px);
  position: relative;
}
.contact_header > * {
  margin: 0 !important;
}
.contact_header > *:empty {
  display: none;
}
@media screen and (min-width: 280px) and (max-width: 414.4px) {
  .contact_header:not(.added_store_image) {
    grid-template-columns: 100%;
    grid-template-areas: "store" "address" "phones" "time";
  }
}
@media screen and (min-width: 414px) {
  .contact_header:not(.added_store_image) {
    grid-template-columns: fit-content(50px) 0.78fr 1fr;
    grid-template-areas: "store store store" "address address address" "phones time time";
  }
}
.contact_header.added_store_image {
  grid-template-columns: fit-content(50px) 1.1fr repeat(2, 1fr);
}
@media screen and (min-width: 280px) and (max-width: 414.4px) {
  .contact_header.added_store_image {
    grid-auto-rows: minmax(50px, auto) auto;
    grid-template-areas: "image store store store store" "address address address address address " "phones phones phones phones phones" "time time time time time";
  }
}
@media screen and (min-width: 414px) {
  .contact_header.added_store_image {
    grid-template-areas: "image store store store store" "image address address address address " "phones phones time time time";
  }
}
.contact_header.added_store_image .contact_store {
  grid-column: 2/-1;
  align-self: center;
}
.contact_header::before,
.contact_header > * {
  will-change: filter, transform, backdrop-filter, transform-origin, background-color, inset, left, opacity;
}
.contact_header::before {
  content: "";
  align-self: stretch;
  width: calc(100% + var(--contain_gap, 0));
  height: calc(100% + var(--contain_gap, 0));
  position: absolute;
  inset: calc(var(--map_hover_offset) / 2) !important;
  border-radius: var(--bdrs-12);
  box-shadow: inset 0 0 60px 0 rgba(235, 237, 246, 0), 0 0 0 0 rgba(235, 237, 246, 0), 0 0 0 0 rgba(235, 237, 246, 0);
  transition: all 300ms 275ms;
  pointer-events: none;
  opacity: 1;
}
.contact_block {
  display: grid;
  grid-template-columns: 100%;
  align-content: start;
  grid-gap: 5px;
}
.contact_block_address {
  display: contents;
}
.contact_block_address .contact_block_list {
  grid-area: address;
}
.contact_block_address .contact_block_list > * {
  margin-bottom: -2px;
}
.contact_block_phones {
  padding-top: 5px;
  grid-area: phones;
}
.contact_block_phones .contact_title, .contact_block_time .contact_title {
  line-height: 1;
}
.contact_block_phones .contact_block_list {
  white-space: nowrap;
  font-variant-numeric: tabular-nums;
  font-variant: oldstyle-nums;
  text-transform: lowercase;
}
.contact_block_phones, .contact_block_time {
  grid-gap: 10px;
  padding-top: 10px;
}
.contact_block_time {
  grid-area: time;
}
.contact_block_time .contact_block_list li ins {
  white-space: pre-line;
  display: block;
  margin-top: -1px;
}
.contact_block_list {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: minmax(16px, max-content);
  align-items: start;
  grid-gap: 5px;
  font-family: var(--ff1-3);
  margin: 0;
  padding: 0;
  font-size: 15px;
  list-style-type: none;
}
.contact_block_list * {
  font-weight: inherit;
  font-size: inherit;
  line-height: 1.1;
  text-decoration: none;
  color: currentColor;
  font-family: inherit;
}
.contact_title {
  font-size: var(--title);
  font-family: var(--ff1-5);
  color: var(--cc1);
}
.contact_title_store {
  color: var(--dd2);
  font-size: var(--size-fluid-5);
  font-family: var(--ff1-7);
  line-height: 1;
}
.contact_title_form {
  font-size: 18px;
  font-family: var(--ff1-7);
}
.contact_store {
  grid-area: store;
  display: block;
  grid-column: 1/1;
}
.contact_store:not(.and_image) {
  grid-column: 1/-1;
}
.contact_store_logo {
  grid-area: image;
  border-radius: var(--bdrs-4);
  box-shadow: 1px 2px 4px -1px rgba(0, 0, 0, 0.36), 0 0 0 1px #d8d3ef;
  background-color: #fff;
  display: flex;
  place-items: center center;
}
.contact_store_logo * {
  border-radius: inherit;
}
.contact_store_img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  mix-blend-mode: multiply;
  border: none;
}
.contact_store_txt {
  align-self: baseline;
  line-height: 1.1;
}
.contact_store_txt:only-child {
  grid-column: 1/-1;
}
.contact .contact_store_figure {
  align-self: center;
}
.contact samp {
  display: grid;
  min-height: 20px;
  height: fit-content;
  color: var(--cc3);
}
.contact samp kbd {
  color: currentColor;
  font-size: clamp(18px, 18px + 10 * (100vw - 662.3px) / 892.7, 28px);
  line-height: 1;
  font-family: var(--ff1-6);
  background-color: transparent;
  box-shadow: none;
  display: block;
  padding: 0;
  margin-block: -3px;
}
.contact fieldset {
  display: block !important;
  float: none;
  margin-inline: 2px !important;
  padding-inline: 0.75em !important;
  padding-block: 0.35em 0.625em !important;
  min-inline-size: min-content !important;
  border-width: 2px !important;
  border-style: groove !important;
  border-color: rgb(192, 192, 192) !important;
  border-image: initial !important;
}
.contact legend {
  display: block !important;
  text-align: unset !important;
  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;
  border-width: initial !important;
  border-style: none !important;
  border-color: initial !important;
  border-image: initial !important;
}
.contact_form {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 18px;
  margin-top: 5px;
}
.contact_form fieldset {
  padding: 0 10px 10px !important;
  border-radius: calc(var(--bdrs-4) + 1px);
  border: none !important;
}
.contact_form fieldset:focus-within {
  filter: drop-shadow(1px 3px 6px rgba(216, 211, 239, 0.7));
}
.contact_form fieldset:focus-within > legend .legend_ease {
  flex-grow: 0.5;
}
.contact_form fieldset:focus-within > legend .legend_ease:last-child {
  flex-grow: 0.5;
}
.contact_form fieldset:focus-within > legend .legend_text {
  color: var(--dd2-2);
  font-style: normal;
  transform: translateY(-2px);
}
.contact_form fieldset:focus-within > legend .legend_text::before {
  display: block;
  background-color: var(--cc1);
  opacity: 1;
}
.contact_form fieldset .form_group:has(.text-danger) + legend .legend_text::before {
  background-color: var(--cc2);
  opacity: 0.8;
}
.contact_form fieldset:focus-within .form_group:has(.text-danger) + legend .legend_text::before {
  opacity: 1;
}
.contact_form legend {
  position: relative;
  z-index: 2;
  transform: translate(0, -1px);
  border: none;
  float: none;
  margin: 0;
  padding: 0;
  text-align: right;
}
.contact_form legend .legend_inner {
  display: flex;
  align-items: baseline;
}
.contact_form legend .legend_ease {
  flex-grow: 1;
  height: 1px;
  transition: 440ms var(--trs1);
}
.contact_form legend .legend_ease:last-child {
  flex-grow: 0.025;
}
.contact_form legend .legend_text {
  font-size: 12px;
  font-variant-caps: titling-caps;
  font-style: italic;
  margin: 0;
  color: var(--dd3-4);
  padding-block: 3px 0 !important;
  padding-inline: 8px !important;
  margin-block-end: 4.5px !important;
  line-height: 1;
  background-color: #fff;
  border-radius: calc(var(--bdrs-4) + 1px) calc(var(--bdrs-4) + 1px) 0 0;
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
}
.contact_form legend .legend_text::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 100%;
  flex-shrink: 0;
  background-color: var(--dd3-4);
  opacity: 1;
}
.contact .form_group {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 10px;
}
.contact .form_group input, .contact .form_group textarea {
  border-radius: var(--bdrs-4) !important;
  overflow: hidden;
  width: calc(100% + 20px) !important;
  margin: -9px -10px -10px;
  border-color: transparent !important;
}
.contact .form_group input::placeholder, .contact .form_group textarea::placeholder {
  color: var(--dd3-4);
  font-style: italic;
}
.contact .form_group input:-internal-autofill-selected, .contact .form_group textarea:-internal-autofill-selected {
  box-shadow: inset 0 0 0 50px #FFE;
}
.contact .form_group textarea {
  resize: vertical;
}
.contact .text-danger {
  font-size: 12px;
  color: var(--cc2);
  font-style: italic;
  line-height: 1;
  position: relative;
}
.contact .form_set:last-child {
  display: flex;
  justify-content: flex-end;
}
.contact .form_set input[type=submit] {
  min-height: 36px;
  width: min(45%, 245px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--ff3-5);
  font-size: 15px;
}

/*----------------------------*/
.template {
  display: grid;
  min-height: 100vh;
  grid-template-columns: 100%;
  align-content: start;
  grid-template-rows: max-content 1fr max-content;
  grid-gap: 0;
}
.template_container {
  padding: var(--minmax_20_35) var(--tip_offset);
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 20px var(--minmax_20_35);
}
.template_htop, .template_hbot {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: max-content;
  grid-gap: calc(var(--minmax_20_35) * 2) var(--minmax_20_35);
}
.template_htop > *, .template_hbot > * {
  padding: 0 !important;
  margin: 0 !important;
}
.template_layout {
  --layout_row_gap: calc(var(--minmax_20_35) / 2 + 5px);
  --layout_col_gap: var(--minmax_20_35);
  display: grid;
  grid-gap: var(--layout_row_gap_var, 0) var(--layout_col_gap_var, 0);
  grid-template-columns: minmax(0px, 280px) 1fr minmax(0px, 280px);
  grid-template-areas: var(--layout_areas, var(--template_areas, unset));
  --layout_row_gap_var: var(--layout_row_gap, var(--layout_gap));
  --layout_col_gap_var: var(--layout_col_gap, var(--layout_gap));
}
@media screen and (min-width: 280px) and (max-width: 990.4px) {
  .template_layout {
    --layout_areas: "main main main" "left left left";
  }
}
.template_layout > * {
  width: unset !important;
  padding: 0 !important;
  margin: 0 !important;
}
.template_aside {
  display: grid !important;
  align-content: start;
  grid-template-columns: 100%;
  grid-gap: 20px;
  padding-bottom: 20px;
}
.template_aside.left {
  grid-area: left;
}
.template_aside.right {
  grid-area: right;
}
.template_contain {
  grid-area: main;
}
.template_contain, .template_hmain {
  display: grid;
  align-content: start;
  grid-template-columns: 100%;
  grid-gap: calc(var(--minmax_20_35)) calc(var(--minmax_20_35) / 2);
}
.template_heading {
  grid-gap: 5px;
  display: grid;
  grid-template-columns: 100%;
}
.template_content {
  display: contents;
}
.template.common-home .header_vertical_menu .box-heading {
  pointer-events: none;
}
.template.common-home .header_vertical_menu .box-heading::before {
  opacity: 0 !important;
}
.template.common-home .template_content.homw .main-slider {
  box-shadow: 0 0 0 1px var(--dd2);
  border-radius: var(--bdrs-12);
  padding: 10px !important;
}
.template.common-home #verticalmenublock ~ .main-slider .swiper-viewport {
  border-right: 1px solid var(--dd2);
  border-bottom: 1px solid var(--dd2);
  border-left: 1px solid var(--dd2);
}
.template.common-home .template_content {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: calc(var(--minmax_20_35) * 2);
}

/*----------------------------*/
.product_item_overlay {
  display: contents !important;
}
.product_item_contain {
  background-color: var(--dd3-5);
  border-radius: var(--bdrs-4);
  padding: var(--minmax_8_15);
  display: grid !important;
  grid-template-columns: 100%;
  grid-template-rows: max-content 1fr;
  grid-gap: 20px 15px;
  margin: 0 !important;
  max-width: unset !important;
}
.product_item_image {
  width: 100% !important;
  display: flex;
  justify-content: center;
  background-color: #fff;
  border-radius: var(--bdrs-4);
  align-items: center;
  max-width: 100% !important;
}
.product_item_figure {
  display: contents !important;
  width: 100% !important;
  height: auto !important;
  justify-content: center !important;
  align-items: center !important;
  min-width: 100%;
  min-height: 100%;
  border-radius: var(--bdrs-4);
  box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.3);
}
.product_item img {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
  border-radius: var(--bdrs-4) !important;
}
.product_item_caption {
  padding: 0 !important;
  display: flex !important;
  flex-direction: column;
  gap: 10px;
}
.product_item_percentsaving {
  filter: drop-shadow(0 0 5px rgba(176, 80, 22, 0.6));
  text-shadow: 1px 1px 0.7px rgba(0, 0, 0, 0.1);
  z-index: 30 !important;
  aspect-ratio: 30/15;
  line-height: 1.25;
  font-family: var(--ff2-4);
  border-radius: 0 !important;
  border-bottom-width: 2px !important;
  border-bottom-style: dashed !important;
  text-indent: 4px;
  background-color: unset !important;
  right: 8px !important;
  top: 4.5px !important;
}
.product_item_heading {
  padding-left: 8px !important;
  padding-right: 8px !important;
  overflow: unset !important;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
}
.product_item_name {
  letter-spacing: 0 !important;
  font-family: var(--ff1-5) !important;
  font-weight: 500 !important;
}
.product_item.product-list .product_item_name {
  font-family: var(--ff1-5);
}
.product_item_details {
  display: contents !important;
  width: 100% !important;
  max-width: 100% !important;
}
.product_item_price {
  padding-left: 8px !important;
  padding-right: 8px !important;
  margin: auto 0 0 !important;
  display: flex !important;
  gap: 3px var(--price_gap) !important;
  align-items: baseline !important;
  flex-wrap: wrap;
  font-style: italic !important;
  font-family: var(--ff1-7) !important;
  line-height: 1 !important;
  z-index: 2;
  --fz: 24px;
  --fz_currency: 14px;
  --fz_old: 14px;
  --line_height: 5px;
  --distance: 3px;
  --price_gap: 10px;
  --color_old: #555;
  --color_old-line: #d8c9c9;
}
.product_item_price_new {
  font-size: 24px !important;
  color: var(--dd2);
  position: relative;
  z-index: 2;
  font-family: var(--ff1-7) !important;
  margin: 0 !important;
  text-shadow: 1px 1px 0.7px rgba(0, 0, 0, 0.1);
}
.product_item_price_new::before {
  position: absolute;
  z-index: -1;
  bottom: calc(var(--line_height) * var(--line_scale, -0.05));
  left: -5px;
  width: calc(100% + 8px);
  height: var(--line_height);
  content: "";
  background-color: var(--color_old-line);
  border-radius: var(--line_height);
}
.product_item_price_new:nth-last-child(2)::before {
  --color_old-line: rgb(var(--rr1) / 70%);
}
.product_item_price_new > span {
  font-size: var(--fz_currency);
  margin-left: var(--distance);
  text-transform: uppercase;
}
.product_item_price_old {
  font-family: var(--ff1-6) !important;
  margin: 0 0 -7px !important;
  font-size: var(--fz_old) !important;
  text-decoration: line-through;
  color: var(--color_old);
  text-shadow: 1px 1px 0.7px rgba(0, 0, 0, 0.1);
}
.product_item_desc {
  padding-left: 8px;
  padding-right: 8px;
  text-align: left;
}
.product_item_addtocart {
  min-height: 36px;
  display: flex !important;
  align-items: center !important;
}
.product_box .product_item_contain {
  grid-template-columns: 40% 1fr;
  grid-template-rows: fit-content(50%) fit-content(50%) 30px !important;
  align-content: stretch !important;
  align-items: stretch !important;
  grid-gap: min(var(--minmax_8_15), 10px) !important;
  border-radius: var(--bdrs-4) !important;
  padding: 0;
}
.product_box .product_item_image {
  background-color: var(--dd1);
  grid-column: 1;
  grid-row: 1/span 2;
  padding: 0 !important;
  box-shadow: none !important;
  border-radius: var(--bdrs-4) !important;
}
.product_box .product_item_details, .product_box .product_item_caption {
  display: contents !important;
}
.product_box .product_item_heading {
  grid-column: 2/-1;
  grid-row: 1;
  padding: 5px 8px !important;
  margin: 0 !important;
  background-color: var(--dd1);
  justify-content: flex-end;
  text-align: right;
  border-radius: var(--bdrs-4);
}
.product_box .product_item_name {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: var(--ff1-5) !important;
  line-height: 1.1 !important;
  margin: 0 !important;
  padding: 0 !important;
  transform: none !important;
  text-align: right;
}
.product_box .product_item_name::before {
  display: none !important;
}
.product_box .product_item_price {
  margin: 0 !important;
  background-color: var(--dd1);
  border-radius: var(--bdrs-4);
  grid-column: 2/-1;
  grid-row: 2;
  flex-direction: column-reverse;
  align-content: stretch !important;
  align-items: flex-end !important;
  justify-content: flex-start !important;
  --fz_currency: 12px;
  --fz_old: 12px;
  --line_height: 4px;
  --distance: 0px;
  --price_gap: 2px;
  gap: 0 !important;
  padding: 4px 8px 5px 8px !important;
}
.product_box .product_item_price_new {
  font-size: 20px !important;
  line-height: 1 !important;
}
.product_box .product_item_price_new > span {
  margin-left: calc(var(--distance) / 1) !important;
  margin-right: calc(var(--distance) / 1.5) !important;
}
.product_box .product_item_price_old {
  font-size: 12px !important;
  line-height: 1 !important;
}
.product_box .product_item_addtocart {
  grid-column: 1/-1;
  grid-row: 3;
}
.product_box .product_item_image {
  display: flex !important;
  padding: 0;
  width: 100% !important;
  border-radius: var(--bdrs-4) var(--bdrs-4) 0 0;
  margin: 0 !important;
  box-shadow: 0 0 0 1px var(--dd3-4);
}
.product_box .product_item_caption {
  display: contents !important;
}
.product_box .product_item_addtocart {
  min-height: 26px !important;
  line-height: 1;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: var(--bdrs-4);
}
.product_feature .product_item_contain {
  margin: 0 !important;
  padding: var(--minmax_8_15);
}
.product_feature .product_item_name {
  display: flex !important;
  font-size: 15px !important;
  line-height: 20px !important;
  transform: translateY(0.2766666667em) !important;
  padding-top: 1px !important;
  font-family: var(--ff1-5);
}
.product_feature .product_item_name::before {
  content: "";
  margin-top: -9px !important;
  display: block !important;
  height: 0 !important;
}
.product_feature .product_item_details {
  width: 100% !important;
  max-width: 100% !important;
}
.product_list {
  --list_col: 1;
  --list_gap: var(--minmax_8_15);
  --list_rGap_var: var(--list_rGap, var(--list_gap));
  --list_cGap_var: var(--list_cGap, var(--list_gap));
  --list_columns: calc((100% - var(--list_cGap_var, 0) * (var(--list_col, 1) - 1)) / var(--list_col, 1));
  width: 100%;
  max-width: 100%;
  display: grid !important;
  grid-template-columns: repeat(var(--list_col, 1), var(--list_columns, 100%));
  gap: var(--list_rGap_var, 0) var(--list_cGap_var, 0);
  margin-left: auto !important;
  margin-right: auto !important;
}
.product_list::before, .product_list::after {
  display: none !important;
}
.product_list .product_item {
  display: contents;
}
@media screen and (min-width: 520.4px) {
  .product_list .product_contain {
    grid-template-columns: fit-content(44%) 1fr 0.5fr 0.35fr 0.15fr;
    grid-template-rows: minmax(33px, max-content);
    grid-auto-columns: max-content;
    min-width: 2;
    align-content: start;
    grid-gap: 8px var(--minmax_8_15);
    align-items: start;
    grid-auto-rows: max-content;
  }
}
@media screen and (min-width: 280px) and (max-width: 567.7px) {
  .product_list {
    --list_col: 1;
  }
}
@media screen and (min-width: 568px) and (max-width: 911.7px) {
  .product_list {
    --list_col: 2;
  }
}
@media screen and (min-width: 912px) and (max-width: 1259.7px) {
  .product_list {
    --list_col: 3;
  }
}
@media screen and (min-width: 1260px) {
  .product_list {
    --list_col: 3;
  }
}
.product_list .product:not(.product-list) .product_item_addtocart {
  width: 100% !important;
}
.product_list .product-list .product_item_image {
  aspect-ratio: unset;
}
@media screen and (min-width: 568px) {
  .product_list .product-list .product_item_contain {
    grid-column: 1/-1;
    grid-template-columns: minmax(176px, 22%) 1fr 0.5fr 0.35fr 0.15fr;
    grid-template-rows: minmax(25px, max-content);
    grid-auto-columns: max-content;
    align-content: start;
    grid-gap: 8px var(--minmax_8_15);
    align-items: start;
  }
  .product_list .product-list .product_item_image {
    grid-row: 1/span 4;
  }
  .product_list .product-list .product_item_caption, .product_list .product-list .product_item_details {
    display: contents !important;
  }
  .product_list .product-list .product_item_heading {
    align-self: center;
    grid-column: 2/4;
    grid-row: 1;
  }
  .product_list .product-list .product_item_name {
    font-family: var(--ff1-5);
  }
  .product_list .product-list .product_item_desc {
    grid-row: 2;
    grid-column: 2/5;
    margin: 0;
  }
  .product_list .product-list .product_item_addtocart {
    align-self: end;
    grid-column: 2;
    grid-row: 4;
    min-height: 36px;
  }
  .product_list .product-list .product_item_price {
    justify-self: end;
    grid-row: 3;
    grid-column: 2/3;
    align-content: flex-start;
  }
}
.product_list .product-grid .product_item_heading {
  margin-bottom: 8px;
}
.product_list .product-grid .product_item_price {
  justify-content: flex-start;
}
@media screen and (min-width: 568px) {
  .product.product-list .product_addtocart {
    grid-column: 2;
    grid-row: 4;
    min-height: 40px;
  }
  .product.product-list .product_price {
    justify-self: end;
    grid-row: 3;
    grid-column: 2/3;
    align-content: flex-start;
  }
}
.product.product-grid .product_price {
  justify-content: flex-start;
}

.category_sortbar {
  display: grid;
  grid-auto-rows: minmax(34px, auto);
  grid-auto-columns: max-content;
  grid-gap: 5px calc(var(--minmax_8_15) * 2);
  background-color: var(--dd3-5);
  padding: calc(var(--minmax_8_15) / 2) var(--minmax_8_15);
  border-radius: var(--bdrs-4);
}
@media screen and (min-width: 280px) and (max-width: 620.4px) {
  .category_sortbar {
    align-items: center;
    align-content: center;
    grid-template-columns: 1fr max-content;
  }
}
@media screen and (min-width: 620px) {
  .category_sortbar {
    grid-template-columns: 1fr max-content max-content;
  }
}
.category_sortbar_overlay {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 5px 10px;
}
@media screen and (min-width: 620px) {
  .category_sortbar_overlay {
    margin-top: -15px;
  }
}
.category_sortbar_toggle_item {
  grid-column: 1/-1;
  margin-top: calc(var(--minmax_8_15) / 2);
  margin-bottom: calc(var(--minmax_8_15) / 2 - 5px);
}
.category_sortbar_contain {
  background-color: var(--dd1);
  padding: calc(var(--minmax_8_15) / 2) var(--minmax_8_15);
}
.category_sortbar_contain img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
  border-radius: var(--bdrs-8);
}
.category_sortbar_txt_btn_toggle.is_open, .category_sortbar_icn_btn_toggle.is_open {
  color: var(--hh1);
}
.category_sortbar_txt_btn_toggle {
  justify-self: end;
  font-style: italic;
  font-size: var(--size-fluid-22);
  font-family: var(--ff1-5);
  transition: 250ms;
}
.category_sortbar_txt_btn_toggle .ui_btn_txt {
  text-decoration: underline dashed var(--cc1) 0.1em;
  text-decoration-skip-ink: none;
  transition: 250ms;
}
.category_sortbar_txt_btn_toggle .ui_btn_txt::before {
  content: attr(data-show-text);
  font-size: inherit;
  color: currentColor;
  line-height: inherit;
  font-weight: inherit;
}
.category_sortbar_txt_btn_toggle:hover .ui_btn_txt {
  text-decoration: underline solid var(--hh1) 1.5px;
  text-decoration-skip-ink: auto;
}
.category_sortbar_txt_btn_toggle.is_open .ui_btn_txt::before {
  content: attr(data-hide-text);
}
.category_sortbar_txt_btn_toggle:not(.is_open) .ui_btn_txt::before {
  content: attr(data-show-text);
}
.category_sortbar_icn_btn_toggle {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
.category_sortbar_icn_btn_toggle .ui_btn_icn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.category_sortbar_sorts, .category_sortbar_limit {
  align-self: baseline;
}
.category_sortbar .new-limit__label {
  min-width: 82px !important;
}
.category_sortbar_actions {
  display: flex;
  justify-self: center;
  place-items: center;
  gap: 3px calc(var(--minmax_8_15) / 2);
  padding-bottom: 1px;
}
.category_sortbar_actions > * {
  width: 26px !important;
  height: 26px !important;
  background: none !important;
  padding: 0 !important;
  margin: 0 !important;
}
.category_sortbar_actions > *:first-child:nth-last-child(3) {
  margin-inline: calc(10px - var(--minmax_8_15) / 2) 10px !important;
}
.category_sortbar_actions > *:first-child:nth-last-child(2) {
  margin-inline: calc(10px - var(--minmax_8_15) / 2) 10px !important;
}
.category_sortbar_actions > *:first-child:nth-last-child(1) {
  margin-left: calc(10px - var(--minmax_8_15) / 2) !important;
}
@media screen and (min-width: 280px) and (max-width: 620.4px) {
  .category_sortbar_sorts {
    grid-column: 1/-1;
    grid-row: 2;
    grid-template-columns: max-content 1fr;
  }
  .category_sortbar_sorts .new-select__select.customSelect {
    width: 100%;
  }
}
@media screen and (min-width: 280px) and (max-width: 620.4px) {
  .category_sortbar_limit {
    align-self: center !important;
  }
}
.category_sortbar_contain {
  overflow: hidden;
}
@media screen and (min-width: 280px) and (max-width: 620.4px) {
  .category_sortbar_contain {
    border-radius: var(--bdrs-4);
  }
}
@media screen and (min-width: 621px) {
  .category_sortbar_contain {
    border-radius: var(--bdrs-8);
  }
}
.category_sortbar_close {
  width: 26px;
  height: 26px;
  position: absolute;
}
.category_sortbar_close svg {
  width: 26px;
  height: 26px;
}
.category_description {
  line-height: 1.5;
  font-size: min(15px, max(12px, 0.4vmin));
}

/*----------------------------*/