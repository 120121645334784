.search
  display: flex !important
  &_label
    display: contents
  &_inp
    flex-grow: 1
    appearance: none !important
    border-radius: var(--bdrs-4) 0 0 var(--bdrs-4) !important

    // background-color: rgb(34, 35, 34) !important
    // color: rgb(160, 149, 130) !important
