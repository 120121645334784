@import ../../_mixins
&_contain
  display: contents

  +from(990.4px)
    display: grid
    background-color: #363734
    align-content: start
    grid-auto-rows: max-content
    align-items: center
    grid-gap: var(--minmax_8_15)
    padding: calc(var(--minmax_8_15) * 2) var(--tip_offset)
    grid-template-columns: fit-content(var(--verticalmenu_width)) minmax(280px, .5fr) minmax(280px, 1fr)  max-content
    grid-template-areas: 'q w e r'

  > *
    padding: 0 !important
    margin: 0 !important

&_logo
  grid-area: q
  +from-to($mm1, 414.4px)
    justify-content: center
    width: auto
  img
    +from-to($mm1, 990.4px)
      width: 75%
    +from(990.4px)
      width: 100%
    object-fit: contain
&_search
  grid-area: e
  button
    width: auto !important

    padding-inline: 8px !important
    background-color: var(--cc1) !important
    +from(990px)
      min-width: 100px !important
    > *
      pointer-events: none
      filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, .3))
    &:hover
      background-color: var(--hh1) !important
    > *
      flex-shrink: 0 !important
  +from-to($mm1, 990.4px)
    input, button

      height: 100% !important

&_content
  grid-area: w
  +from-to($mm1, 553.4px)
    .header-cms
      padding-left: 0 !important
      background-image: none !important
    .headercms-title
      display: none !important

&_language
  grid-area: r


&_logo
  +from-to($mm1, 553.4px)
    img
      height: 38px
      width: auto

+from-to($mm1, 990.4px)
  &_cart
    grid-area: d
  #res-menu
    grid-area: a


&_logo
  +from(991px - .4)
    padding-left: calc(var(--minmax_8_15)/2) !important
  display: flex
  align-items: center
  width: 100%

&_search
  padding: 0
  button
    min-width: 44px
&_content
  color: var(--dd1)

  +from-to($mm1,553.4px)
    place-self: center start
  +from(553px)
    place-self: center center



&_language
  display: flex
  .language-select
    span
      padding-bottom: 5px !important
      padding-top: 5px !important
      border-bottom: 1.5px dashed transparent !important
    &.active
      span
        border-bottom-color: var(--hh1) !important
  #form-language
    gap: 4px var(--minmax_8_15)
    img
      margin-right: 0


#search
  +from-to($mm1, 414.4px)
    display: none !important