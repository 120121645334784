@mixin font-face($font-family, $font-style: normal, $font-weight: normal, $file-path, $asset-pipeline: 'woff2', $fallback: fallback)
    @font-face
        // font-display: fallback
        font-display: $fallback
        font-family: $font-family
        font-weight: $font-weight
        font-style: $font-style
        @if $asset-pipeline == ttf
            src: url('#{$file-path}.ttf') format('truetype')
        @else if $asset-pipeline == woff
            src: url('#{$file-path}.woff') format('woff')
        @else if $asset-pipeline == woff2
            src: url('#{$file-path}.woff2') format('woff2')
        @else
            src: url('#{$file-path}.eot')
            src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), url('#{$file-path}.woff') format('woff'), url('#{$file-path}.ttf') format('truetype')
