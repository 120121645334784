&.common-home
  .header_vertical_menu
    .box-heading
      pointer-events: none

      &::before
        opacity: 0 !important


  .template_content.homw .main-slider
    box-shadow: 0 0 0 1px var(--dd2)
    border-radius: var(--bdrs-12)
    padding: 10px !important
  #verticalmenublock
    ~.main-slider
      .swiper-viewport
        border-right: 1px solid var(--dd2)
        border-bottom: 1px solid var(--dd2)
        border-left: 1px solid var(--dd2)






&.common-home &
  &_content
    display: grid
    grid-template-columns: 100%
    grid-gap: calc(var(--minmax_20_35) * 2)


