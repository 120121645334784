&_menu
  height: var(--hb_height)
  display: flex
  flex-wrap: wrap
  gap: calc(var(--minmax_8_15) / 1)
  padding-block: calc(var(--minmax_8_15) / 2)
  padding-inline: 0
  margin-inline: calc(var(--HS_offset) / 2) !important
  +from-to($mm1, 990.4px)
    display: contents !important
    background-color: #fff
    #res-menu
      background-color:  var(--dd1)
      border-radius: var(--bdrs-4)
    > *
      margin: 0!important
  &::before
    display: none
  .nav-inner,
  #menu .nav.navbar-nav,
  #menu .top_level

      display: contents !important
  #menu.main-menu
    +from(990.4px)
        display: contents !important
    a
      font-family: var(--ff1-3)
      background-color: rgb(var(--rr1) / 10%)
      padding: calc(var(--minmax_8_15) / 2) var(--minmax_8_15)
      border-radius: var(--bdrs-4)
      transition: 300ms
      height: 100%
      text-transform: unset
      &:hover
        background-color: var(--hh1)
        color: var(--cc3)