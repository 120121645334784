// &_feature
&_feature &_item
  &_contain
    margin: 0 !important
    padding: var(--minmax_8_15)




  &_name
    display: flex !important
    @include trim(15, 10, 'force')
    font-family: var(--ff1-5)

  &_details
    width: 100% !important
    max-width: 100% !important