@mixin if-child-count($n:1, $selector: #{&}, $c:0)

  @if $n == 1
    &:first-child:nth-last-child(1)
      @content
  @else
    @if $c == 1
      &:first-child:nth-last-child(#{$n})
        @content
    @else if $c > 1
      &:first-child:nth-last-child(#{$n}) ~ #{$selector}:nth-child(#{$c})
        @content
    @else
      &:first-child:nth-last-child(#{$n}),
      &:first-child:nth-last-child(#{$n}) ~ #{$selector}
        @content