.column_aside
  display: grid !important
  grid-template-columns: 100%
  grid-gap: 20px
  padding-bottom: 20px
  .box.bestseller
    +from-to(280px, 992px - .4)
      order: -1


  > *
    margin: 0 !important
#column-left
  display: grid !important
#column-left .box
  width: min(720px,100%)
  margin-left: auto
  margin-right: auto
#column-left .box .box-content
  display: block !important