/* Leading Trim
/* One of the challenges when working with some typefaces is having
/* a different leading value which might result in inconsistent spacing
/* before and after a font. */
%trimRow
  display: grid
  grid-template-columns: 100%
  grid-template-rows: var(--trim, 16px)
  align-items: center
  > *:nth-child(1),
  &::before
    display: block
    grid-column: 1
    grid-row: 1
  // &::before
  //   content: ''
  //   height: var(--row_height, 16px)
  //   display: inline-block
  //   vertical-align: middle

/* Leading Trim end */


%scrollbar_hide
  --scrollbar_overflow: var(--scrollbar_overflow_x) var(--scrollbar_overflow_y)
  -ms-overflow-style: var(--scrollbar_overflow_style, none)
  overflow: var(--scrollbar_overflow_xy, var(--scrollbar_overflow, auto))
  scrollbar-width: var(--scrollbar_ff_width, none)
  &::-webkit-scrollbar
    width: var(--scrollbar_width, 0)
    height: var(--scrollbar_height, 0)