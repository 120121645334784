@import ../../_mixins
.header_cart
  cursor: pointer
  position: relative
  background-color: var(--cc1)
  border-radius: var(--bdrs-4)
  transition: 300ms
  +from(990.4px)
    border-radius: var(--bdrs-8)
    margin-block: calc(var(--HS_offset) / -2) !important
  > *
    filter: drop-shadow(1px 1px 4px rgb(0 0 0 / 30%))
    pointer-events: none
  &:hover
    background-color: var(--hh1)
  &#cart
    display: grid
    color: var(--dd1)
    padding-block: calc(var(--minmax_8_15) / 1.7) calc(var(--minmax_8_15) / 1.2)
    padding-inline: calc(var(--minmax_8_15) / 1.15)
    box-shadow: 2px 5px 22px rgb(0 0 0 / 40%)
    z-index: 6
    grid-gap: 0 calc(var(--minmax_8_15) / 2)
    align-items: center
    grid-template-columns: max-content max-content
    grid-template-rows: max-content
    +from-to($mm1, 553.4px)
      gap: 0
    +from-to($mm1, 990.4px)
      .header_cart_icon
        display: flex
        align-items: flex-end
        justify-content: center
      .header_cart_icon svg
        height: auto
        width: 25px
    +from(990.4px)
      // width: auto !important
      margin-inline: calc(var(--HS_offset) * -1) !important
      border: none !important
    &.zero .header_cart_total,
    &.zero .header_cart_quantity
      display: none
    &.zero .header_cart_icon
      grid-column: 2
    &.zero .header_cart_title
      grid-column: 1
      grid-row: 1 / span 2
      place-self: center
  > button
    display: contents !important
  &_icon
    align-self: end
    grid-column: 1
    grid-row: 1 / span 2
    transform: translate(0px, 4px) rotate(1deg) scaleX(-1)
  &_title

    grid-column: 2
    grid-row: 1
    +from-to($mm1, 990.4px)
      font-size: var(--size-5)
    +from(990.4px)
      font-size: var(--size-5)
    place-self: end
    letter-spacing: 0.04em
    line-height: 1.1
    font-family: var(--ff1-4)
    min-width: 68px
    +from-to($mm1, 553.4px)
      display: none
  &_total
    grid-column: 2
    grid-row: 2
    font-family: var(--ff1-7)
    font-size: var(--size-fluid-1)
    +from-to($mm1, 553.4px)
      display: none
  &_quantity
    grid-column: 1
    grid-row: 1


  &_quantity
    --fz: 24px
    --fz_currency: 14px
    --fz_old: 14px
    --line_height: 5px
    --distance: 3px
    --price_gap: 10px
    --color_old: #555
    --color_old-line: #d8c9c9
    align-items: center
    background-color: rgb(249 17 85)
    border-radius: 7px
    color: var(--dd1)
    display: flex
    font-family: var(--ff1-5)
    text-indent: 2px
    font-size: 9px
    font-weight: 500
    grid-column: 1
    grid-row: 1
    justify-content: center
    min-height: 14px
    min-width: 14px
    padding: 1px 2.3px 1px 1px
    place-self: end start
    pointer-events: none
    position: absolute
    letter-spacing: -.003em
    right: 80%
    top: 50%
    transform: translate(.1vw, -122%) translatex(2.4px) skew(-3deg) rotate(1.5deg)
    // z-index: -1
    // box-shadow: 5px -3.5px 0 -4px rgb(249 17 85)
    transition: 250ms
    line-height: 0.7
    +from-to($mm1, 553.4px)
      left: auto
      right: 45%
      // transform: translate(2px, 1px) rotate(1deg)
      justify-self: center

  &:hover .header_cart_quantity
    z-index: 2
    border-radius: 3px
    box-shadow: none
    padding: 1px 2px 2px
    text-indent: 0
    min-height: 12px
    transform: translate(.13vw, -118%) scale(2.3) !important
    line-height: 0.8

  &_total
    font-size: 16px
    @extend .ui_title
  &_total.zero
      display: none !important
  &_total.zero + &_quantity
    display: none !important

  &_integer,
  &_currency
    text-shadow: 1px 1px 0.7px rgba(0, 0, 0, .1) !important
    line-height: 1 !important
    font-family: var(--ff1-5) !important
    font-weight: 500 !important
    font-size: 16px !important
    z-index: 2
    font-style: italic
    color: var(--dd2)
  // &_integer

  &_currency
    font-size: 10px !important
    text-transform: uppercase



.responsive-menu .main-navigation
  top: calc(100% + 8px)
  width: 100vw
  left: -8px

