&_empty
  flex-grow: 1
  margin: var(--mfp_frame_inset_reverse_ver) var(--mfp_frame_inset_reverse_hor) !important
  padding: var(--mfp_frame_inset_ver) var(--mfp_frame_inset_hor) !important
  width: auto !important
  display: flex
  justify-content: center
  align-items: center
  position: relative
  z-index: 2
  &_text
    color: rgb(0 0 0 / 50%)
    text-align: center
    font-style: italic
    font-size: 20px
    text-indent: 5px
    padding-bottom: 7.25vmin
    mix-blend-mode: multiply
  &::before
    content: ''
    position: absolute
    inset: 0
    opacity: .15
    z-index: -1
    background-blend-mode: multiply
    background-color: var(--dd1)
    background-image: url('../image/decor/basket_empty_icon.webp')
    background-position: 96% 94%
    background-repeat: no-repeat
    background-size: 16% auto
    pointer-events: none