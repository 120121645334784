@use "sass:list"
@use "sass:math"


@function calculateTypeOffset($lh, $fontSize, $descenderHeightScale)
  $lineHeightScale: math.div($lh, $fontSize)
  @return math.div(($lineHeightScale - 1), 2) + $descenderHeightScale


/** Our system will use a 4px base vertical rhythm */
$verticalRhythmBase: 2

/** Our system's base font size */
$baseFontSize: 16

/**
 * Calculate the type offset for a given font
 *
 * @param {number} $lh - the font's base line height
 * @param {number} $fontSize - the font's size
 * @param {number} $descenderHeightScale - the font's descender height as a ratio
 * @return {number} the offset to be added to a transformY to keep the text in place
 */
@function calculateTypeOffset($lh, $fontSize, $descenderHeightScale)
  $lineHeightScale: math.div($lh, $fontSize)
  @return math.div(($lineHeightScale - 1), 2) + $descenderHeightScale


/**
 * The basekick base function
 *
 * @param {number} $typeSizeModifier - a multiplier to determine the font size
 * @param {number} $typeRowSpan - how many rows of our vertical rhythm should the type span
 * @param {number} $descenderHeightScale - the height of the descender expressed as a ratio of the font
 * @param {number} $capHeight - the font's cap height expressed as a ratio of the font
 */
@mixin basekick($typeSizeModifier, $typeRowSpan, $forced, $descenderHeightScale, $capHeight)
  $fontSize: $typeSizeModifier * $baseFontSize
  $lineHeight: $typeRowSpan * $verticalRhythmBase
  $typeOffset: calculateTypeOffset($lineHeight, $fontSize, $descenderHeightScale)
  $topSpace: $lineHeight - $capHeight * $fontSize
  $heightCorrection: 0

  @if $topSpace > $verticalRhythmBase
    $heightCorrection: $topSpace - ($topSpace % $verticalRhythmBase)


  $preventCollapse: 1

  @if $forced == false
    font-size: #{$fontSize}px
    line-height: #{$lineHeight}px
    transform: translateY(#{$typeOffset}em)
    padding-top: #{$preventCollapse}px
    &::before
      content: ''
      margin-top: #{-($heightCorrection + $preventCollapse)}px
      display: block
      height: 0
  @else
    font-size: #{$fontSize}px !important
    line-height: #{$lineHeight}px !important
    transform: translateY(#{$typeOffset}em) !important
    padding-top: #{$preventCollapse}px !important
    &::before
      content: ''
      margin-top: #{-($heightCorrection + $preventCollapse)}px !important
      display: block !important
      height: 0 !important


/**
 * The trim mixin to apply base-kick styles
 *
 * @param {number} $typeSizeModifier - a multiplier for our system's $baseFontSize
 * @param {number} $typeRowSpan - the height of the descender expressed as a ratio of the font
 */
@mixin trim($markUpFontSize, $typeRowSpan, $forced, $defaultFontSize: $baseFontSize)
  $trimDescenderHeightScale: 0.11
  $trimCapHeight: 0.75
  $forced: ''
  $typeSizeModifier: math.div($markUpFontSize, $defaultFontSize)

  @include basekick($typeSizeModifier, $typeRowSpan, $forced, $trimDescenderHeightScale, $trimCapHeight)


/**
 * The trim mixin to apply base-kick styles
 *
 * @param {16px / 14px} =>  mark_up_font_size / default_font_size => size modifier
 * @param {11} => mark_up_text_height_trim => 22px line_height on site
 *
 * @use "sass:math"
 *
 * @include trim(16, 11)
 **/