.footerbottom
  .social-block

    ul
      li
        a
          &::before
            font-size: calc(15px * 1.5)
      li
        // &.facebook
        //   a
        //     &::before
        //       font-size: calc(15px * 1.5)
        &.instagram
          a
            &::before
              content: '\f16d'
              font-size: calc(15px * 1.55)