.template
  display: grid
  min-height: 100vh
  grid-template-columns: 100%
  align-content: start
  grid-template-rows: max-content 1fr max-content
  grid-gap: 0


  &_container
    padding: var(--minmax_20_35) var(--tip_offset)
    display: grid
    grid-template-columns: 100%
    grid-gap: 20px var(--minmax_20_35)

  // &_htop
    // +mm9
    //   margin-top: calc(var(--minmax_20_35) * -1)
  &_htop,
  &_hbot
    display: grid
    grid-template-columns: 100%
    grid-auto-rows: max-content
    grid-gap: calc(var(--minmax_20_35) * 2) var(--minmax_20_35)
    > *
      padding: 0 !important
      margin: 0 !important

  // &_header


  &_layout
    --layout_row_gap: calc(var(--minmax_20_35) / 2 + 5px)
    --layout_col_gap: var(--minmax_20_35)

    display: grid

    grid-gap: var(--layout_row_gap_var, 0) var(--layout_col_gap_var,0)
    grid-template-columns: minmax(0px, 280px) 1fr minmax(0px, 280px)
    grid-template-areas: var(--layout_areas, var(--template_areas, unset))
    // --calc
    --layout_row_gap_var: var(--layout_row_gap, var(--layout_gap))
    --layout_col_gap_var: var(--layout_col_gap, var(--layout_gap))
    +from-to($mm1, 990.4px)
      --layout_areas: 'main main main' 'left left left'

    > *
      width: unset !important
      padding: 0 !important
      margin: 0 !important


  &_aside
    display: grid !important
    align-content: start
    grid-template-columns: 100%
    grid-gap: 20px
    padding-bottom: 20px
    &.left
      grid-area: left
    &.right
      grid-area: right
  &_contain
    grid-area: main
  &_contain,
  &_hmain
    display: grid
    align-content: start
    grid-template-columns: 100%
    grid-gap: calc(var(--minmax_20_35)) calc(var(--minmax_20_35) / 2)
  &_heading
    grid-gap: 5px
    display: grid
    grid-template-columns: 100%
  &_content
    display: contents


  @import home-page