
.swiper-slide
  background-color: var(--dd3-5)
  border-radius: var(--bdrs-4)
  padding: 15px
  a
    display: flex

    border-radius: var(--bdrs-4)
    aspect-ratio: 188/265
    box-shadow: 0 0 10px -4px rgb(0 0 0 / 30%)
  img
    // mix-blend-mode: multiply
    border-radius: var(--bdrs-4)
    width: 100%
    height: 100%
    object-fit: cover


    margin: 0 !important
