&_sortbar
  display: grid
  +from-to($mm1, 620.4px)
    align-items: center
    align-content: center
    grid-template-columns: 1fr max-content
  +from(620px)
    grid-template-columns: 1fr max-content max-content
  grid-auto-rows: minmax(34px, auto)
  grid-auto-columns: max-content
  grid-gap: 5px calc(var(--minmax_8_15) * 2)
  background-color: var(--dd3-5)
  padding: calc(var(--minmax_8_15) / 2) var(--minmax_8_15)
  border-radius: var(--bdrs-4)


  &_overlay
    display: grid
    grid-template-columns: 100%
    grid-gap: 5px 10px

    +from(620px)
      margin-top: -15px


  &_toggle_item
    grid-column: 1/-1
    margin-top: calc(var(--minmax_8_15) / 2)
    margin-bottom: calc(var(--minmax_8_15) / 2 - 5px)

  &_contain
    background-color: var(--dd1)
    padding: calc(var(--minmax_8_15) / 2) var(--minmax_8_15)
    img
      display: block
      max-width: 100%
      width: 100%
      height: auto
      border-radius: var(--bdrs-8)
  &_txt_btn_toggle.is_open,
  &_icn_btn_toggle.is_open
      color: var(--hh1)

  &_txt_btn_toggle
    justify-self: end
    font-style: italic
    font-size: var(--size-fluid-22)
    font-family: var(--ff1-5)
    transition: 250ms

    .ui_btn_txt
      text-decoration: underline dashed  var(--cc1) .1em
      text-decoration-skip-ink: none
      transition: 250ms
      &::before
        content: attr(data-show-text)
        font-size: inherit
        color: currentColor
        line-height: inherit
        font-weight: inherit
    &:hover .ui_btn_txt
      text-decoration: underline solid var(--hh1) 1.5px
      text-decoration-skip-ink: auto

  &_txt_btn_toggle.is_open .ui_btn_txt::before
    content: attr(data-hide-text)
  &_txt_btn_toggle:not(.is_open) .ui_btn_txt::before
    content: attr(data-show-text)

  &_icn_btn_toggle

    // height: 26px !important
    // width: 26px !important
    // margin-right: calc(var(--minmax_8_15) * 2 ) !important
    display: flex
    flex-grow: 1
    align-items: center
    justify-content: center
    .ui_btn_icn
      display: flex
      align-items: center
      justify-content: center

  &_sorts,
  &_limit
    align-self: baseline
  .new-limit__label
      min-width: 82px !important

  &_actions
    display: flex
    justify-self: center
    place-items: center
    // grid-template-columns: max-content max-content
    gap:  3px calc(var(--minmax_8_15) / 2)
    padding-bottom: 1px
    > *
      width: 26px !important
      height: 26px !important
      background: none !important
      padding: 0 !important
      margin: 0 !important
      +if-child-count(3, '*', 1)
        margin-inline: calc(10px - var(--minmax_8_15)/2) 10px !important
      +if-child-count(2, '*', 1)
        margin-inline: calc(10px - var(--minmax_8_15)/2) 10px !important
      +if-child-count(1, '*', 1)
        margin-left: calc(10px - var(--minmax_8_15)/2) !important

  &_sorts
    +from-to($mm1, 620.4px)
      grid-column: 1/-1
      grid-row: 2
      grid-template-columns: max-content 1fr
      .new-select__select.customSelect
        width: 100%
  &_limit
    +from-to($mm1, 620.4px)
      align-self: center !important


  &_contain
    +from-to($mm1, 620.4px)
      border-radius: var(--bdrs-4)

    +from(621px)
      border-radius: var(--bdrs-8)
    overflow: hidden

  &_close
    width: 26px
    height: 26px
    position: absolute
    svg
      width: 26px
      height: 26px