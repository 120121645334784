.box
  &_heading
    margin: 0
    text-transform: uppercase !important
    color: var(--dd2) !important
    letter-spacing: 0 !important
    text-align: center !important
    font-size: var(--size-fluid-3)
    font-family: var(--ff1-7) !important
    border-bottom: 1px solid #d8c9c9 !important

  &_head

    text-align: center !important
  &_content

    margin: 0 !important
    background-color: #f2f2f2 !important
    border: none !important
  &_section
    display: grid
    grid-template-columns: 100%
    grid-gap: var(--minmax_8_15)
    border: none !important

.box_featured
  padding: 0 !important

  grid-gap: var(--minmax_15_25) var(--minmax_8_15)
  position: relative
  .customNavigation
    position: static !important
    display: flex
    justify-content: space-between
    grid-gap: calc(var(--minmax_8_15) / 2)
    width: auto
    > *
      display: flex
      align-items: center
      justify-content: center
      width: 36px
      height: 36px
      &::before
        font-size: 22px !important
        line-height: 1 !important
        width: 100%
        text-align: center
  .box_wrapper
    display: grid
    grid-template-columns: 100%
    min-width: 0
    grid-gap: var(--minmax_8_15) var(--minmax_8_15)
    padding-top: var(--minmax_8_15)
    padding-bottom: var(--minmax_8_15)
    //max-width: 1505px
    //margin-left: auto
    //margin-right: auto
  .box_container
    &:not(.slider)
      display: grid
      align-items: center
      grid-template-columns: 1fr max-content
      grid-auto-rows: max-content
      padding: 0
      //padding: 0 var(--tip_offset) !important
    //&.slider
      //padding: 0 max(1rem, calc((min(100%, 100vw) - calc(var(--tip_wrap, 0px) + var(--tip_inset, 0px) * 4)) / 2))  !important