&_box &_item
  &_contain

    grid-template-columns: 40% 1fr
    // grid-auto-rows: minmax(auto, max-content)
    grid-template-rows: fit-content(50%) fit-content(50%) 30px !important
    align-content: stretch !important
    align-items: stretch !important

    grid-gap: min(var(--minmax_8_15), 10px) !important
    border-radius: var(--bdrs-4) !important
    // overflow: hidden !important
    padding: 0
    // padding: var(--minmax_8_15)

  &_image
    background-color: var(--dd1)
    grid-column: 1
    grid-row: 1 / span 2
    padding: 0 !important
    box-shadow: none !important
    border-radius: var(--bdrs-4) !important

  &_details,
  &_caption
    display: contents !important

  &_heading
    grid-column: 2 /-1
    grid-row: 1
    padding: 5px 8px !important
    margin: 0 !important
    background-color: var(--dd1)
    // display: contents !important
    justify-content: flex-end
    text-align: right
    border-radius: var(--bdrs-4)
  &_name
    font-size: 14px !important
    font-weight: 500 !important
    font-family: var(--ff1-5) !important
    line-height: 1.1 !important
    margin: 0 !important
    padding: 0 !important
    transform: none !important
    text-align: right
    &::before
      display: none !important

  &_price
    margin: 0 !important
    background-color: var(--dd1)
    border-radius: var(--bdrs-4)
    grid-column: 2 /-1
    grid-row: 2
    flex-direction: column-reverse
    align-content: stretch !important
    align-items: flex-end !important
    justify-content: flex-start !important

    --fz_currency: 12px
    --fz_old: 12px
    --line_height: 4px
    --distance: 0px
    --price_gap: 2px
    gap: 0 !important
    padding: 4px 8px 5px 8px !important

    &_new
      font-size: 20px !important
      line-height: 1 !important
      > span
        margin-left: calc(var(--distance) / 1) !important
        margin-right: calc(var(--distance) / 1.5) !important
        // margin: 0 calc(var(--distance) / 5) 0 calc(var(--distance) / 6) !important
    &_old
      font-size: 12px !important
      line-height: 1 !important


  &_addtocart
    grid-column: 1 / -1
    grid-row: 3

    // padding: 0 !important
    // display: grid
    // grid-template-columns: fit-content(44%) 1fr
    // grid-gap: 10px

  &_image
    display: flex !important

    padding: 0
    width: 100% !important
    border-radius: var(--bdrs-4) var(--bdrs-4) 0 0
    margin: 0 !important
    box-shadow: 0 0 0 1px var(--dd3-4)

  &_caption
    display: contents !important
    // display: flex !important
    // flex-direction: column
    // gap: 5px
    // padding:  5px 10px !important

  // &_caption >*:not(&_addtocart):not(&_price)
  //   margin: 0 !important
  //   padding: 0 !important

  &_price

    &_new
      // @include trim(14, 8, true)
      // font-size: min(50px, max(33px, 5vmin))




  // &_actions

  &_addtocart
    min-height: 26px !important
    line-height: 1
    display: flex !important
    align-items: center
    justify-content: center
    // border-radius: 0 0 var(--bdrs-4) var(--bdrs-4)
    border-radius: var(--bdrs-4)

