.header
  &-cms
    background: url(../image/codezeel/headphone_white.svg) no-repeat scroll 0 7px transparent
    background-size: 31px
    padding-left: 38px
    position: relative
    &-block
      display: inline-grid
.headercms-title
    font-size: 14px
    font-weight: 500
    letter-spacing: 0.8px
    text-transform: capitalize
    color: #fff
    line-height: 22px
    +from-to($mm1, 553.4px)
      line-height: 18px
      font-size: 12px
.headercms-subtitle


    font-size: 14px
    font-weight: 400
    letter-spacing: 0.5px
    color: #afbc25
    line-height: 22px
    a
      white-space: nowrap

      color: #fff
      font-size: 19px
      +from-to($mm1, 553.4px)
        font-size: 19px

        letter-spacing: 0.06em