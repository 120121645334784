&-preloader
  display: none

&-wrap
  position: fixed !important
  z-index: 2101 !important
  top: 0 !important
  left: 0 !important
  width: 100vw !important
  height: 100vh !important
  overflow: hidden !important

&-container
  text-align: right !important
  padding: 0 !important
  &::before
    display: none !important

&-content
  display: grid !important
  grid-template-columns: 100vw
  grid-template-rows: 100vh
  pointer-events: none
  backdrop-filter: blur(2px)

