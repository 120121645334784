
.header
  display: grid
  grid-auto-rows: minmax(40px, max-content)
  grid-template-columns: 40px .5fr 1.2fr max-content 40px
  +from(553px)
    grid-template-columns: 40px .5fr 1.2fr max-content max-content
  +from-to($mm1, 414.4px)
    grid-template-areas: 'w w  w r r' 'a q q q d'
  +from-to(414px, 553.4px)

    grid-template-areas: 'w w  w r r' 'a q e e d'
  +from-to(553px, 990.4px)
    grid-template-areas: 'q q  w r r' 'a e e e d'

  +from-to($mm1, 990.4px)
    background-color: #363734
    padding: var(--minmax_8_15) var(--tip_offset)
    grid-gap: 4px var(--minmax_8_15)
  +from(990.4px)
    background-color: transparent
    grid-gap: 0
    grid-template-columns: 100%
    grid-auto-rows: max-content

  &.fixed
    background-color: var(--cc3)
    padding: var(--minmax_8_15)



  @import header_contain
  @import header_bottom
  @import header_cart
  @import header_vertical_menu
  @import header_menu

  .nav-responsive
    +from-to($mm1, 990.4px)
      display: contents !important
      margin: 0 !important
      min-width: 44px
      .expandable
        width: 100%
        height: 100%
        margin: 0 !important
      .main-navigation
        width: fit-content
//
// &_logo
//   grid-area: q
//
// &_search
//   grid-area: e
//
// &_content
//   grid-area: w
//
// &_language
//   grid-area: r
//
// +from(990.4px)
//   &_cart
//     grid-area: d
//   #res-menu
//     grid-area: a
