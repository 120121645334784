samp.text-clip
  --calc_cap: calc((var(--var_lh) - var(--var_cap)) * -.5)
  display: grid
  grid-template: fit-content(var(--cap, var(--var_cap, 100%))) / fit-content(100%)
  > span
    pointer-events: none
    display: flex
    color: inherit
    font-size: var(--fz, var(--var_fz, inherit)) !important
    line-height: var(--lh, var(--var_lh, 20px))
    height: max-content
    white-space: break-spaces
    margin-block: calc(var(--calc_cap) * -1)