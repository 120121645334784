&_list
  --list_col: 1
  --list_gap: var(--minmax_8_15)
  --list_rGap_var: var(--list_rGap, var(--list_gap))
  --list_cGap_var: var(--list_cGap, var(--list_gap))
  --list_columns: calc((100% - var(--list_cGap_var, 0) * (var(--list_col, 1) - 1)) / var(--list_col, 1))

  width: 100%
  max-width: 100%
  display: grid !important
  grid-template-columns: repeat(var(--list_col, 1), var(--list_columns, 100%))
  gap: var(--list_rGap_var, 0) var(--list_cGap_var, 0)
  // margin-top: var(--minmax_8_15) !important
  margin-left: auto !important
  margin-right: auto !important
  &::before,
  &::after
    display: none !important

&_list &_item
  display: contents

&_list &_contain
  +from(520.4px)
    grid-template-columns: fit-content(44%) 1fr .5fr .35fr .15fr
    grid-template-rows: minmax(33px, max-content)
    grid-auto-columns: max-content
    min-width: 02
    align-content: start
    grid-gap: 8px var(--minmax_8_15)
    align-items: start
    grid-auto-rows: max-content
&_list
  +from-to($mm1, 568px - .3)
    --list_col: 1
  +from-to(568px, 912px - .3)
    --list_col: 2
  +from-to(912px, 1260px - .3)
    --list_col: 3
  +from(1260px)
    --list_col: 3


&_list &:not(.product-list) &_item
  &_addtocart
    width: 100% !important

&_list .product-list &_item
  &_image
    aspect-ratio: unset
  +from(568px)
    &_contain
      grid-column: 1/-1
      grid-template-columns: minmax(176px, 22%) 1fr .5fr .35fr .15fr
      grid-template-rows: minmax(25px, max-content)
      grid-auto-columns: max-content
      align-content: start
      grid-gap: 8px var(--minmax_8_15)
      align-items: start
      // grid-auto-rows: max-content

    &_image
      // grid-row: 1 / span 3
      grid-row: 1 / span 4


    &_caption,
    &_details
      display: contents !important

    &_heading
      align-self: center
      grid-column: 2 / 4
      grid-row: 1

    &_name
      font-family: var(--ff1-5)

    &_desc
      grid-row: 2
      grid-column: 2/5
      margin: 0

    &_addtocart
      align-self: end
      grid-column: 2
      // grid-row: 3
      grid-row: 4
      min-height: 36px

    &_price
      // grid-row: 1/2
      // grid-column: 4/-1
      // flex-direction: column
      // align-self: start
      justify-self: end
      grid-row: 3
      grid-column: 2/3
      align-content: flex-start


&_list .product-grid &_item

  &_heading
    margin-bottom: 8px
    // text-align: right
    // justify-content: end
  &_price
    justify-content: flex-start


// &_item
//
// &::before,
// &::after
//   display: none !important
// &_contain
//   width: unset !important


// &_list &_item.product-list &_contain
//   grid-template-columns: fit-content(22%) 1fr .5fr .35fr .15fr
//   grid-template-rows: minmax(25px, max-content)
//   grid-auto-columns: max-content
//   align-content: start
//   grid-gap: 8px
//   align-items: start
//   grid-auto-rows: max-content
//
// &.product-list &_image
//   // grid-row: 1 / span 3
//   grid-row: 1 / span 4
//
// &.product-list &_caption,
// &.product-list &_details
//   display: contents !important

+from(568px)
  &.product-list &_addtocart
    grid-column: 2
    // grid-row: 3
    grid-row: 4
    min-height: 40px

  &.product-list &_price
    // grid-row: 1/2
    // grid-column: 4/-1
    // flex-direction: column
    // align-self: start
    justify-self: end
    grid-row: 3
    grid-column: 2/3
    align-content: flex-start

&.product-grid &_price
  justify-content: flex-start