.main-slider
  // --MS_ar_default: 1200/322
  // aspect-ratio: var(--MS_ar_local, var(--MD_ar_global, var(--MS_ar_default)))
  // background-color: var(--cc1)
  // width: var(--main_slider_width, 100%)
  display: grid
  grid-template-columns: 100%
  justify-items: end
  +from(990.4px)
    border-radius: var(--MS_bdrs, var(--bdrs-8))
  #spinner
    pointer-events: none
    background-color: rgb(var(--rr1) / 30%)
    backdrop-filter: blur(1px)
    position: static
  #spinner,
  .swiper-viewport
    grid-column: 1
    grid-row: 1
    min-height: 0
    height: auto


  #spinner,
  .swiper-viewport,
  .swiper-container,
  .swiper-slide,
  .swiper-slide a, div, span, img
    border-radius: inherit



.template_container.homw .main-slider
  +from(990px)
    display: none !important
