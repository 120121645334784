%sort-label
  position: relative
  font-size: 14px
  font-family: var(--ff1-5)
  line-height: 1
  &[class]
    color: #777

.new-select

  display: grid
  grid-template-columns: max-content fit-content(180px)
  grid-auto-rows: max-content
  gap: 5px 10px
  align-items: baseline
  position: relative
  &__label
    flex: 0 1
    margin: 0
    @extend %sort-label


  select
    position: absolute !important
    inset: 0
    height: unset !important
    width: unset !important
    border: none !important
    // opacity: 0

  &__select.customSelect
    height: unset
    border: none
    display: flex !important
    background-color: var(--dd1) !important
    box-shadow: 0 0 5px -3px rgba(0, 0, 0, 0.5)
    border-radius: var(--bdrs-4)
    padding: 5px 0 5px 10px
    width: fit-content
    font-size: 14px
    font-family: var(--ff1-5)
    line-height: 1.7
    .customSelectInner
      height: 100%
      width: calc(100% + 27px) !important
      padding-right: 22px
      background-size: 17px 17px
      background-image: url("data:image/svg+xml;utf8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20opacity%3D%220.6%22%3E%3Cg%20clip-path%3D%22url(%23clip0_375_12445)%22%3E%3Cg%20clip-path%3D%22url(%23clip1_375_12445)%22%3E%3Cpath%20d%3D%22M7%209.99988L12%2014.9999L17%209.99988%22%20stroke%3D%22%23363E49%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3Cdefs%3E%3CclipPath%20id%3D%22clip0_375_12445%22%3E%3Crect%20width%3D%2224%22%20height%3D%2224%22%20rx%3D%223%22%20fill%3D%22white%22%2F%3E%3C%2FclipPath%3E%3CclipPath%20id%3D%22clip1_375_12445%22%3E%3Crect%20width%3D%2224%22%20height%3D%2224%22%20rx%3D%223%22%20fill%3D%22white%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3C%2Fsvg%3E%0A") !important
      background-position-x: calc(100% - 6px) !important
      background-position-y: 50% !important
      background-repeat: no-repeat !important



.new-limit
  display: flex
  gap: 5px 10px
  align-items: baseline
  &__label
    @extend %sort-label
    // margin-right: 11px
  &__row
    display: flex
    gap: var(--minmax_8_15)

  &__link
    flex-shrink: 0
    display: flex
    place-content: center
    padding-bottom: 5px
    @extend %sort-label
    &:not(&_current):hover
      border-bottom: 1.5px solid var(--hh1-2)

    &_current
      border-bottom: 1.5px dashed var(--hh1-2)
      &[class]
        color: var(--hh1)

