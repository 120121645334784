@import ../../_mixins
&_vertical_menu
  // background-color: var(--cc1)

  margin-inline: calc(var(--HS_offset) / -2) !important
  display: grid
  width: auto !important
  grid-template-columns: 100%
  grid-template-rows: minmax(var(--hb_height), auto) 1fr
  filter: drop-shadow(2px 5px 16px rgb(0 0 0 / 40%))
  +from-to($mm1, 990.4px)
    display: none !important
  .box-heading
    @extend .header_cart

    padding-inline: calc(var(--minmax_8_15) + var(--HS_offset) / 2 + 3px) !important
    display: flex
    align-items: center
    .dropdown-arrow
      order: -1
    &::before
      transition: 300ms
      order: 3
      margin-left: auto


  .box-content
    top: calc(100% - 1px)
    box-shadow: none
    padding-top: 0 !important
    padding-bottom: 0 !important
    visibility: visible !important
    pointer-events: none
    transform-origin: 50% 100% !important
    transition: 250ms, transform 350ms var(--trs21) !important
    transform: translateY(5%) scaleY(.97) !important
    .dropmenu
      display: grid
      grid-template-columns: 100%
      grid-auto-rows: max-content
      align-content: start
      background-color: transparent
      padding: 0 0 12px !important

      .top_level
        @include if-child-count(9, '.top_level')
          --top_lavel_fz: 15px
          --top_lavel_lh: 1.3
          --tl_pdblock_start: 6px
          --tl_pdblock_end: 8px
          --top_lavel_bgp-y: 8px
        a
          font-size: var(--top_lavel_fz, 15px) !important
          padding-block: var(--tl_pdblock, var(--tl_pdblock_start, 9px) var(--tl_pdblock_end, 7px)) !important
          padding-inline: var(--top_lavel_pdinline, var(--tl_pdinline_start, 29px) var(--tl_pdinline_end, 4px)) !important
          font-family: var(--ff1-3) !important
          font-weight: 300 !important
          line-height: var(--top_lavel_lh, 1.4) !important
          letter-spacing: 0.02em !important
          border-radius: var(--bdrs-4) !important
          background-position: 10px var(--top_lavel_bgp-y, 11px) !important
          &:hover
            background-color: var(--hh1)
  &:not(.open)
    .box-heading
      height: calc(100% + var(--HS_offset)) !important
      transform-origin: 100% 50% !important
    .box-heading:hover::before
      // transition: 250ms
      transform: translateY(4px)
    .box-content > *
      opacity: 0

  &.open
    .box-heading
      transform-origin: 50% 50% !important
      transition: 300ms, transform 500ms var(--trs121), height 275ms var(--trs121) 50ms, border-radius 200ms 100ms ease-out !important
      border-radius: var(--bdrs-8) var(--bdrs-8) 0 0 !important
      transform: translateY(calc(var(--HS_offset) / -1))
      height: calc(100% + var(--HS_offset) * 2 + 1px) !important
      box-shadow: none

      &::before
        transition: 250ms 100ms
        transform: scale(1, -1)
      &:hover + .box-content
        background-color: var(--hh1) !important
    .box-content
      transition: 300ms, transform 250ms var(--trs12) 55ms, border-radius 200ms 100ms ease-out !important
      box-shadow: none
      border-radius: 0 0 var(--bdrs-8) var(--bdrs-8)
      pointer-events: auto

      transform: translateY(0) scaleY(1) !important


