

.ui_btn
  all: unset
  box-sizing: border-box
  cursor: pointer
  appearance: auto
  display: inline-flex
  place-items: center
  place-content: center
  width: var(--width, fit-content) !important
  height: var(--height, fit-content) !important
  &_switcher
    position: relative
    transition: 300ms
    color: var(--dd2)
    &::before
      content: ''
      border: 1.36px solid transparent
      pointer-events: none
      border-radius: 0
      position: absolute
      top: 50%
      left: 50%
      width: 100%
      height: 100%
      transform: translate(-50%, -50%) scale(1.3)
      transition: 300ms
      opacity: 0



    &:hover
      &::before
        transform: translate(-50%, -50%) scale(var(--scale, 1.1))
        opacity: 1
        border-color: var(--hh1)
        border-radius: var(--bdrs-4)


  &_switcher &_icn
    transition: 300ms
    transform: scale(var(--scale, 1))
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
  &_switcher.active &_icn,
  &_switcher:hover &_icn
      color: var(--hh1)
  &_switcher:hover &_icn
    transform: scale(var(--scale, .7))