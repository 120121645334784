@import ../../_mixins
&-frame &-header,
&-frame &-footer
  grid-auto-columns: auto
  grid-gap: 5px clamp(10px, 10px + (20 - 10) * (100vw - 662.3px) / (1555 - 662.3), 20px)
  background-color: var(--dd3-3) !important
  border: none
  &:empty
    display: none
  &:not(:empty)
    display: grid

&-frame > &-close ~ &-contain &-header &-title,
&-frame &-header > *:only-child,
&-frame &-header > *:nth-child(n + 3),
&-frame &-footer > *
  grid-column: 1/-1
&-frame > &-close ~ &-contain &-header &-title,
&-frame &-header > *:only-child,
&-frame &-footer
  text-align: center
  justify-content: center

&-frame &-header
  padding-top: var(--mfp_header_inset_top_val)
  padding-bottom: var(--mfp_header_inset_bottom_val)
  min-height: var(--mfp_header_min_height, 0)


&-frame &-main
  padding-top: var(--mfp_main_inset_top_val)
  padding-bottom: var(--mfp_main_inset_bottom_val)
  margin-block: -1px

&-frame &-footer
  padding-top: var(--mfp_footer_inset_top_val)
  padding-bottom: var(--mfp_footer_inset_bottom_val)
  min-height: var(--mfp_footer_min_height, max-content)

&-frame &-title
  padding: 0
  color: var(--dd2-3)
  font-family: var(--ff1-6)
  font-size: clamp(25px, 25px + (30 - 25) * (100vw - 763.3px) / (1685 - 763.3), 30px) !important
  line-height: 1.1 !important
  text-transform: uppercase
  display: grid
  align-content: center
  grid-template-rows: 40px
  grid-auto-rows: max-content
  align-items: baseline
  &::before
    content: ''
    display: block
    height: 30px
  &::before,
  > span
    grid-column: 1
    grid-row: 1
  > span
    align-self: center
// font-style: italic

&-frame &-subtitle
  font-family: var(--ff1-5)
  font-size: clamp(16px, 16px + (22 - 16) * (100vw - 763.3px) / (1685 - 763.3), 22px) !important
  line-height: 1.1
  font-style: italic

&-frame &-main
  background-color: var(--mfp_main_bgc, var(--dd1, #fff))

&-frame &-actions
  display: flex
  width: 100%
  flex-wrap: wrap
  justify-content: space-around
  gap: 8px 10px
  > *
    @include if-child-count(3, '*', 1)
      width: 100%

&-frame &-btn
  background-color: transparent
  font-size: 11.5px
  line-height: 1
  font-family: var(--ff1-6)
  font-style: italic
  opacity: 1
  border-radius: var(--bdrs-4)

  padding: 5.5px 8px 4px
  text-shadow: .5px .5px 1px var(--dd3-2)
  letter-spacing: .03em
  text-transform: uppercase
  transition: 350ms
  border: 1px solid var(--cc1)
  &:not(.btn_close)
    background-color: var(--cc1)
    color: var(--dd1)
    &:hover
      background-color: var(--hh1-2)
      border-color: var(--hh1-2)

  &.btn_close
    text-shadow: .5px .5px 1px var(--dd3-4)
    border: none
    text-decoration: underline
    transition: 250ms, text-decoration-thickness 0.3s
    text-decoration-thickness: from-font
    text-decoration-style: double
    &:hover
      text-decoration-color: var(--hh1)
      text-decoration-thickness: 1.5px
      text-decoration-style: solid