&_alert
  position: sticky
  top: 0
  z-index: 10
  display: flex
  justify-content: center
  align-items: center
  padding: var(--mfp_frame_inset_ver) var(--mfp_frame_inset_hor)
  transform: translateY(var(--mfp_frame_inset_reverse_ver))
  margin: 0 var(--mfp_frame_inset_reverse_hor) var(--mfp_frame_inset_reverse_ver) !important
  border-radius: var(--mfp_frame_radius_size)
  background-color: rgba(#A94442, .3)
  backdrop-filter: blur(2px)
  border: 1px solid #A94442
  box-shadow: none
  &:empty
    display: none
  &_contain
    border-radius: var(--bdrs-4) !important
    margin: 0 !important
    width: 100%
    display: grid !important
    grid-template-columns: minmax(0px,1fr) max-content
    grid-auto-rows: max-content
    grid-gap: 10px
    align-content: start
    align-items: center