.wall_categories
  // --cw_tiitle_minh: min(46px, max(33px, 5vmin))
  --cw_tiitle_minh: 40px
  overflow: unset !important
  &__container
    padding: 0
  &__head
    --title_mar: 0 0 var(--minmax_15_25)
  // &__heading
    // border: none

  &__content
    +from-to($mm1, 568px - .3)
      --categories_wall_cols: 1
      --cw_fz: clamp(13px, 13px + (17 - 13) * (100vw - 320.3px) / (568 - 320.3), 17px)
      --cw_col_gap: 10px
    +from-to(568px, 912px - .3)
      --categories_wall_cols: 2
      --cw_fz: clamp(13px, 13px + (17 - 13) * (100vw - 320.3px) / (912 - 320.3), 17px)
      --cw_col_gap: 17.5px
    +from-to(912px, 1260px - .3)
      --categories_wall_cols: 3
      --cw_fz: 15px
      --cw_col_gap: 21.5px
    +from(1260px)
      --cw_fz: 19px
      --categories_wall_cols: 3
      --cw_col_gap: var(--minmax_8_15)
    --cw_row_gap: var(--cw_col_gap)


    --cw_cols_width: calc(100% - var(--cw_row_gap, 0px) * (var(--categories_wall_cols, 1) - 1))
    display: grid
    grid-template-columns: repeat(var(--categories_wall_cols,1 ), calc(var(--cw_cols_width, 100%) / var(--categories_wall_cols, 1)))
    grid-gap: var(--cw_col_gap, 0) var(--cw_row_gap, 0)
    margin: 0 !important
    width: 100%

    &_view
      display: contents

  &__item
    width: fit-content !important
    background-color: var(--dd1)
    box-shadow: 0 0 0 1px var(--dd3-3)
    padding: 10px
    border-radius: var(--bdrs-8)
    transition: 500ms
    &:hover
      border-radius: var(--bdrs-12)
      // background-color: rgb(var(--rr1) / 30%)
      // box-shadow: 3px 3px 10px rgb(var(--rd2)  / 7%), 5px 5px -10px rgb(var(--rd2)  / 7%)

    &_preview
      display: grid
      grid-template-columns: 100%
      grid-gap: 10px
      border-radius: var(--bdrs-8)

    &_figure
      width: auto
      margin-inline: -10px
      display: flex
      justify-content: center
      align-items: center
      border-radius: var(--bdrs-8)

    &_img
      border-radius: var(--bdrs-4)
      display: block
      max-width: 100%
      // width: min(100%, max(44vmin, 3.8vmin))
      width: 100%
      height: 100%
      background-size: contain
      border: none
      transition: 300ms ease-in 200ms
      mix-blend-mode: multiply

    &:hover &_img
      transition: 350ms ease-in-out
      transform: scale(.93)
    &_title
      min-height: var(--cw_tiitle_minh)
      background-color: var(--dd3-3)
      border: 1px solid var(--dd3-3)
      display: flex
      align-items: center
      border-radius: var(--bdrs-4)
      font-family: var(--ff1-5)
      font-size: var(--cw_fz)
      color: var(--dd2)
      line-height: 1
      letter-spacing: 0.01em
      gap: 2.5px
      justify-content: space-between
      padding: 5px 8px 5px 8px
      transition: 300ms linear
      &:hover
        transition: 300ms
        background-color: var(--hh1-2)
        border-color: var(--hh1-2) !important
        color: var(--dd1)
      svg, svg *
        transition: 300ms linear
      > span
        margin-left: auto
        margin-right: auto
        @extend %trimRow
        --trim: calc(var(--cw_tiitle_minh) - 8px - 5px)
      > svg
        width: calc(var(--cw_tiitle_minh) * .5)
        height: auto
        // margin-bottom: -.6px
    &:hover &_title
      border-radius: var(--bdrs-8)
      border-color: var(--hh1)
      svg, svg *
        fill: var(--hh1) !important
  // &__list
  //   &_child
  //   &_link
  //   &_btn_seemore
  //   &_btn_wall
  //   &_btn_wall_icn