// &_feature &_item &
//   &_contain


//&_item + &_item
  //padding-top: 20px
  //padding-bottom: 20px
  //border-bottom: 1px solid red
&_item
  &_overlay
    display: contents !important
  &_contain
    background-color: var(--dd3-5)
    border-radius: var(--bdrs-4)
    padding: var(--minmax_8_15)
    display: grid !important
    grid-template-columns: 100%
    grid-template-rows: max-content 1fr
    grid-gap: 20px 15px
    margin: 0 !important
    max-width: unset !important


  &_image
    width: 100% !important
    display: flex
    justify-content: center
    // height: 100%
    // aspect-ratio: 1
    background-color: #fff
    border-radius: var(--bdrs-4)
    align-items: center
    max-width: 100% !important

  &_figure
    display: contents !important
    width: 100% !important
    height: auto !important
    // display: flex !important
    justify-content: center !important
    align-items: center !important
    min-width: 100%
    min-height: 100%

    border-radius: var(--bdrs-4)
    box-shadow: 0 0 10px -4px rgba(0, 0, 0, .3)
  img
    width: 100% !important
    height: 100% !important
    object-fit: contain

    border-radius: var(--bdrs-4) !important


  &_caption
    padding: 0 !important
    display: flex !important
    flex-direction: column
    gap: 10px



  &_percentsaving
    filter: drop-shadow(0 0 5px rgb(176 80 22 / 60%))
    text-shadow: 1px 1px 0.7px rgb(0 0 0 / 10%)
    // padding: 1.5px 4px !important
    z-index: 30 !important
    aspect-ratio: 30/15
    line-height: 1.25
    font-family: var(--ff2-4)
    border-radius: 0!important
    border-bottom-width: 2px !important
    border-bottom-style: dashed !important
    text-indent: 4px
    background-color: unset !important

    right: 8px !important
    top: 4.5px !important

  &_heading
    padding-left: 8px !important
    padding-right: 8px !important
    overflow: unset !important
    display: flex !important
    justify-content: flex-start
    align-items: center

  &_name
    letter-spacing: 0 !important
    // @include trim(15, 10.25, 'force')
    font-family: var(--ff1-5) !important
    font-weight: 500 !important

  &.product-list &_name
    font-family: var(--ff1-5)


  &_details
    display: contents !important
    width: 100% !important
    max-width: 100% !important


  &_price

    padding-left: 8px !important
    padding-right: 8px !important
    margin: auto 0 0 !important
    display: flex !important
    gap: 3px var(--price_gap) !important
    align-items: baseline !important
    flex-wrap: wrap

    font-style: italic !important
    font-family: var(--ff1-7) !important
    line-height: 1 !important
    z-index: 2
    --fz: 24px
    --fz_currency: 14px
    --fz_old: 14px
    --line_height: 5px
    --distance: 3px
    --price_gap: 10px
    --color_old: #555
    --color_old-line: #d8c9c9

    &_new
      font-size: 24px !important
      color: var(--dd2)
      position: relative
      z-index: 2
      font-family: var(--ff1-7) !important
      margin: 0 !important
      text-shadow: 1px 1px 0.7px rgb(0 0 0 / 10%)
      &::before
        position: absolute
        z-index: -1
        bottom: calc(var(--line_height) * var(--line_scale, -.05))
        left: -5px
        width: calc(100% + 8px)
        height: var(--line_height)
        content: ''
        background-color: var(--color_old-line)

        // transform: skew(-15deg)
        border-radius: var(--line_height)

      &:nth-last-child(2)::before
        --color_old-line: rgb(var(--rr1) / 70%)

      > span
        font-size: var(--fz_currency)
        margin-left: var(--distance)
        text-transform: uppercase
    &_old
      font-family: var(--ff1-6) !important
      margin: 0 0 -7px !important
      font-size: var(--fz_old) !important
      text-decoration: line-through
      color: var(--color_old)
      text-shadow: 1px 1px 0.7px rgb(0 0 0 / 10%)




  &_desc
    padding-left: 8px
    padding-right: 8px
    text-align: left

  &_addtocart
    min-height: 36px
    display: flex !important
    align-items: center !important
    // margin-top: 5px

