@use "sass:list"
@use "sass:math"

// pseudo-elements
// =============================================================================

// before and after
@mixin ba($con:null)
  &::before,
  &::after
    @if ($con)
      content: $con
    @content

// before only
@mixin b($con:null)
  &::before
    @if ($con)
      content: $con
    @content

// after only
@mixin a($con:null)
  &::after
    @if ($con)
      content: $con
    @content

// placeholder form
@mixin plc($color: currentColor)
  &::placeholder
    color: $color
    @content

// sets styles for replicas (subtitles)
@mixin cue($bgc: rgb(0 0 0 / 6%), $color: #fff)
  ::cue
    background-color: $bgc
    color: $color
    @content

// selected text or element
@mixin sel($bgc: #000, $color: #fff)
  &::selection
    background-color: $bgc
    color: $color
    @content

@mixin fade-in()
  animation: fade-in 0.5s ease-out

@keyframes fade-in
  0%
    opacity: 0

  50%
    opacity: 0.5

  100%
    opacity: 1



