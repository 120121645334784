&_bottom

  display: contents

  --hb_height: 30px
  +from(990.4px)
    --hb_height: 50px
    --verticalmenu: calc(var(--verticalmenu_width) + var(--hb_height) / 2 - var(--HS_offset) / 2)
    display: grid

  &.common-home
    grid-template-columns: 100%
    grid-template-rows: var(--hb_height)
    background-color: transparent
    &::before
      +from(990.4px)
        content: ''
        grid-column: 1
        grid-row: 1/2
        background-color: darken(#363734, 2.1%)
        width: 100%
        box-shadow: 0 4px 3px -1px rgb(0 0 0 / 15%)
        position: relative
        z-index: 1

  &:not(.common-home)
    padding: 0 var(--tip_offset)
    background-color: darken(#363734, 2.1%)
    grid-template-columns: minmax(0, var(--verticalmenu)) 1fr max-content
    grid-template-rows: var(--hb_height)

  &:not(&.common-home) &_overlay
    display: contents
  &.common-home &_overlay
    +from(990.4px)
      grid-column: 1
      grid-row: 1/ span 2

      padding: 0 var(--tip_offset)
      display: grid
      grid-template-columns: minmax(0, var(--verticalmenu)) 1fr max-content
      grid-template-rows: var(--hb_height) max-content
    +from-to($mm1, 990.4px)
      display: contents !important
    #verticalmenublock
      grid-row: span 2
      z-index: 6

      .box-content
        position: static
      ~ .main-slider
        +from-to($mm1, 990.4px)
          display: none !important

    .main-slider
      grid-row: 2
      grid-column: 2/-1
      --MS_bdrs: 0 0 var(--bdrs-8) var(--bdrs-8)
      background-color: rgba(var(--rr1) / 30%)
      background-clip: content-box
      border-radius: var(--MS_bdrs) !important
      position: relative
      +from(990.4px)
        margin-left: calc(var(--HS_offset) / -1) !important
        margin-bottom: var(--HS_offset) !important



      .swiper,
      .swiper-wrapper,
      .swiper-slide
        +from(990.4px)
          height: 100% !important
    .swiper-slide img
      object-position: right bottom
    #spinner
      z-index: 4
    .swiper-viewport
      z-index: 3

  &_overlay
    > *
      width: unset !important
      margin: 0 !important


.nav.navbar-nav,
.header-cart
  height: 100%
.nav.navbar-nav
  display: flex
  align-items: center
.main-menu ul > li > a, .hiden_menu li > a
  padding: 0
  display: flex
  align-items: center
  justify-content: center

