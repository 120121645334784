&-frame

  --mfp_frame_offset_size: 3vmin
  --mfp_frame_inset_ver: min(3vmin, 18px)
  --mfp_frame_inset_hor: min(5.8024vmin, 25px)

  --mfp_frame_radius_size: var(--bdrs-12)

  --mfp_header_min_height: 62px
  --mfp_header_inset_size: min(2vmin, 10px)

  // --mfp_footer_min_height: 60px

  --mfp_footer_inset_top: min(2vmin, 13px)
  --mfp_footer_inset_bottom: min(2.5vmin, 13px)

  --mfp_outside_close_btn_size: var(--mfp_header_min_height)
  --mfp_inside_close_btn_size: 30px
  --mfp_inside_close_btn_bdrs: var(--bdrs-4)

  //__calculate__
  --mfp_frame_offset_top_val: var(--mfp_frame_offset_top, var(--mfp_frame_offset_ver, var(--mfp_frame_offset_size)))
  --mfp_frame_offset_right_val: var(--mfp_frame_offset_right, var(--mfp_frame_offset_hor, var(--mfp_frame_offset_size)))
  --mfp_frame_offset_bottom_val: var(--mfp_frame_offset_bottom, var(--mfp_frame_offset_ver, var(--mfp_frame_offset_size)))
  --mfp_frame_offset_left_val: var(--mfp_frame_offset_left, var(--mfp_frame_offset_hor, var(--mfp_frame_offset_size)))

  // --mfp_frame_width_val: var(--mfp_frame_width, var(--mfp_frame_size))
  // --mfp_frame_height_val: var(--mfp_frame_height, var(--mfp_frame_size))


  --mfp_frame_inset_top_val: var(--mfp_frame_inset_top, var(--mfp_frame_inset_ver, var(--mfp_frame_inset_size)))
  --mfp_frame_inset_right_val: var(--mfp_frame_inset_right, var(--mfp_frame_inset_hor, var(--mfp_frame_inset_size)))
  --mfp_frame_inset_bottom_val: var(--mfp_frame_inset_bottom, var(--mfp_frame_inset_ver, var(--mfp_frame_inset_size)))
  --mfp_frame_inset_left_val: var(--mfp_frame_inset_left, var(--mfp_frame_inset_hor, var(--mfp_frame_inset_size)))



  --mfp_outside_close_btn_width_val: var(--mfp_outside_close_btn_width, var(--mfp_outside_close_btn_size))
  --mfp_outside_close_btn_height_val: var(--mfp_outside_close_btn_height, var(--mfp_outside_close_btn_size))

  --mfp_inside_close_btn_width_val: var(--mfp_inside_close_btn_width, var(--mfp_inside_close_btn_size))
  --mfp_inside_close_btn_height_val: var(--mfp_inside_close_btn_height, var(--mfp_inside_close_btn_size))

  --mfp_header_inset_top_val: var(--mfp_header_inset_top, var(--mfp_header_inset_size, var(--mfp_frame_inset_top_val)))
  --mfp_header_inset_bottom_val: var(--mfp_header_inset_bottom, var(--mfp_header_inset_size, var(--mfp_frame_inset_bottom_val)))
  --mfp_main_inset_top_val: var(--mfp_main_inset_top, var(--mfp_main_inset_size, var(--mfp_frame_inset_top_val)))
  --mfp_main_inset_bottom_val: var(--mfp_main_inset_bottom, var(--mfp_main_inset_size, var(--mfp_frame_inset_bottom_val)))
  --mfp_footer_inset_top_val: var(--mfp_footer_inset_top, var(--mfp_footer_inset_size, var(--mfp_frame_inset_top_val)))
  --mfp_footer_inset_bottom_val: var(--mfp_footer_inset_bottom, var(--mfp_footer_inset_size, var(--mfp_frame_inset_bottom_val)))

  pointer-events: auto
  grid-template-columns: var(--mfp_frame_columns, 100%)
  grid-template-rows: var(--mfp_frame_rows, max-content 1fr max-content)
  grid-auto-rows: max-content

  filter: drop-shadow(-2px 2px 6px rgba(0, 0, 0, 0.15))

  display: grid !important
  background-color: var(--mfp_frame_bgc, transparent) !important
  max-width: var(--mfp_frame_max_width, 100%) !important
  max-height: var(--mfp_frame_max_height, -webkit-fill-available) !important
  overflow: unset !important
  position: relative !important

  width: var(--mfp_frame_width_val, fit-content)
  height: var(--mfp_frame_height_val, max-content)


  min-height: var(--mfp_frame_min_height, 67vh)
  @supports (min-height: 100svh)
    min-height: var(--mfp_frame_min_height, 67svh)

  place-self: center
  margin: var(--mfp_frame_offset_top_val) var(--mfp_frame_offset_right_val) var(--mfp_frame_offset_bottom_val) var(--mfp_frame_offset_left_val)

  &:not(.mfp_full_size):not(.mfp_full_width)
    --mfp_frame_width_val: min(max(80vw, 80vmin), var(--mfp_frame_width, var(--mfp_frame_size, 545px)))

  &:not(.close_btn_left):not(.mfp_right)
    --mfp_frame_offset_right: calc(var(--mfp_frame_offset_top_val) / 2 + var(--mfp_outside_close_btn_width_val))

  &.mfp_full_size,
  &.mfp_full_width
    --mfp_frame_width_val: min(max(80vw, 80vmin), calc(var(--mfp_frame_width, var(--mfp_frame_size, 100%)) - var(--mfp_frame_offset_left_val) - var(--mfp_frame_offset_right_val)))

  &.close_btn_left,
  &.mfp_right
    --mfp_frame_offset_left: calc(var(--mfp_frame_offset_top_val) / 2 + var(--mfp_outside_close_btn_width_val))


  &:not(.mfp_full_size):not(.mfp_full_height)
    --mfp_frame_height_val: var(--mfp_frame_height, var(--mfp_frame_size, max-content))

  &.mfp_full_size,
  &.mfp_full_height
    --mfp_frame_height_val: calc(100% - var(--mfp_frame_height, var(--mfp_frame_size, 0px)) - var(--mfp_frame_offset_top_val) - var(--mfp_frame_offset_bottom_val))

  &.mfp_full_width
    justify-self: stretch
  &.mfp_full_height
    align-self: stretch
  &.mfp_full_size
    justify-self: stretch
    align-self: stretch
  &.mfp_top
    align-self: start
  &.mfp_bottom
    align-self: end
  &.mfp_left
    justify-self: start
  &.mfp_right
    justify-self: end


&-frame,
&-frame > &-close,
&-frame &-contain,
&-frame &-header,
&-frame &-main,
&-frame &-footer
  --mfp_frame_top_left_radius_val: var(--mfp_frame_top_left_radius, var(--mfp_frame_radius_size))
  --mfp_frame_top_right_radius_val: var(--mfp_frame_top_right_radius, var(--mfp_frame_radius_size))
  --mfp_frame_bot_right_radius_val: var(--mfp_frame_bot_right_radius, var(--mfp_frame_radius_size))
  --mfp_frame_bot_left_radius_val: var(--mfp_frame_bot_left_radius, var(--mfp_frame_radius_size))
  border-radius: var(--mfp_frame_top_left_radius_val) var(--mfp_frame_top_right_radius_val) var(--mfp_frame_bot_right_radius_val) var(--mfp_frame_bot_left_radius_val) !important

&-frame &-contain
  display: contents
  > *
    margin: 0

&-frame &-main,
&-frame &-footer
  +from-to($mm1, $mm4 - .3)
    margin-left: -47px

&-frame &-header,
&-frame &-main,
&-frame &-footer
  padding-left: var(--mfp_frame_inset_left_val)
  padding-right: var(--mfp_frame_inset_right_val)
  &::before,
  &::after
    display: none


&-frame &-header
  grid-template-columns: 1fr max-content


&-frame &-footer
  --mfp_frame_top_right_radius: 0
  --mfp_frame_top_left_radius: 0

&-frame &-close
  --mfp_close_btn_bgc: var(--dd2-3)
  --mfp_close_btn_color: var(--dd3-3)
  --mfp_close_btn_bdrs: var(--bdrs-4)

  all: unset
  appearance: auto
  box-sizing: border-box
  cursor: pointer
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  line-height: 1
  font-family: var(--ff1-4)
  font-size: 34px
  transition: 250ms linear

  background-color: var(--mfp_close_btn_bgc)
  color: var(--mfp_close_btn_color)
  > *
    transition: 250ms linear
  &:hover
    --mfp_close_btn_bgc: var(--dd1)
    --mfp_close_btn_color: var(--dd2-3)
    --mfp_close_btn_bdrs: 8px

    box-shadow: 0 0 8px -2px rgba(0 0 0 / 30%)

&-frame > &-close
  --mfp_close_btn_bgc: var(--dd3-3)
  --mfp_close_btn_color: var(--dd2-3)

  width: var(--mfp_outside_close_btn_width_val)
  height: var(--mfp_outside_close_btn_height_val)
  font-style: italic
  position: absolute
  top: 0
  opacity: 1 !important
  > *
    transform: skew(-10deg)
  &:hover
    --mfp_close_btn_bgc: var(--dd3-4)
    box-shadow: inset -5px 0px 8px -3px rgba(0 0 0 / 30%)

&-frame:not(.close_btn_left):not(.mfp_right) > &-close
  --mfp_frame_top_left_radius: 0px
  --mfp_frame_bot_left_radius: 0px
  right: unset
  left: calc(100% - .5px)


&-frame.mfp_right > &-close,
&-frame.close_btn_left > &-close
  --mfp_frame_top_right_radius: 0
  --mfp_frame_bot_right_radius: 0
  right: calc(100% - .5px)
  left: unset

&-frame &-contain &-header ~ &-main
  --mfp_frame_top_right_radius: 0
  --mfp_frame_top_left_radius: 0

&-frame &-header,
&-frame &-contain >*:nth-last-of-type(2)
  --mfp_frame_bot_right_radius: 0
  --mfp_frame_bot_left_radius: 0

&-frame:not(.close_btn_left):not(.mfp_right) > &-close  ~ &-contain &-header
  --mfp_frame_top_right_radius: 0

&-frame.mfp_right > &-close ~ &-contain &-header,
&-frame.close_btn_left > &-close ~ &-contain &-header
  --mfp_frame_top_left_radius: 0

&-frame > &-close ~ &-contain &-close
  display: none

&-frame &-header &-close
  align-self: start
  width: var(--mfp_inside_close_btn_width_val) !important
  height: var(--mfp_inside_close_btn_height_val) !important
  margin:
    top: var(--mfp_inside_close_btn_mar_top, var(--mfp_inside_close_btn_mar, 9px))
    right: var(--mfp_inside_close_btn_mar_rig, var(--mfp_inside_close_btn_mar, 0))
    bottom: var(--mfp_inside_close_btn_mar_bot, var(--mfp_inside_close_btn_mar, 0))
    left: var(--mfp_inside_close_btn_mar_left, var(--mfp_inside_close_btn_mar, 0))

  border-radius: var(--mfp_inside_close_btn_bdrs)
  &:hover
    > *
      transform: scale(.8)

&-frame &-main
  box-sizing: content-box
  overflow: var(--mfp_main_overflow, auto) !important
  // min-width: