&_btn
  all: unset
  cursor: pointer
  appearance: auto
  box-sizing: border-box
  line-height: 1
  display: flex
  align-items: center
  justify-content: center
  max-width: 100%
  transition: 250ms linear !important
  text-align: center
  &_icn
    display: flex
    align-items: center
    justify-content: center
    transition: 250ms linear
    > *
      width: 100%
      height: 100%
      display: block
      max-width: 100%
      object-fit: contain

  &_alert
    align-self: start
    transform: translate(8px, -11px)
  &_remove
    border-radius: var(--bdrs-4)
    background-color: var(--dd3-3)
    width: 30px
    height: 30px
    &:hover
      color: var(--dd1)
      background-color: var(--hh1-2)
      border-radius: 8px
      box-shadow: 0 0 8px -2px rgba(0 0 0 / 30%)

  &_remove &_icn
    width: 20px
    height: 20px
  &_remove:hover &_icn
    transform: scale(0.8)
  &_quantity
    font-size: 14px
    padding: 3px
    align-self: center
    justify-self: center
    border-radius: var(--bdrs-4) !important

    &_decrease
      border-radius: var(--bdrs-4) 0 0 var(--bdrs-4) !important
      order: -2
      &[data-quantity-value="1"]
        pointer-events: none
    &_increase
      border-radius: 0 var(--bdrs-4) var(--bdrs-4) 0 !important
      &:hover
        box-shadow: 0 0 0 1px var(--hh1-2) !important
        + input
          border-radius: var(--bdrs-8) 0 0 var(--bdrs-8) !important
          box-shadow: 0 0 0 1px var(--hh1-2), 0 0 3px -1px var(--hh1-2) !important




    &_increase:hover,
    &_decrease:not([data-quantity-value="1"]):hover
      color: var(--dd1)
      background-color: var(--hh1-2)

    &_decrease:not([data-quantity-value="1"])
      &:hover
        box-shadow: 0 0 0 1px var(--hh1-2) !important
        ~ input
          border-radius: 0 var(--bdrs-8) var(--bdrs-8) 0 !important
          box-shadow: 0 0 0 1px var(--hh1-2), 0 0 3px -1px var(--hh1-2) !important

    &_decrease[data-quantity-value="1"]:hover
      background-color: var(--dd3-4)


  &_order
    border-radius: var(--bdrs-4)
    background-color: var(--cc1)
    border: 1px solid var(--cc1)
    +from-to($mm1, $mm5 - .3)
      font-size: 12px
    +mm5
      font-size: 16px
    font-family: var(--ff1-5)
    line-height: 1
    padding: 5.5px 16px 4px
    text-transform: uppercase
    transition: all 500ms ease 0s
    min-height: 34px
    &:hover
      background-color: var(--hh1-2)
      border-color: var(--hh1-2)
      color: var(--dd1)