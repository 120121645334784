.btn-list-grid
  padding-top: 4px !important

.btn:not(.ui_btn)
  font-size: 15px !important
  font-family: var(--ff1-5) !important
  line-height: 1 !important
  text-transform: uppercase !important
  display: flex !important
  align-items: center !important
  align-content: center !important
  justify-content: center !important
  padding: 4px 12px !important
  border: none !important

  gap: 8px






.owl-carousel
  background-color: var(--dd1)
  margin: calc(var(--minmax_8_15) / -2) !important
  width: auto !important
  height: auto !important
.owl-carousel .owl-wrapper
  display: flex !important

.owl-carousel .owl-wrapper .owl-item
  display: flex !important
  flex-direction: column
  height: auto !important
  > *
    flex-grow: 1



  .double-slideitem
    margin: calc(var(--minmax_8_15) / 2) !important
    display: grid
    grid-template-columns: 100%
    //grid-template-rows: minmax(0, 150px)  minmax(0, 150px)
    //grid-template-rows: fit-content(50%) fit-content(50%)
    grid-gap: var(--minmax_8_15)
    align-content: stretch
    background-color: #fff !important

    >
      .product_item_contain
        +from-to($mm1, $mm8 - .3)
          grid-template-rows: 1fr .5fr .5fr 36px !important
        +from($mm8)
          grid-template-rows: 1fr .8fr 36px !important
        .product_item_image
          grid-row: span 3

        .product_item_heading
          +from-to($mm1, $mm8 - .3)
            grid-row: 1 / span 2
        .product_item_price
          +from-to($mm1, $mm8 - .3)
            grid-row: 3
        .product_btn_addtocart
          height: 36px

          +from-to($mm1, $mm8 - .3)
            grid-column: 1/-1
            grid-row: 4
          +from($mm8)
            grid-row: 3
            grid-column: 2/-1
        &:only-child
          grid-row: span 2
          grid-template-columns: minmax(auto,1fr) minmax(auto,.6fr)
          //grid-gap: 10px !important
          grid-template-rows: 1fr 0.3fr 36px!important

          min-height: 0
          min-width: 0
          max-width: 100%
          
          
          // grid-auto-rows: max-content !important
          // aspect-ratio: 375/440
          // height: 100%
          *
            grid-column: 1/-1
          *:not(.product_item_price):not(.product_btn_addtocart)
            grid-row: unset !important
          .product_item_figure
            display: contents !important
            img:not(._hover)
              width: auto !important
              height: auto !important
            img

          .product_item_name
            @include trim(15, 8, 'force')

          .product_item_heading
            padding: 3px 13px 10px !important

          .product_item_price,
          .product_btn_addtocart
            grid-row: 3
          .product_item_price
            gap: 13px !important
            padding: 6px 13px !important
            flex-direction: row-reverse
            align-items: baseline !important
            align-content: baseline
            grid-column: 1
          .product_btn_addtocart
            grid-column: 2


#products-related
  padding-left: 15px
  padding-right: 15px

#footer
  h5,
  .h5
    font-size: 19px
    line-height: 1.1
  .column li
    font-size: 15px
    line-height: 1.2


#search.header-search .btn-lg
  gap: 5px


.smcart_item_quantity_contain
  +from-to($mm1, $mm5 - .3)
    border-radius:  var(--bdrs-4) !important


.tooltip .tooltip-inner
  font-size: 12px
  border-radius: var(--bdrs-12)
  min-height: 24px !important
  width: fit-content !important
  padding-bottom:  5px

.tooltip.top .tooltip-arrow
  bottom: 1px
  transform: scale(1.5)


#czoffercmsblock,
#czsubbannercmsblock
  .container
    all: unset !important


#czsubbannercmsblock .subbanners
  margin: 0
  display: grid
  +from-to($mm1, 568px - .3)
    grid-template-columns: 100%
  +from(568px)
    grid-template-columns: 1fr 1fr
  min-width: 0
  grid-gap: calc(var(--minmax_20_35) / 2 + 5px) calc(var(--minmax_20_35) / 2)
  > *
    padding: 0 !important
    margin: 0 !important
    width: 100% !important
    > *
      border-radius: var(--bdrs-8) !important


.common-home #content
  padding-bottom: 0


#czoffercmsblock
  .offercms-inner
    display: grid
    grid-template-columns: 100%
    border-radius: var(--bdrs-8)
    overflow: hidden
    > *
      grid-row: 1
      grid-column: 1
      position: static
    .offercmsdetail
      background-color: #b2bd3a
      border-radius: var(--bdrs-8)
  .offercmsdetail
    display: flex
    flex-direction: column
    > *
      height: auto !important
      flex-grow: 1
      display: grid
      grid-template-columns: 100%
      border-radius: var(--bdrs-8)
      +mm2
        grid-template-columns: 1fr .3fr
        grid-auto-rows: max-content 1fr
      grid-gap: calc(var(--minmax_15_25) / 2) var(--minmax_15_25)
      *
        line-height: 1.5

    .shopnow
      position: static
      place-self: center center
      +mm2
        grid-column: 2
        grid-row: 1
      +mm6
        grid-column: 2
        grid-row: 1 /span 2
    .offer-subtitle
      +from-to($mm2, $mm6 - .3)
        grid-column: 1/-1


.price-cartbox,
.form-group.qty
  display: grid !important
  grid-template-columns: 100%
  grid-auto-rows: max-content
  grid-gap: calc(var(--minmax_8_15)/2) var(--minmax_8_15)
  > *
    margin: 0 !important
    width: unset !important


.form-group.qty
  grid-template-columns: 80px  minmax(auto, 200px) 36px max-content
  align-items: center
  margin-bottom: calc(var(--minmax_8_15) / 2)
  [name="quantity"],
  #button-cart
    align-self: stretch
    border-radius: var(--bdrs-8)
    text-align: center
  .control-label
    grid-column: 1/-1

#product .category_sortbar
  grid-template-columns: 100%
  margin-bottom: var(--minmax_15_30)

#product .product_item_price_new
  font-size: var(--size-fluid-5) !important


#product .product_item_price_old
  --fz_old: 16px !important
  --fz_currency: 16px !important

.zoomContainer
  margin: 0 !important
  border-radius: var(--bdrs-8)
  overflow: hidden !important


.product-info
  .product-image
    display: grid
    border-radius: var(--bdrs-8)
    grid-template-columns: 100%
    grid-gap: calc(var(--minmax_8_15) / 2)
    border: calc(var(--minmax_8_15) / 2) solid var(--dd3-5)
    background-color: var(--dd3-5)

    > *,
    .thumbnail
      margin: 0 !important
      padding: 0 !important
      border-radius: var(--bdrs-8) !important
      overflow: hidden !important
      max-width: 100%
    &.thumbnails
      grid-template-rows: 1fr auto

    .thumbnail img
      width: 100%
      border-radius: var(--bdrs-8)

    .image
      width: 100% !important
      // +to(767px)
      //   width: 100%
    .additional-carousel
      width: 100% !important
      display: grid
      grid-gap: calc(var(--minmax_8_15) / 2)
      grid-template-columns: 30px minmax(0, 1fr) 30px
      &.not_slider
        grid-template-columns: 100%
        border-radius: var(--bdrs-4) !important

    .customNavigation
      display: contents !important

      > *
        position: unset !important
        height: 100% !important
        width: 100% !important
        display: flex
        justify-content: center
        align-items: center
        border-radius: var(--bdrs-4) !important

      .arrow-left
        order: 1

      .arrow-right
        order: 3

    .image-additional
      order: 2
      grid-row: 1
      grid-column: 2
      width: 100% !important
      height: 100% !important
      min-width: 0
      margin: 0
      padding: 0
    .not_slider .image-additional
      grid-row: 1
      grid-column: 1


    .slider-item
      width: auto !important
    .product-block
      height: 100% !important

    .slider-wrapper-outer
      border-radius: var(--bdrs-4) !important
    .not_slider .image-additional
      overflow: auto hidden

    // .slider-wrapper

      // grid-template-columns: repeat(var(--cols, 1), )
    .slider-wrapper,
    .not_slider .image-additional
      display: grid !important
      --cols: 3
      --col_gap: calc(var(--minmax_8_15) / 2)
      --calc_cols: calc(var(--cols, 1) - 1)
      --calc_width: calc(100% - var(--col_gap) * var(--calc_cols))
      // grid-template-rows: 120px
      // grid-template-rows: minmax(95px, 131px)
      grid-auto-columns: calc(var(--calc_width, 100%) / var(--cols, 1))
      grid-auto-rows: minmax(95px, 132px)

      grid-auto-flow: column
      max-width: 100%
      width: 100% !important
      min-width: 0
      grid-gap: calc(var(--minmax_8_15) / 2)
      *
        margin: 0 !important
    .product-block
      width: 100% !important
      height: 100% !important
      border-radius: var(--bdrs-4) !important
      border: none !important
      .elevatezoom-gallery
        display: flex
        justify-content: center
        align-items: center
        width: 100% !important
        height: 100% !important
      img
        width: 100% !important
        height: 100% !important
        border-radius: var(--bdrs-4) !important
        object-fit: contain
        background-color: var(--dd1) !important



.bottomfooter_contain
  display: grid
  grid-template-columns: max-content 1fr !important
  align-items: center
  .footerbottom,
  .footerpowered.text
    display: contents !important
    > *
      margin: 0
      position: 0
  .text > p:last-child
    font-family: var(--ff1-6)
    letter-spacing: .03em
    font-size: clamp(12px, 12px + (14 - 12) * (100vw - 470.3px) / (1280 - 470.3), 14px)

  .social-block
    > *, li
      padding: 0 !important
      margin: 0 !important
      line-height: 1 !important