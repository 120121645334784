.ui_title
  --title_fz: var(--size-fluid-5)


  --title_lh: 1
  --title_color: var(--title_dd2)
  --title_line_size: 7px
  color: var(--title_color, var(--cc3)) !important
  font-size: var(--title_fz)

  font-family: var(--title_ff, var(--ff1-7))
  font-style: var(--font-style, inherit)
  line-height: var(--title_lh, 1.2) !important


  text-align: var(--title_align, left)
  margin: var(--title_mar, 0) !important
  padding: var(--title_pad, 0) !important
  text-transform: var(--title_tt, uppercase) !important
  width: fit-content
  --bxs_y: -7px
  --bxs_val: var(--bxs_x, 0) var(--bxs_y, 0) var(--bxs_b, 0) var(--bxs_l, 0)
  --bxs_var: var(--bxs_type, inset) var(--bxs_val) var(--bxs_fill, rgb(153 153 153 / 50%))
  box-shadow: var(--title_bxs, var(--bxs_var, none))

  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6
    all: unset
    box-sizing: border-box
    appearance: auto
    margin: 0

  > *
    color: inherit
    text-transform: inherit !important
    font-size: inherit
    font-weight: inherit
    line-height: inherit
    margin: var(--title_inner_mar, 0) !important
    padding: var(--title_innder_pad, 0) !important