.breadcrumbs
  &_contain
    display: grid
    grid-auto-columns: max-content
    grid-auto-flow: column
    grid-gap: 10px 26px
    align-items: baseline
    overflow: auto hidden
    overflow-clip-margin: 15px
    @extend %scrollbar_hide
  &_link
    display: inline-flex
    font-size: 14px
    position: relative
    transition: 250ms, text-decoration-thickness 0.3s
    &:not(.is_current)
      color: #777
      font-family: var(--ff1-3)
      text-decoration: underline
      text-decoration-thickness: 1.5px
      text-decoration-color: transparent
      &:hover
        // text-shadow: 0.5px 0.5px .5px var(--dd3-4)

        text-decoration-color: var(--hh1)

        text-decoration-style: solid
      &::after
        content: '/'
        position: absolute
        top: 0
        left: calc(100% + 26px / 3)
        text-shadow: none
    &.is_current
      color: var(--dd2)
      font-family: var(--ff1-5)


  // align-items: center
  // overflow: auto hidden
  // overflow-clip-margin: 10px
  // > li + li:before
  //   padding: 2px 0 0
  //   height: 20px
  //   display: flex
  //   align-items: center
  //   justify-content: center
  //   font-size: 20px
  //   font-family: var(--ff1-3)
  //
  // @extend %scrollbar_hide
  //
  // i
  //   color: currentColor !important
  // li
  //   display: contents
  // a
  //   color: #777 !important
  // a,
  // span
  //   display: inline-flex
  //   font-size: 14px
  //   font-family: var(--ff1-3)
  //
  //   text-transform: lowercase !important
  //   &:first-letter
  //     text-transform: capitalize !important
  //
  // span
  //   color: var(--dd2) !important