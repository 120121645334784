// @font-face
//   font-display: swap
//   font-family: "Inter"
//   font-stretch: 75% 100%
//   font-style: oblique 0deg 20deg
//   font-weight: 1 999
//   src: url('../fonts/Inter/Inter.var.woff2') format("woff2 supports variations"), url("../fonts/Inter/Inter.var.woff2") format("woff2-variations")

@include font-face('exo2-300',  normal, 300, '../fonts/01_exo2/Exo2-Light', woff2, swap)
@include font-face('exo2-400',  normal, 400, '../fonts/01_exo2/Exo2-Regular', woff2, swap)
@include font-face('exo2-500',  normal, 500, '../fonts/01_exo2/Exo2-Medium', woff2, swap)
@include font-face('exo2-600',  normal, 600, '../fonts/01_exo2/Exo2-SemiBold', woff2, swap)
@include font-face('exo2-700',  normal, 700, '../fonts/01_exo2/Exo2-Bold', woff2, swap)

@include font-face('audiowide-400',  normal, 400, '../fonts/02_audiowide/Audiowide-Regular', woff2, swap)

@include font-face('inter-300',  normal, 300, '../fonts/03_Inter/Inter-Light-BETA', ttf, swap)
@include font-face('inter-400',  normal, 400, '../fonts/03_Inter/Inter-Regular', ttf, swap)
@include font-face('inter-500',  normal, 500, '../fonts/03_Inter/Inter-Medium', ttf, swap)
@include font-face('inter-600',  normal, 600, '../fonts/03_Inter/Inter-SemiBold', ttf, swap)
@include font-face('inter-700',  normal, 700, '../fonts/03_Inter/Inter-Bold', ttf, swap)