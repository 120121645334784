.smcart
  --mfp_frame_width: 545px
  &_alert,
  &_empty
    --mfp_frame_inset_reverse_ver: calc(var(--mfp_frame_inset_ver) * var(--mfp_reverse_argument, -1))
    --mfp_frame_inset_reverse_hor: calc(var(--mfp_frame_inset_hor) * var(--mfp_reverse_argument, -1))
  // &_data

    // min-width: calc(var(--smcart_data_min_content) -
  @import smcart_btn
  @import smcart_alert
  @import smcart_empty
  @import smcart_data
  @import smcart_item
  @import smcart_total
  @import smcart_order
// @import "smcart_products"