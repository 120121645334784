&_data

  display: flex
  flex-direction: column
  // grid-template-columns: 100%
  // grid-template-rows: max-content 1fr
  // grid-auto-rows: max-content
  gap: calc(var(--mfp_frame_offset_y) * 2)
  // align-content: start

  &_contain,
  &_table,
  &_tbody
    display: contents
  &_thead
    display: none
  &_tbody > &_trow + &_trow
    position: relative
    &::before
      content: ''
      width: calc(100% + var(--mfp_frame_offset_x) * 2)
      height: 1px
      background-color: rgb(234 237 247 / 45%)
      position: absolute
      bottom: calc(100% + var(--mfp_frame_offset_y) - .5px)
      left: 50%
      transform: translateX(-50%)

