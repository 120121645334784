&_item + &_item
  margin-top: 15px
  padding-top: 15px
  border-top: 1px solid var(--dd3-3)



&_item
  display: grid !important
  grid-template-rows: 21px minmax(21px, max-content) repeat(2, auto)
  +from-to($mm1, $mm5 - .3)
    grid-gap: 0 4px
    grid-template-columns: 60px 1fr 1fr
    grid-auto-columns: max-content
    grid-template-areas: 'preview name name' 'preview name name' 'model model model' 'remove quantity total'
  +mm5
    grid-template-columns: fit-content(116px) auto 1fr 30px

    justify-content: end

    grid-gap: 0 min(20px, max(7px, 1.389vw))


  &.model_show
    +from-to($mm1, $mm5 - .3)
      grid-template-columns: 80px 1fr 1fr 30px
      grid-template-areas: 'preview model model model' 'preview name name name' 'preview total total total' ' preview quantity quantity remove'
    +mm5
      grid-template-areas: 'preview model model remove' 'preview name name remove' 'preview name name remove' 'preview quantity total total'

  &.options_show
    grid-template-areas: 'preview name name remove' 'preview name name remove' 'preview options options options' 'preview quantity total total'

  &.model_show.options_show
    grid-template-areas: 'preview model model remove' 'preview name name remove' 'preview options options options' 'preview quantity total total'
  > *
    width: auto !important
    height: auto !important
    margin: 0 !important

  &_area
    &_remove
      grid-area: remove
    &_preview
      grid-area: preview
    &_model
      grid-area: model
    &_name
      grid-area: name
    &_options
      padding-top: 10px
      grid-area: options
      > div
        font-family: var(--ff2-4)
        font-size: 12px
        display: flex
        gap: 3px
        align-items: baseline
        > *
          &:nth-child(1)
            color: var(--dd3-1)
            text-transform: unset !important
          &:nth-child(2)
            color: var(--dd2-3)

    &_quantity
      grid-area: quantity
      +from-to($mm1, $mm5 - .3)
        border-radius: 4px
        justify-self: end

      +mm5
        align-self: end

      margin-top: 10px
    &_total
      grid-area: total
      align-self: end

      justify-self: end
      padding-bottom: 9px


  &_error
    color: var(--aa1)
    &.error_stock
      font-size: 14px
      font-family: var(--ff1-7)
  &_btn
    all: unset
    box-sizing: border-box
    cursor: pointer
    max-width: 100%
    border-radius: var(--bdrs-4)
    transition: 250ms linear

    display: flex
    align-items: center
    justify-content: center
    background-color: var(--dd3-3)
    &:hover
      color: var(--dd1)
      background-color: var(--hh1-2)
      border-radius: 8px
      box-shadow: 0 0 8px -2px rgba(0 0 0 / 30%)

  &_preview
    position: sticky
    top: 0
    display: flex
    align-items: center
    justify-content: center
    transition: 300ms linear
    border-radius: var(--bdrs-4)
    &:hover
      // padding: 8px !important
      box-shadow: 0 0 0 1px var(--dd3-3)
    img
      max-width: 100%
      height: auto
      // widtsh: 100%
      // height: auto
      display: block
      border-radius: var(--bdrs-4)

  &_model
    font-size: 13px
    font-family: var(--ff1-3)
    display: flex
    color: #777
    align-items: baseline
    gap: 3px
  &_name
    display: flex
    align-items: baseline
    gap: 4px
    &_text
      color: var(--dd2-3)
      font-family: var(--ff1-4)
      line-height: 1.2
      display: inline-block
      +from-to($mm1, $mm5 - .3)
        font-size: 12px
      +mm5
        font-size: 16px
  &_quantity
    width: fit-content
    &_contain
      --smcart_item_quantity_size: min(40px, max(30px, 5.05vw))
      position: relative
      // display: flex
      display: grid
      grid-template-columns: var(--smcart_item_quantity_size) 1fr var(--smcart_item_quantity_size)
      grid-template-rows: var(--smcart_item_quantity_size)
      background-color: var(--dd3-3)
      border-radius: var(--bdrs-8)
      transition: 250ms linear !important

    // &_contain:hover:not(:focus-within)
    //  box-shadow: 0 0 0 .5px var(--hh1-2)

    &_inp
      all: unset
      appearance: auto
      box-sizing: border-box
      font-family: var(--ff1-5)
      text-align: center
      cursor: text
      padding: 3px
      transition: all 250ms 0s !important
      order: -1
      border-radius: var(--bdrs-8)

      &:hover:not(:focus)
        cursor: pointer
        border-radius: var(--bdrs-8)
        background-color: var(--dd3-4)
        box-shadow: inset 0 0 0 1px var(--dd3)
      &:focus
        box-shadow: inset 0 0 0 1px var(--dd3-2), 0 0 1px -1px var(--dd3-2)
        border-radius: var(--bdrs-8)
        &:hover
          box-shadow: 0 0 0 1px var(--hh1-2)
    &_btn
      height: var(--smcart_item_quantity_size)
      width: var(--smcart_item_quantity_size)


  &_total
    display: flex
    align-items: baseline
    gap: 4px
    &_text
      font-size: clamp(16px, 16px + (21 - 16) * (100vw - 662.3px) / (1555 - 662.3), 21px)
      font-family: var(--ff1-7)
      font-style: italic
      white-space: nowrap
      color: var(--dd2)
