&_overlay
  --contain_size: 1200px
  --contain_inset: 16px
  --contain_left_col: 500

  --contain_gap: calc(var(--contain_inset) * 2)
  --map_hover_offset: calc(var(--contain_inset) * -2)


  --contain_inset_top_var: var(--contain_inset_top, var(--contain_innset_ver, var(--contain_inset)))
  --contain_inset_right_var: var(--contain_inset_right, var(--contain_innset_hor, var(--contain_inset)))
  --contain_inset_bottom_var: var(--contain_inset_bottom, var(--contain_innset_ver, var(--contain_inset)))
  --contain_inset_left_var: var(--contain_inset_left, var(--contain_innset_hor, var(--contain_inset)))
  --contain_inset_var: var(--contain_inset_top_var, 0) var(--contain_inset_right_var, 0) var(--contain_inset_bottom_var, 0) var(--contain_inset_left_var, 0)
  // --
  --contain_row_gap_var: var(--contain_row_gap, var(--contain_gap, 0))
  --contain_col_gap_var: var(--contain_cow_gap, var(--contain_gap, 0))
  --contain_cols: 2
  --contain_cols_size: calc(100% - var(--contain_col_gap_var, 0px) * (var(--contain_cols, 1) - 1))
  --contain_left_size: calc(var(--contain_cols_size) * .48)
  --contain_right_size: calc(var(--contain_cols_size) * .52)
  // ---
  --map_hover_offset_top_var: var(--map_hover_offset_top, var(--map_hover_offset))
  --map_hover_offset_right_var: var(--map_hover_offset_right, var(--map_hover_offset))
  --map_hover_offset_bottom_var: var(--map_hover_offset_bottom, var(--map_hover_offset))
  --map_hover_offset_left_var: var(--map_hover_offset_left, var(--map_hover_offset))


  display: grid
  grid-template-columns: 100%
  grid-gap: var(--contain_row_gap_var, 0) var(--contain_col_gap_var, 0)
  +from(990.4px)
    grid-template-columns: var(--contain_left_size, 1fr) var(--contain_right_size, 1fr)
  background-color: var(--dd3-3)
  border-radius: var(--bdrs-12)
  grid-auto-rows: max-content
  padding: var(--contain_inset_var)


&_map
  order: 2
  align-self: stretch
  position: relative
  padding: var(--contain_inset_ver)

  //filter: drop-shadow(0 0 2px rgba(0 0 0 / 20%))
  border-radius: var(--bdrs-12)
  +from-to($mm1, 990.4px)
    aspect-ratio: 16/9
    border-radius: var(--bdrs-4)
  &_block
    height: unset !important
    width: unset !important
    position: absolute
    inset: 0 0 0 0
    left: 0
    transform: translateX(0)
    background-color: var(--dd1)
    border-radius: var(--bdrs-12)
    +from(990px)
      box-shadow: 0 0 8px .5px rgba(0 0 0 / 20%)
    will-change: inset, transform, border-radius
    > *
      width: 100% !important
      height: 100% !important
      border-radius: inherit
    img
      border-radius: var(--bdrs-12)
  &_block.map
    background-color: #fff
    will-change: left, box-shadow
    transition: 170ms ease


  &:hover &_block
    @media (hover: hover) and (pointer: fine)
      transition: all 350ms ease, left 300ms 325ms var(--trs12)
      inset: var(--map_hover_offset) calc(var(--map_hover_offset) * 2) var(--map_hover_offset) 0
      transform: translateX(var(--map_hover_offset))
  &:hover &_block.map
    @media (hover: hover) and (pointer: fine)
      left: -100%
      //transition: left 340ms ease 325ms, transform 300ms var(--trs12)
      //box-shadow: 0 0 0 1px #ebedf6, 0 5px 15px -5px rgba(0 0 0 / 50%)


&_map:hover ~ &_contain
  @media (hover: hover) and (pointer: fine)
    .contact_title_form
      transition: 150ms 250ms, opacity 500ms
      opacity: 0
      pointer-events: none
      filter: grayscale(50%)
      position: relative
    .contact_store_logo
      background-color: #eceef6
      box-shadow: 0 0 0 1px #eceef6, 1px 2px 4px -1px rgba(0,0,0,.36)
      transform: translateY(-4px)
&_map:hover ~ &_contain &
  @media (hover: hover) and (pointer: fine)
    .contact_title_form,
    &_form
      will-change: opacity
      transition: 150ms 250ms, opacity 250ms
      opacity: 0
      pointer-events: none
      //filter: grayscale(50%)
      //z-index: -1
      position: relative
    &_header
      pointer-events: none
      transform-origin: 0 50%
      transition: all 350ms ease 250ms, transform 400ms 325ms var(--trs12)
      --contain_inset_reverse-x: calc(var(--contain_inset) * -1.1)
      --contain_inset_reverse-y: calc(var(--contain_inset) * -.3)
      transform: translate(var(--contain_inset_reverse-x), var(--contain_inset_reverse-y))
      a
        pointer-events: auto
    &_header *
      filter: drop-shadow(0 0 0 rgb(0 0 0 / 0%))
      color: var(--dd2)
      transition: 300ms 275ms  var(--trs1)



    &_header::before
      transition: all 450ms 300ms var(--trs12)
      border-radius: calc(var(--bdrs-12) / 2 )
      background-color: rgba(#ebedf6, .85)
      box-shadow: inset -65px -32px 0 61px rgba(179,154,233,.55), 0 0 0 2.7px #eceef6, 0 0 0 4px #d8d3ef




//&_detail
//
//  grid-gap: 5px 15px
//  grid-template-columns: 100%
//  //grid-template-columns: fit-content(32%) fit-content(60%) fit-content(60%) 1fr
//  //grid-template-rows: fit-content(33%) fit-content(33%)
//  







&_header
  --title: 16px
  display: grid
  grid-gap: 6px 16px
  margin-bottom: 30px
  will-change: filter, transform , backdrop-filter, transform-origin, background-color, inset, left, opacity
  grid-column: 1/-1



  transition: all 250ms ease 300ms, transform 325ms 275ms var(--trs12)
  transform: translateZ(0px)
  position: relative

  > *
    margin: 0 !important
    &:empty
      display: none

  &:not(.added_store_image)
    +from-to($mm1, 414.4px)
      grid-template-columns: 100%
      grid-template-areas: 'store' 'address' 'phones' 'time'
    +from(414px)
      grid-template-columns: fit-content(50px) .78fr 1fr
      grid-template-areas: 'store store store' 'address address address' 'phones time time'
  &.added_store_image
    grid-template-columns: fit-content(50px) 1.1fr repeat(2, 1fr)
    +from-to($mm1, 414.4px)
      grid-auto-rows: minmax(50px, auto) auto
      grid-template-areas: 'image store store store store' 'address address address address address ' 'phones phones phones phones phones' 'time time time time time'
    +from(414px)

      grid-template-areas: 'image store store store store' 'image address address address address ' 'phones phones time time time'

    .contact_store
      grid-column: 2/-1
      align-self: center
  &::before,
  > *
    will-change: filter, transform , backdrop-filter, transform-origin, background-color, inset, left, opacity

  &::before
    content: ''
    align-self: stretch
    width: calc(100% + var(--contain_gap, 0))
    height: calc(100% + var(--contain_gap, 0))

    position: absolute
    inset: calc(var(--map_hover_offset) / 2) !important
    border-radius: var(--bdrs-12)

    box-shadow: inset 0 0 60px 0 rgba(#ebedf6,0), 0 0 0 0 rgba(#ebedf6,0), 0 0 0 0 rgba(#ebedf6,0)
    transition: all 300ms 275ms
    pointer-events: none
    opacity: 1






&_block
  display: grid
  grid-template-columns: 100%
  align-content: start
  grid-gap: 5px
  &_address
    display: contents
  &_address &_list
    grid-area: address
    > *
      margin-bottom: -2px
  &_phones
    padding-top: 5px
    grid-area: phones

  &_phones .contact_title,
    &_time .contact_title
    line-height: 1
  &_phones &_list

    white-space: nowrap
    font-variant-numeric: tabular-nums
    font-variant: oldstyle-nums
    text-transform: lowercase

  &_phones,
  &_time
    grid-gap: 10px
    padding-top: 10px
  &_time
    grid-area: time

  &_time &_list li ins
    
    white-space: pre-line
    display: block
    margin-top: -1px


  &_list
    display: grid
    grid-template-columns: 100%
    grid-template-rows: minmax(16px, max-content)
    align-items: start
    grid-gap: 5px
    font-family: var(--ff1-3)
    margin: 0
    padding: 0
    font-size: 15px
    list-style-type: none
    *
      font-weight: inherit
      font-size: inherit
      
      line-height: 1.1
      text-decoration: none
      color: currentColor
      font-family: inherit
&_title
  font-size: var(--title)
  font-family: var(--ff1-5)
  color: var(--cc1)

  &_store
    color: var(--dd2)
    font-size: var(--size-fluid-5)
    font-family: var(--ff1-7)
    line-height: 1
  &_form
    font-size: 18px
    font-family: var(--ff1-7)


&_store
  grid-area: store
  display: block
  grid-column: 1/1
  &:not(.and_image)
    grid-column: 1/-1


  &_logo
    grid-area: image

    border-radius: var(--bdrs-4)
    //background-color: var(--dd3-3)
    //filter: drop-shadow(1px 2px 4px -1px rgb(0 0 0 / 30%))
    box-shadow: 1px 2px 4px -1px rgb(0 0 0 / 36%) , 0 0 0 1px #d8d3ef
    background-color: #fff
    display: flex
    place-items: center center
    *
      border-radius: inherit
  &_img
    max-width: 100%
    width: 100%
    height: 100%
    object-fit: cover
    display: block
    mix-blend-mode: multiply

    border: none
    

  &_txt
    align-self: baseline
    line-height: 1.1
    &:only-child
      grid-column: 1/-1


.contact_store_figure
 align-self: center

samp
  //flex-basis: 27px
  display: grid
  min-height: 20px
  height: fit-content
  color: var(--cc3)

  kbd
    color: currentColor
    font-size: clamp(18px, 18px + (28 - 18) * (100vw - 662.3px) / (1555 - 662.3), 28px)
    line-height: 1
    font-family: var(--ff1-6)
    background-color: transparent
    box-shadow: none
    display: block
    padding: 0
    margin-block: -3px

fieldset
  display: block !important
  float: none

  margin-inline: 2px !important
  padding-inline: 0.75em !important
  padding-block: 0.35em 0.625em !important

  min-inline-size: min-content !important
  border-width: 2px !important
  border-style: groove !important
  border-color: rgb(192, 192, 192) !important
  border-image: initial !important
legend
  display: block !important
  text-align: unset !important
  padding-inline-start: 0 !important
  padding-inline-end: 0 !important
  border-width: initial !important
  border-style: none !important
  border-color: initial !important
  border-image: initial !important


&_form
  display: grid
  grid-template-columns: 100%
  grid-gap: 18px
  margin-top: 5px
  fieldset
    padding: 0 10px 10px !important
    border-radius: calc(var(--bdrs-4) + 1px)
    //border: 1px solid  darken(#ebedf6,15%) !important
    border: none !important

    &:focus-within
      filter: drop-shadow(1px 3px 6px rgba(#d8d3ef, .7))
      > legend
        .legend_ease
          flex-grow: 0.5
          &:last-child
            flex-grow: .5
        .legend_text
          color: var(--dd2-2)
          font-style: normal
          transform: translateY(-2px)
          &::before
            display: block
            background-color: var(--cc1)
            opacity: 1
    .form_group:has(.text-danger) + legend .legend_text::before
      background-color: var(--cc2)
      opacity: .8
    &:focus-within .form_group:has(.text-danger) + legend .legend_text::before
      opacity: 1



  legend
    position: relative
    z-index: 2
    transform: translate(0, -1px)
    border: none
    float: none
    margin: 0
    padding: 0
    text-align: right
    .legend_inner
      display: flex
      align-items: baseline

    
    .legend_ease
      flex-grow: 1
      height: 1px
      transition: 440ms var(--trs1)
      &:last-child
        flex-grow: 0.025

    .legend_text
      font-size: 12px
      font-variant-caps: titling-caps
      font-style: italic
      margin: 0


      color: var(--dd3-4)

      padding-block: 3px 0 !important
      padding-inline: 8px !important
      margin-block-end: 4.5px !important
      line-height: 1

      background-color: #fff
      border-radius:  calc(var(--bdrs-4) + 1px) calc(var(--bdrs-4) + 1px) 0 0
      display: flex
      align-items: center
      gap: 4px
      width: fit-content
      &::before
        content: ''
        width: 5px
        height: 5px
        border-radius: 100%
        flex-shrink: 0
        background-color: var(--dd3-4)
        opacity: 1

.form_group
  display: grid
  grid-template-columns: 100%
  grid-gap: 10px
  //display: contents
  input, textarea
    border-radius: var(--bdrs-4) !important
    overflow: hidden
    width: calc(100% + 20px) !important
    margin: -9px -10px -10px
    border-color: transparent !important
    &::placeholder
      color: var(--dd3-4)
      font-style: italic

    &:-internal-autofill-selected
      box-shadow: inset 0 0 0 50px #FFE

  textarea
    resize: vertical
.text-danger
  font-size: 12px
  color: var(--cc2)
  font-style: italic
  line-height: 1
  position: relative
.form_set

  &:last-child
    display: flex
    justify-content: flex-end
  input[type="submit"]
    min-height: 36px
    width: min(45%, 245px)
    display: flex
    align-items: center
    justify-content: center
    font-family: var(--ff3-5)
    font-size: 15px
