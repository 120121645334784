
\:root
    // fonts -init-
    --ff1-3: 'exo2-300', var(--font_fallback)
    --ff1-4: 'exo2-400', var(--font_fallback)
    --ff1-5: 'exo2-500', var(--font_fallback)
    --ff1-6: 'exo2-600', var(--font_fallback)
    --ff1-7: 'exo2-700', var(--font_fallback)

    --ff2-4: 'audiowide-400', var(--font_fallback)

    --ff3-3: 'inter-300', var(--font_fallback)
    --ff3-4: 'inter-400', var(--font_fallback)
    --ff3-5: 'inter-500', var(--font_fallback)
    --ff3-6: 'inter-600', var(--font_fallback)
    --ff3-7: 'inter-700', var(--font_fallback)

    // fonts -default-
    --font_fallback: BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
    --font_code: Menlo,Consolas,Monaco,Liberation Mono,Lucida Console,monospace
    --font_body: var(--ff1-4)
    --color_body: var(--dd2-2)
    // fonts -end-



    --trs1: cubic-bezier(0.25, 0.1, 0.25, 1)
    --trs2: cubic-bezier(0.39, 0.58, 0.57, 1)

    --trs121: cubic-bezier(0.68, -0.55, 0.27, 1.55)
    --trs12: cubic-bezier(0.18, 0.89, 0.32, 1.28)
    --trs21: cubic-bezier(0.6, -0.28, 0.74, 0.05)





    // sizes -root-




    --size-3: 1rem
    --size-4: 1.25rem
    --size-5: 1.5rem
    --size-6: 1.75rem
    --size-7: 2rem
    --size-8: 3rem
    --size-9: 4rem
    --size-10: 5rem

    --size-fluid-1: clamp(0.5rem, 1vw, 1rem)
    --size-fluid-2: clamp(1rem, 2vw, 1.25rem)
    --size-fluid-22: clamp(1.15rem, 2vw, 1.4rem)
    --size-fluid-3: clamp(1.5rem, 3vw, 2rem)
    --size-fluid-4: clamp(1.5rem, 4vw, 2.5rem)
    --size-fluid-5: clamp(1.5rem, 5vw, 3rem)
    --size-fluid-6: clamp(2rem, 5vw, 5rem)
    --size-fluid-7: clamp(3rem, 7vw, 5rem)
    --size-fluid-8: clamp(4rem, 9vw, 6rem)

    // root_title_variables

    // draft, collect variables
    --tip_wrap: 1170px
    --tip_inset: 15px
    // no_px
    --fip_wrap: 1170
    --fip_inset: 15
    --fip_min_offset: calc(var(--fip_wrap) + var(--fip_inset))

    --minmax_15_30: clamp(15px, 15px + (30 - 15) * (100vw - 740.3px) / (var(--fip_min_offset) - 740.3), 30px)
    --tip_offset: max(var(--minmax_15_30), calc((min(100%, 100vw) - calc(var(--tip_wrap, 0px) - var(--tip_inset, 0px) * 2)) / 2))
    --verticalmenu_width: 260px
    --HS_offset: var(--bdrs-8)
    --bdrs-4: 4px
    --bdrs-8: 8px
    --bdrs-12: 12px




    --minmax_8_15: clamp(8px, 8px + (15 - 8) * (100vw - 820.3px) / (1645 - 820.3), 15px)
    --minmax_15_25: clamp(15px, 15px + (25 - 15) * (100vw - 820.3px) / (1645 - 820.3), 25px)
    --minmax_20_35: clamp(20px, 20px + (35 - 20) * (100vw - 820.3px) / (1645 - 820.3), 35px)


    // color -reserved-
    --aa1: #ff002e
    --gg1: green
    // reserved_color -end-

    // colors -decor-
    --dd1: #fff
    --dd1-1: #e5e5e5
    --dd1-2: #f2f2f2
    --dd1-3: #d2d2d2
    --dd1-4: #ccc

    --dd2: #000
    --rd2: 0 0 0
    --dd2-1: #222
    --dd2-2: #4f4d57
    --dd2-3: #333
    --dd2-4: #555

    --dd3: #666
    --dd3-1: #888
    --dd3-2: #a2a2a2
    --dd3-3: #EAEDF7
    --dd3-4: #ddd
    --dd3-5: #f2f2f0
    // decor_colors -end-

    // 16/3colors -main-
    --cc1: #afbc25
    --rr1: 175 188 37
    --hh1: #8a941e
    --hh1-2: #000

    --cc2: #ff6408
    --ch2: #d44f00

    --cc3: #231f20

// main_colors -end-
