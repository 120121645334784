.advantages
  --advantages_columns: 4
  --advantages_row_gap: clamp(10px, 10px + (20 - 10) * (100vw - 1260.3px) / (1645 - 1260.3), 20px)
  --advantages_col_gap: clamp(10px, 10px + (35 - 10) * (100vw - 1260.3px) / (1645 - 1260.3), 35px)
  --advantages_col_size: 1fr
  --advantages_col_decor_size: 1.5px
  --advantages_col_decor_height: 75%
  --advantages_col_decor_fill: var(--dd3-3)
  // ------------------
  --advantages_icn_size: min(50px, max(38px, 5vmin))
  --advantages_icn_scale: 77%
  --advantages_icn_bgc: url("../image/codezeel/service-icon.png")
  --advantages_icn_bgc_pos_x: center

  --advantages_icn_width_var: var(--advantages_icn_width, var(--advantages_icn_size))
  --advantages_icn_height_var: var(--advantages_icn_height, var(--advantages_icn_size))
  // ------------------
  --advantages_heading_color: var(--dd2)
  --advantages_heading_ff: var(--ff1-5)
  --advantages_heading_fz: 16px
  // --advantages_heading_fz: clamp(12px, , 14px)
  --advantages_heading_ls: -.025rem
  // ------------------
  +from-to($mm1, 350px)
    --advantages_template_cols: 100%
  +from-to(350px + 0.3, $mm4)
    --advantages_template_cols: 1fr 1fr
  +from-to($mm4 + 0.3, 1107px)
    --advantages_columns: 2
    // --advantages_contain_text_align: center
    // --advantages_contain_justify: center

  .container
    all: unset

  &__overlay
    padding-top: clamp(10px, 10px + (30 - 10) * (100vw - 1260.3px) / (1645 - 1260.3), 30px) !important
    // padding-bottom: clamp(20px, 20px + (40 - 20) * (100vw - 1260.3px) / (1645 - 1260.3), 40px) !important
    padding-bottom: 0 !important

  &__template
    --calc_advantages_columns: calc(var(--advantages_columns, 1) - 1)
    --calc_advantages_col_size: repeat(var(--calc_advantages_columns, 1), minmax(0, var(--advantages_col_size, 1fr)) var(--advantages_col_decor_size, 1fr)) var(--advantages_col_size, 1fr)
    --calc_advantages_row_size: minmax(var(--advantages_icn_height_var, auto), max-content)
    // ------------------
    display: grid
    grid-template-columns: var(--advantages_template_cols, var(--calc_advantages_col_size))
    grid-auto-rows: var(--advantages_template_rows, var(--calc_advantages_row_size))
    grid-gap: var(--advantages_row_gap, 0) var(--advantages_col_gap, 0)
    justify-items: center
    justify-content: center

  &__item
    width: 100%
    display: contents
    &::after
      justify-self: center
      align-self: center
      content: ''
      display: block
      width: var(--advantages_col_decor_width, var(--advantages_col_decor_size, auto))
      height: var(--advantages_col_decor_height, var(--advantages_col_decor_size, auto))
      background-color: var(--advantages_col_decor_fill, currentColor)
    &:nth-child(1n)::after
      +from-to($mm1 + 0.3, $mm4)
        display: none
    &:nth-child(2n)::after
      +from-to($mm4 + 0.3, 1107px)
        display: none
    &:nth-child(4n)::after
        display: none
    &_overlay
      width: 100%

    &_contain
      width: 100%
      height: 100%
      background-color: var(--dd1)
      display: grid
      grid-gap: 3px clamp(5px, 5px + (10 - 5) * (100vw - 731.3px) / (1645 - 731.3), 10px)
      grid-template-columns: var(--advantages_icn_width_var, max-content) 1fr
      grid-template-rows: repeat(2, max-content)
      border-radius: var(--bdrs-4)
      justify-items: var(--advantages_contain_justify)
      text-align: var(--advantages_contain_text_align, left)
      padding: 8px clamp(8px, 8px + (22 - 8) * (100vw - 731.3px) / (1645 - 731.3), 22px) 8px clamp(6px, 6px + (10 - 6) * (100vw - 731.3px) / (1645 - 731.3), 10px)
      align-items: center
      box-shadow: 0 0 0 1px var(--dd3-3)
      transition: 500ms
      &:hover
        border-radius: var(--bdrs-12)
        background-color: rgb(var(--rr1) / 30%)
        box-shadow: 3px 3px 10px rgb(var(--rd2)  / 7%), 5px 5px -10px rgb(var(--rd2)  / 7%)
        // backdrop-filter: blur(2px)
      > *:nth-child(n+4)
        grid-column: 1/-1
      &.contain_shipping
        --advantages_icn_bgc_pos_y: 12px
        &:hover
          --advantages_icn_bgc_pos_y: -64px
      &.contain_returns
        --advantages_icn_bgc_pos_y: -140px
        &:hover
          --advantages_icn_bgc_pos_y: -216px
      &.contain_guarantee
        --advantages_icn_bgc_pos_y: -294px
        &:hover
          --advantages_icn_bgc_pos_y: -370px
      &.contain_supports
        --advantages_icn_bgc_pos_y: -443px
        &:hover
          --advantages_icn_bgc_pos_y: -519px

    &_icn
      grid-column: 1
      +from-to($mm1 + 0.3, $mm4)
        grid-row: 2
      +mm4
        grid-row: 1/-1

    &:not(:hover) &_heading
      +from-to($mm1 + 0.3, $mm4)
        padding-left: clamp(3px, 3px + (10 - 3) * (100vw - 360.3px) / (563 - 360.3), 10px)
    &_heading
      grid-row: 1
      +from-to($mm1 + 0.3, $mm4)
        grid-column: 1/-1
        transition: 250ms
      +mm4
        grid-column: 2
        align-self: end
        padding-top: 2px

    &_text
      grid-row: 2
      grid-column: 2
      +from-to($mm1 + 0.3, $mm4)
        align-self: center
      +mm4
        align-self: start

    &_icn
      height: var(--advantages_icn_width_var, auto)
      width: var(--advantages_icn_height_var, auto)
      display: flex
      justify-content: center
      align-items: center
      transition: 500ms
      filter: drop-shadow(.5px .5px 0 var(--dd2))
      border-radius: var(--bdrs-12)
      &::before
        content: ''
        width: 50px
        height: 50px
        transform: scale(var(--advantages_icn_scale))
        transition: 500ms
        background-image: var(--advantages_icn_bgc, none)
        background-repeat: no-repeat
        background-attachment: scroll
        background-color: transparent
        background-position: var(--advantages_icn_bgc_pos_x, 50%) var(--advantages_icn_bgc_pos_y, 50%)

    &_contain:hover &_icn
      transition: 500ms, background-color 500ms 0ms
      border-radius: var(--bdrs-4)
      background-color: var(--dd1)
      filter: drop-shadow(1px 1px 2px rgba(var(--rd2) / 25%))
      &::before
        transition: 500ms, background-color 500ms 0ms
    &_heading
      color: var(--advantages_heading_color, currentColor)
      font-size: 1.55rem
      font-family: var(--advantages_heading_ff, inherit)
      line-height: var(--advantages_heading_lh, 1.2)
      letter-spacing: var(--advantages_heading_ls, initial)
    &_text
      font-size: var(--size-4)