@use "sass:math"
//old
// inject:fontsName
$ff: "gotham-pro", sans-serif
$ff2: "lato", sans-serif
$ff3: "audiowide", sans-serif
// endinject
$ff-fa: "Font Awesome 5 Pro", sans-serif

// colors
$c: #000
$cf: #fff
$bg: #f2f2f2

$c1: #4fb0a7
$c2: #222

// other
$fz: 16px


//in editor (px)

$max-h1: 36
$max-h2: 30
$max-h3: 24
$max-h4: 18
$max-h5: 16
$max-h6: 15
$max-txt: 16


$list-width: 25
$li-marker: "\2022"


// transitions
$trsE: ease
$trsL: linear
$trsEI: ease-in
$trsEO: ease-out
$trsEIO: ease-in-out

$trs: cubic-bezier(0.25, 0.1, 0.25, 1)
$trs2: cubic-bezier(0.39, 0.58, 0.57, 1)

$trs121: cubic-bezier(0.68, -0.55, 0.27, 1.55)
$trs12: cubic-bezier(0.18, 0.89, 0.32, 1.28)
$trs21: cubic-bezier(0.6, -0.28, 0.74, 0.05)

// transform-position-translate-center
$tf: translate(-50%, -50%)
$tfX: translateX(-50%)
$tfx: translateX(-50%)
$tfY: translateY(-50%)
$tfy: translateY(-50%)


$columns: 12
$offset: 30px
$fields: 15

$atom: math.div(100% , $columns)
$break_m1: 1685px
$break_m2: 1445px
$break_m3: 1370px
$break_m4: 1285px
$break_m5: 1155px
$break_m6: 1055px
$break_m7: 965px
$break_m8: 865px
$break_m9: 740px
$break_m10: 639px
$break_m11: 570px
$break_m12: 480px
$break_m13: 359px
$break_m14: 320px

$offset_one_side: math.div($offset , 2)


//=========================================================
=wrapper-full()
  padding-left: $fields
  padding-right: $fields

=row-offsets()
  margin-left: ($offset_one_side * -1)
  margin-right: ($offset_one_side * -1)

=col-offsets($type, $val, $val2)
  @if ($val == null)
    #{$type}-left: $offset_one_side
    #{$type}-right: $offset_one_side
  @else if ($val == true)
    #{$type}-left: $offset_one_side
    #{$type}-right: $offset_one_side
    #{$type}-bottom: ($offset_one_side * 2)
  @else if ($val == mb and $val2 != null)
    #{$type}-left: math.div($val2 , 2)
    #{$type}-right: math.div($val2 , 2)
    #{$type}-bottom: $val2
  @else if ($val == $val and $val2 != null)
    #{$type}-left: math.div($val2 , 2)
    #{$type}-right: math.div($val2 , 2)
    #{$type}-bottom: $val
  @else
    #{$type}-left: math.div($val , 2)
    #{$type}-right: math.div($val , 2)

//=========================================================

=wr($wr: 1170px, $pd: $offset, $pos: center)
  @if ($wr != 0 and $pd != 0)
    max-width: $wr + $pd
    padding-left: math.div($pd , 2)
    padding-right: math.div($pd  , 2)
  @else if ($wr != 0 and $pd == 0)
    max-width: $wr
  @else
    padding-left: math.div($pd, 2)
    padding-right: math.div($pd, 2)

  @if ($pos == "center")
    margin-left: auto
    margin-right: auto
  @else if ($pos == "left")
    margin-right: auto
  @else if ($pos == "right")
    margin-left: auto
  @else


=sflex($ofs:null, $wp:wrap)
  display: flex
  @if ($wp != 'nwp')
    flex-wrap: $wp
  @if ($ofs != null and $ofs != 0)
    margin-left: math.div($ofs , -2)
    margin-right: math.div($ofs , -2)
  @else if ($ofs == 0)
  @else
    +row-offsets()

=col($ofs:null, $mb:null, $type:margin)
  word-wrap: break-word
  @if ($ofs != null and $mb == null)
    #{$type}-left: math.div($ofs , 2)
    #{$type}-right: math.div($ofs , 2)
  @else if ($ofs != null and $mb != null and $mb != 'mb')
    #{$type}-left: math.div($ofs , 2)
    #{$type}-right: math.div($ofs , 2)
    #{$type}-bottom: $mb
  @else if ($ofs != null and $mb == 'mb')
    #{$type}-left: math.div($ofs , 2)
    #{$type}-right: math.div($ofs , 2)
    #{$type}-bottom: $ofs
  @else
    #{$type}-bottom: $mb

//=col($mb:null, $ofs:null, $type:margin)
//  word-wrap: break-word
//  @if($mb == 'mb' and $ofs == null)
//    +col-offsets($type, true, $ofs)
//  @else
//    +col-offsets($type, $mb, $ofs)

=size($n, $full: null)
  $col: $columns / $n
  $val: math.div(100% , ($columns * $col))
  @if ($full == full)
    width: $val
  @else if ($full != null)
    width: #{calc(#{$val} - #{$full})}
  @else
    width: #{calc(#{$val} - #{$offset})}

//-------------------------------------
=gg($col, $gap:full, $gap-b:$gap)
  --col: #{$col}
  @if ($gap != full and $gap != 0)
    --gap: #{$gap}
    +sf(--gap)
    > *
      +sz(--col, --gap)
    @if ($gap-b != 0)
      --gap-b: #{$gap-b}
      > *
        +cl(--gap, --gap-b)
    @else
      > *
        +cl(--gap)
  @else
    +sf()
    > *
      +sz(--col, full)


=sf($ofs:null, $wp:wrap)
  display: flex
  @if ($wp != 'nwp')
    flex-wrap: $wp
  @if ($ofs != null and $ofs != 0)
    margin-left: #{calc(var(#{$ofs}) / -2)}
    margin-right: #{calc(var(#{$ofs}) / -2)}


=cl($ofs:null,$mb:null)
  word-wrap: break-word
  @if ($ofs != null)
    margin-left: #{calc(var(#{$ofs}) / 2)}
    margin-right: #{calc(var(#{$ofs}) / 2)}
  @if ($mb != null)
    margin-bottom: #{var(#{$mb})}

=sz($n, $ofs:null)
  $val: 100% / var(#{$n})
  @if ($ofs != null and $ofs != full)
    width: calc(#{$val} - var(#{$ofs}))
  @else
    width: calc(#{$val})


//===========================================================================
=m1()
  @media screen and (max-width: $break_m1)
    @content

=m2()
  @media screen and (max-width: $break_m2)
    @content

=m3()
  @media screen and (max-width: $break_m3)
    @content

=m4()
  @media screen and (max-width: $break_m4)
    @content

=m5()
  @media screen and (max-width: $break_m5)
    @content

=m6()
  @media screen and (max-width: $break_m6)
    @content

=m7()
  @media screen and (max-width: $break_m7)
    @content

=m8()
  @media screen and (max-width: $break_m8)
    @content

=m9()
  @media screen and (max-width: $break_m9)
    @content

=m10()
  @media screen and (max-width: $break_m10)
    @content

=m11()
  @media screen and (max-width: $break_m11)
    @content

=m12()
  @media screen and (max-width: $break_m12)
    @content

=m13()
  @media screen and (max-width: $break_m13)
    @content

=m14()
  @media screen and (max-width: $break_m14)
    @content

=from($min_width)
  @media screen and (min-width: $min_width)
    @content

=to($max_width)
  @media screen and (max-width: $max_width)
    @content

=from-to($min_width, $max_width)
  @media screen and (min-width: $min_width) and (max-width: $max_width)
    @content