&-bg
  opacity: 0
  transition: 250ms
&-bg#{&}-ready
  opacity: 0.5
&-bg#{&}-removing
  opacity: 0
  transition: 250ms 100ms
/* &-wrap
/* &-wrap#{&}-ready
/* &-wrap#{&}-removing

&-wrap &-frame
  opacity: 0
  transition: all 150ms ease-out
  transform: perspective(100px) translate3D(0, 0, 1px) scale(1.2, 1)
  &.mfp_right,
  &.mfp_left
    transform-origin: 0 50%
    transform: perspective(50px) translate3D(5%, 0, -1px) scale3D(1.2, 1, 1)
&-wrap#{&}-ready &-frame
  opacity: 1
  transform: perspective(100px) translate3D(0, 0, 0) scale(1)
  &.mfp_right,
  &.mfp_left
    transform: perspective(50px) translate3D(0, 0, 0) scale3D(1, 1, 1)
&-wrap#{&}-removing &-frame
  opacity: 0
  transform: perspective(100px) translate3D(0, 0, -3px) scale(1.15, 1)
  transition: 150ms , opacity 250ms
  &.mfp_right,
  &.mfp_left
    transition: 250ms , opacity 250ms 50ms
    transform-origin: -5% 50%
    transform: perspective(80px)  translate3D(15%, 0, -1.5px) scale3D(1.15, 1, 1.2)