&_total
  flex-grow: 1
  display: flex
  align-items: flex-end
  justify-content: flex-end
  margin-top: var(--mfp_frame_inset_top_val)
  margin-bottom: var(--mfp_frame_inset_right_val)

  // gap: var(--mfp_frame_offset_y) var(--mfp_frame_offset_x)
  &_contain
    display: grid
    grid-gap: 10px
    align-items: baseline
    justify-items: end
    grid-template-columns: 1fr max-content

    > *
      &:nth-child(n + 3)
        grid-column: 1/-1

  &_text
    font-size: 15px
    font-family: var(--ff1-3)
    line-height: 1.2
    color: var(--dd2-1)
  &_order
    font-size: var(--size-fluid-5)
    font-family: var(--ff1-7)
    font-style: italic
    text-transform: uppercase
    color: var(--dd2)